import React, { useEffect, useState } from "react";
import { allValidations } from "../../utils/formValidation";
import { showFormErrors } from "../../utils/commonFunction";
import { forgetPasswordAction, resetPasswordAction } from "../../redux/action/signupAction";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export const ForgetIndex = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form, setForm] = useState({
        email: ""
    })
    const [data, setData] = useState({
        password: "",
        confirmPassword: "",
        token: ""
    })
    const { token } = useParams()
    const handleChange = (e) => {
        const { name, value } = e.target
        const formErrors = allValidations(name, value, form)
        setForm((prev) => ({ ...prev, [name]: value, formErrors }));
    }
    const handleSubmit = () => {
        if (showFormErrors(form, setForm)) {
            dispatch(forgetPasswordAction(form, () => navigate("/email-verify")))
        }
    }
    const handleResetChange = (e) => {
        const { name, value } = e.target
        const formErrors = allValidations(name, value, data)
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
    const handleResetPassword = () => {
        if (showFormErrors(data, setData)) {
            dispatch(resetPasswordAction(data, token, () => navigate("/")))
        }
    }
    useEffect(() => {
        if (token) {
            setData({
                ...data,
                token: token
            })
        }
    }, [token])
    return {
        form,
        handleChange,
        handleSubmit,
        handleResetPassword,
        handleResetChange,
        data
    }
}