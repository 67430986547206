
import React from "react";
import Chart from "react-apexcharts";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import { useDispatch, useSelector } from "react-redux";
import { growthChartAction } from "../../redux/action/dashboardAction";
import { useEffect } from "react";

function GrowthChart({ extraclass }) {
  const dispatch = useDispatch()
  const growthData = useSelector((state) => state.growthChart.growthProfile)

  let chartData = {
    colors: ["#8F9A99"],
    series: [
      // {
      //   name: "PRODUCT A",
      //   data: [33, 30, 26, 18, 19, 29, 22, 26, 25, 31, 21, 22],
      // },
      // {
      //   name: "PRODUCT B",
      //   data: [30, 31, 15, 10, 30, 27, 33, 12, 25, 30, 45, 60],
      // },
      // {
      //   name: "PRODUCT C",
      //   data: [37, 28, 18, 15, 20, 14, 15, 13, 25, 30, 45, 60],
      // },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        colors: ["#8F9A99"],
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      fill: {
        opacity: 1,
        colors: ["#0021A6", "#8F9A99", "#4E515F"],
      },
      legend: {
        show: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };

  const setChartData = () => {
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    let buyerData = {
      name: "Buyer",
      data: []
    };
    let sellerData = {
      name: "Seller",
      data: []
    };
    let rentalData = {
      name: "Rental",
      data: []
    };

    if (growthData) {
      months.forEach(month => {
        growthData.forEach(data => {
          if (data.name === month) {
            buyerData.data.push(data.buyer);
            sellerData.data.push(data.seller);
            rentalData.data.push(data.rental);
          }
          else {
            buyerData.data.push(0);
            sellerData.data.push(0);
            rentalData.data.push(0);
          }
        });
      });
    };
    chartData.series.push(buyerData);
    chartData.series.push(sellerData);
    chartData.series.push(rentalData);
  }

  useEffect(() => {
    dispatch(growthChartAction())
  }, []);

  useEffect(() => {
    if (growthData.length)
      setChartData();
  }, [growthData])


  return (
    <div className={extraclass}>
      <div className="chart">
        <div className="shadow-2 p-3 border-round-lg">
          <div className="flex align-items-center justify-content-between">
            <h3 className="text-black-dark text-2xl m-0 font-medium">User growth</h3>
            <div className="flex align-items-center border-1 surface-border border-round-xl">
              <ButtonComponent
                label="Monthly"
                className="border-none button-padding text-sm border-round-xl font-medium active"
              />
              <ButtonOutlined
                label="Yearly"
                className="border-none button-padding text-sm border-round-xl text-light-gray font-medium"
              />
            </div>
          </div>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </div>
  );
}
export default GrowthChart;
