import api from "../../services/api";
import { formDataapi } from "../../services/formDataapi";
import Constants from "../../utils/constants";
import { types } from "../type";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

export const SubscribersList = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.SUBSCRIPTIONS);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.SUBSCRIBERS,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
