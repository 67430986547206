import { types } from "../type";

const intitalState = {
    isLoading: false
}

export const loaderReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.SHOW_LOADER:
            return { isLoading: true };
        case types.HIDE_LOADER:
            return { isLoading: false };
        default:
            return { ...state };
    }
}

