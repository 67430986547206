import { types } from "../type";

const initialState = {
  propertyProfile: [],
};
export const sellerUploadImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_FILE:
      return {
        ...state,
        sellerUploadFileInfo: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const propertyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PROPERTY_CONSTANT:
      return {
        ...state,
        propertyProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
export const buyerGeneralInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GENERAL_PROPERTY_INFO:
      return {
        ...state,
        generaInfo: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const myProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MY_PROFILE:
      return {
        ...state,
        myProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const updateProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PROFILE:
      return {
        ...state,
        updateProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
export const createBuyerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_BUYER_FORM:
      return {
        ...state,
        createBuyerProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
