import React from "react";
import Logo from "../../assets/images/Logo.png";
import {
  CustomPassword,
} from "../../components/input/AllInput";
import { Link } from "react-router-dom";
import { ButtonOutlined } from "../../components/buttons/button";
import { ForgetIndex } from "./ForgetIndex";
export const SetPassword = () => {
  const { data, handleResetChange, handleResetPassword } = ForgetIndex()
  return (
    <div className="bg-light-white" style={{ minHeight: "100vh" }}>
      <div
        className="grid justify-content-center align-items-center w-auto md:w-full"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-11 md:col-8 lg:col-6 xl:col-5">
          <div className="bg-light-main border-round-lg p-4 relative">

            <h1 className="text-4xl text-center font-semibold text-black-dark m-0 mb-4">Welcome Back!</h1>
            <img src={Logo} alt="" width={229} className="m-auto block mb-3" />
            <CustomPassword
              label="New Password"
              col={12}
              placeholder="Password"
              name="password"
              value={data?.password}
              onChange={handleResetChange}
              errorMessage={data?.formErrors?.password}
            />
            <CustomPassword
              label="Confirm password"
              col={12}
              placeholder="Password"
              value={data?.confirmPassword}
              name="confirmPassword"
              onChange={handleResetChange}
              errorMessage={data?.formErrors?.confirmPassword}
            />
            <ButtonOutlined label="Submit" onClick={() => handleResetPassword()} />
            <div className="text-center mt-4">
              <p className="text-gray">
                Have an account?{" "}
                <Link to="/" className="text-main no-underline font-medium">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
