import TaskIcon from "../assets/icons/task.png";
import TaskActiveICon from "../assets/icons/Taskactive.png";
import DashboardIcon from "../assets/icons/dashboard-square.png";
import DashboardActiveIcon from "../assets/icons/dashboard-squareactive.png";
import UserIcon from "../assets/icons/user-multiple.png";
import MessageIcon from "../assets/icons/message.png";
import checkIcon from "../assets/icons/checkmark-circle.png";
import UserActioveIcon from "../assets/icons/userMultipleHighLight.png";
import MessgageHighlight from "../assets/icons/message-highlight.png";
import checkMarkHighlight from "../assets/icons/checkmark-circle-03.png";
import archivedIcon from "../assets/icons/archived.png";
import archivedActiveIcon from "../assets/icons/archivedactive.png";
const LayoutData = () => {
  const menuItems = [
    {
      name: "Dashboard",
      link: "/dashboard",
      activeicon: DashboardActiveIcon,
      icon: DashboardIcon,
      iconWidth: "24px",
      iconHeight: "24px",
    },
    {
      name: "Briefs",
      link: "/new-post-list",
      activeicon: TaskActiveICon,
      icon: TaskIcon,
      iconWidth: "24px",
      iconHeight: "24px",
    },
    {
      name: "Archived briefs",
      link: "/archived",
      activeicon: archivedActiveIcon,
      icon: archivedIcon,
      iconWidth: "24px",
      iconHeight: "24px",
    },
    {
      name: "Users",
      link: "/user-list",
      activeicon: UserActioveIcon,
      icon: UserIcon,
      iconWidth: "24px",
      iconHeight: "24px",
    },
    {
      name: "Messages",
      link: "/message",
      activeicon: MessgageHighlight,
      icon: MessageIcon,
      iconWidth: "24px",
      iconHeight: "24px",
    },
    {
      name: "Newsletter Subscribers",
      link: "/subscriber",
      activeicon: checkMarkHighlight,
      icon: checkIcon,
      iconWidth: "24px",
      iconHeight: "24px",
    },
  ];
  return { menuItems };
};
export default LayoutData;
