import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "../../layout";
import { OverViewCard, PostCard } from "../../components/cards/card";
import DashboardData from "./DashboardData";
import ArrowLeftIcon from "../../assets/icons/arrow-left.png";
import ArrowDoneIcon from "../../assets/icons/arrow-down.png";
import GrowthChart from "./GrowthChart";
import { TableComponent } from "../../components/table/table";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { useNavigate } from "react-router-dom";
export const Dashboard = () => {
  const navigate = useNavigate()
  const { overviewData, RecentMessageHeading, RecentMessageDetail, buttons, postsHandleChange, ApprovedPostData, property } =
    DashboardData();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <DashboardLayout>
      <div className="py-2">
        <h1 className="font-semibold text-black-dark">Overview</h1>
        <div className="grid ">
          {overviewData.map((item) => {
            return (
              <>
                <OverViewCard
                  title={item.title}
                  arrowicon={
                    item.counting > "26,000" ? ArrowLeftIcon : ArrowDoneIcon
                  }
                  icon={item.icon}
                  counting={item.counting}
                  percent={item.percent}
                  // onClick={() => navigate(`/user-list/${item?.name}`)}
                />
              </>
            );
          })}

          <GrowthChart extraclass="lg:col-7 col-12 mb-3" />
          <div className="lg:col-5 col-12 mb-3">
            <div className="bg-white h-full shadow-2 p-3 border-round-lg">
              <div className="flex align-items-center justify-content-between mb-3">
                <h3 className="text-black-dark text-2xl m-0 font-medium">
                  Recent message
                </h3>
                <div className="cursor-pointer text-sm text-light-gray font-medium border-1 surface-border button-padding text-sm border-round-lg" onClick={() => navigate("/message")}>
                  View all <i className="pi pi-chevron-right text-xs"></i>
                </div>
              </div>
              <TableComponent
                tableHeading={RecentMessageHeading}
                tableData={RecentMessageDetail.data?.slice(0, 7)}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="shadow-2 p-3 border-round-lg">
              <div className="flex align-items-center justify-content-between mb-3 px-4">
                <h3 className="text-black-dark text-2xl m-0 font-medium">
                  Approved posts
                </h3>
                <div className="post-tab flex align-items-center">
                  <div className="flex tab-button align-items-center gap-2 border-1 surface-border border-round-xl">
                    {buttons.map((item, index) => {
                      return (
                        <Button
                          name={item}
                          onClick={() => { postsHandleChange(item); setActiveIndex(index) }}
                          className={`bg-none border-none p-button-text font-medium  button-padding text-sm border-round-xl ${activeIndex === index ? "active" : "text-light-gray "
                            }`}
                          label={item}
                        />
                      )
                    })}
                  </div>
                  <div className="cursor-pointer text-light-gray  border-1 surface-border ml-3 border-round-lg" onClick={() => navigate("/new-post-list")}>
                    <p className="m-0 button-padding text-sm">View all <i className="pi pi-chevron-right text-xs"></i></p>
                  </div>
                </div>
              </div>
              <TabView
                activeIndex={activeIndex}
                className="dashboard-news"
                onTabChange={(e) => setActiveIndex(e.index)}
              >
                <TabPanel>
                  {ApprovedPostData?.map((item, index) => {
                    return (
                      index <= 2 ?
                        <>
                          {item.postType === "BUYER" || item.postType === "RENTER" ?
                            item.address?.map((items) => {
                              return (
                                <PostCard item={item} index={index} items={items} />
                              )

                            })
                            :
                            <>
                              <PostCard item={item} index={index} property={property} /></>}
                        </> : null
                    )
                  })}
                </TabPanel>
                <TabPanel>
                  {ApprovedPostData?.map((item, index) => {
                    return (
                      index <= 2 ?
                        <>
                          {item.postType === "BUYER" ?
                            item.address?.map((items) => {
                              return (
                                <PostCard item={item} index={index} items={items} />
                              )

                            })
                            :
                            <PostCard item={item} index={index} property={property} />}
                        </> : null
                    )
                  })}
                </TabPanel>
                <TabPanel>
                  {ApprovedPostData?.map((item, index) => (
                    index <= 2 ?
                      <PostCard
                        item={item} index={index}
                      /> : null
                  ))}
                </TabPanel>
                <TabPanel>
                  {ApprovedPostData?.map((item, index) => {
                    return (
                      index <= 2 ?
                        <>
                          {item.postType === "RENTER" ?
                            item.address?.map((items) => {
                              return (
                                <PostCard item={item} index={index} items={items} />
                              )

                            })
                            :
                            <PostCard item={item} index={index} property={property} />}
                        </> : null
                    )
                  })}
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
