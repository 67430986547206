import { types } from "../type";

const initialState = {
  InqueryProfile: [],
  particularInqueryProfile: [],
};

export const inqueryMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INQUERY_LIST:
      return {
        ...state,
        InqueryProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
export const ContactMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CONTACTLIST:
      return {
        ...state,
        CONTACTLIST: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const particularInqueryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PARTICULAR_INQUERY_LIST:
      return {
        ...state,
        particularInqueryProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const particularContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PARTICULAR_CONTACT_LIST:
      return {
        ...state,
        particularContact: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
