import React from "react";
import Logo from "../../assets/icons/checkmark-circle-01.png";

export const EmailVerify = () => {
  return (
    <div className="bg-light-white" style={{ minHeight: "100vh" }}>
      <div
        className="grid justify-content-center align-items-center w-auto md:w-full"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-11 md:col-8 lg:col-6 xl:col-5">
          <div className="bg-light-main flex flex-column justify-content-center align-items-center border-round-lg p-5 p-lg-7 relative" style={{height:"392px"}}>
            <img src={Logo} alt="" width={64} className="mx-auto block mb-3" />
            <h1 className="text-4xl font-semibold text-black-dark text-center m-0 mb-3">
              Reset password email sent
            </h1>
            <p className="text-gray text-center text-lg">
              You should soon receive an email allowing you to reset your
              password. Please make sure to check your spam and trash if you
              can’t find the email.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
