import React from "react";
import { Link, useLocation } from "react-router-dom";
import LayoutData from "./LayoutData";

export default function SidebarIcons({ style, extraClass }) {
  const location = useLocation();

  const activeLink = location.pathname;
  const { menuItems } = LayoutData();
  return (
    <div
      className={`flex lg:hidden sidebar-nav flex-column justify-content-between h-full fixed overflow-y-scroll bg-lightest pt-3 left-0 text-center ${extraClass}`}
      style={style}
    >
      <ul className="pt-3 list-none p-0">
        {menuItems.map((item, key) => (
          <li
            key={key}
            className={
              item.link === activeLink
                ? "p-3 side_menuitem text-dark active "
                : "p-3 side_menuitem text-dark "
            }
          >
            <span className="flex justify-content-between align-items-center">
              <Link
                to={item.link}
                className="no-underline side_menuitem text-dark open-sans flex align-items-center"
              >
                {activeLink.includes(item.link) ? (
                  <img
                    src={item.activeicon}
                    alt=""
                    width={item.iconWidth}
                    height={item.iconHeight}
                  />
                ) : (
                  <img
                    src={item.icon}
                    alt=""
                    width={item.iconWidth}
                    height={item.iconHeight}
                  />
                )}
              </Link>
              {/* {item.subMenu ? (
                  <span onClick={() => toggleSubMenu(key)}>
                    <i
                      class={
                        isActiveSubMenu
                          ? "pi pi-chevron-up cursor-pointer"
                          : "pi pi-chevron-down cursor-pointer"
                      }
                    ></i>
                  </span>
                ) : null} */}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
