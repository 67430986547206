import axios from "axios";
import { isAuthenticated } from "./authService";
import Constants from "../utils/constants";

const successStatus = [200, 201, 204];
export const include = (arr, con) => arr && arr.includes(con);

export const formDataapi = (method, urlEndPoint, data = null) =>
  new Promise((myResolve) => {
    let headers = {
      "Content-Type": "multipart/form-data",
    };
    if (isAuthenticated()) {
      headers = {
        ...headers,
        Authorization: `Bearer ${isAuthenticated()}`,
      };
    }
    axios({
      method,
      url: Constants.BASE_URL + urlEndPoint,
      data,
      headers,
    })
      .then((response) => {
        myResolve({
          message: response.data.message,
          data: response.data.data,
          success: response.data.success,
          statusCode: include(successStatus, response.status),
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          myResolve({
            message: err?.response?.data?.message,
            statusCode: false,
          });
        }
        myResolve({
          message: err?.response?.data,
          statusCode: false,
        });
      });
  });
