import React from "react";
import DashboardLayout from "../../layout";
import { Cardcomponent } from "../../components/cards/card";
import { PostDetailTopBar } from "../../shared/PostDetailTopBar";
import {
  ClimateControl,
  ContactDetail,
  IndoorDetail,
  OutdoorDetail,
  PropertySpaceDetail,
  Propertytype,
} from "./CommanFeild";
import ButtonComponent from "../../components/buttons/button";
import { CommonIndex } from "./CommonIndex";
import { AcceptRejectPostListAction } from "../../redux/action/postListAction";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PostArchived } from "../../redux/action/archivedAction";

export const BuyerPostDetail = () => {
  const { viewPostData } = CommonIndex();
  const items = [{ label: "Post details" }];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const home = { label: "New post", url: "/" };
  const { id } = useParams();
  const saveArchived = (id) => {
    dispatch(PostArchived(id, navigate));
  };
  const Edit = (id) => {
    navigate("/edit_deatils/" + id);
    // dispatch(PostArchived(id, navigate));
  };

  const handleApprove = () => {
    dispatch(AcceptRejectPostListAction({ isVerified: "VERIFIED" }, id)).then(
      () => {
        navigate("/new-post-list");
      }
    );
  };
  const handleCancel = () => {
    dispatch(AcceptRejectPostListAction({ isVerified: "REJECTED" }, id)).then(
      () => {
        navigate("/new-post-list");
      }
    );
  };
  return (
    <DashboardLayout>
      <PostDetailTopBar
        heading="Buyer post details"
        description="Please check the details carefully. If post okay then approve it other you can cancel the post."
        id={viewPostData?.id}
        model={items}
        home={home}
        postType={viewPostData?.postType}
      />
      <Cardcomponent cardClass="border-round-2xl p-3">
        <ContactDetail
          fullName={viewPostData?.firstName + " " + viewPostData?.lastName}
          email={viewPostData?.email}
          number={viewPostData?.mobile}
          fulladdress={viewPostData?.streetAddress}
          rentAmount={viewPostData?.suburb}
        />
        <div className="grid">
          {/* Type of Purchase Section */}
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium text-2xl">
              Type of Purchase
            </h2>
          </div>
          <div className="col-12 md:col-12 mb-2">
            <span className="font-medium text-gray text-lg">
              {viewPostData?.typeOfPurchase}
            </span>
          </div>
          {/* <div className="col-12 md:col-12 mb-2">
            <p className=" text-gray font-medium text-lg p-0 m-0 ">
              {" "}
              Demo text
            </p>
          </div> */}
          <div className="col-12 md:col-12">
            <hr className="buyer-hr p-0 m-0" />
          </div>
          {/* New or established property Section */}
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium text-2xl">
              New or established property
            </h2>
          </div>
          <div className="col-12 md:col-12 mb-2">
            <span className="font-medium text-gray ">
              {viewPostData?.establishedProperty}
            </span>
          </div>
          <div className="col-12 md:col-12">
            <hr className="buyer-hr p-0 m-0" />
          </div>
          <Propertytype viewPostData={viewPostData} />
          {/* Price section */}
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium text-2xl">Price</h2>
          </div>
          <div className="col-12 md:col-12 mb-3">
            <span className="font-medium text-gray ">{`$${
              viewPostData?.priceRange?.min < 1000000
                ? viewPostData?.priceRange?.min / 1000 + "K"
                : viewPostData?.priceRange?.min / 1000000 + "M"
            } - $${
              viewPostData?.priceRange?.max < 1000000
                ? viewPostData?.priceRange?.max / 1000 + "k"
                : viewPostData?.priceRange?.max / 1000000 + "M"
            }`}</span>
          </div>
          <div className="col-12 md:col-12">
            <hr className="buyer-hr p-0 m-0" />
          </div>
          <PropertySpaceDetail viewPostData={viewPostData} />
          {/* land size */}
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium text-2xl">Land size</h2>
          </div>
          <div className="col-12 md:col-12 mb-3">
            <span className="font-medium text-gray ">{`${viewPostData?.landSize?.min} -  ${viewPostData?.landSize?.max}`}</span>
          </div>
          <div className="col-12 md:col-12">
            <hr className="buyer-hr p-0 m-0" />
          </div>
          {/* Purchase Location */}
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium text-2xl">
              Purchase Location
            </h2>
          </div>
          {viewPostData?.address?.length > 0 &&
            viewPostData?.address?.map((item, index) => {
              return (
                <div className="col-12 md:col-12 mb-3">
                  <span className="font-medium text-gray text-lg">
                    {item?.streetAddress}
                  </span>
                </div>
              );
            })}
          <div className="col-12 md:col-12">
            <hr className="buyer-hr p-0 m-0" />
          </div>
          {/* Purchase Method */}
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium text-2xl">
              Purchase method
            </h2>
          </div>
          <div className="col-12 md:col-12 mb-3">
            <span className="font-medium text-gray text-lg">
              {viewPostData?.purchaseMethod === "FINANCE" &&
              viewPostData?.financeDetails?.isArranged === true
                ? "Finance Ready"
                : viewPostData?.purchaseMethod === "FINANCE" &&
                  viewPostData?.financeDetails?.isArranged === false
                ? "Finance Not Ready"
                : viewPostData?.purchaseMethod === "OWNFUNDS"
                ? "Own Funds"
                : ""}
            </span>
          </div>
          {viewPostData?.purchaseMethod === "FINANCE" &&
          viewPostData?.financeDetails?.isArranged === true ? (
            <>
              <div className="col-12 md:col-12 mb-3">
                <span className="font-medium text-gray text-lg">
                  Pre-approved -${viewPostData?.financeDetails?.preApproved}
                </span>
              </div>
              <div className="col-12 md:col-12 mb-3">
                <span className="font-medium text-gray text-lg">
                  Deposit -${viewPostData?.financeDetails?.depositAmount}
                </span>
              </div>
            </>
          ) : (
            ""
          )}
          {viewPostData?.purchaseMethod === "FINANCE" &&
          viewPostData?.financeDetails?.isArranged === false ? (
            <>
              <div className="col-12 md:col-12 mb-3">
                <span className="font-medium text-gray text-lg">
                  Deposit -${viewPostData?.financeDetails?.depositAmount}
                </span>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="col-12 md:col-12">
            <hr className="buyer-hr p-0 m-0" />
          </div>
          <OutdoorDetail viewPostData={viewPostData} />
          <IndoorDetail viewPostData={viewPostData} />
          <ClimateControl viewPostData={viewPostData} />
          {/* Notes Section */}
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium  text-2xl p-0 m-0">
              Notes
            </h2>
          </div>
          <div className="col-12 md:col-12 mb-3">
            <div className="flex align-items-center p-0 m-0">
              <p className="p-0 m-0 text-gray font-medium">
                {viewPostData?.note === "" ? "N/A" : viewPostData?.note}
              </p>
            </div>
          </div>

          <div className="col-12 md:col-12 mb-3">
            <hr className="buyer-hr p-0 m-0" />
          </div>
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium  text-2xl p-0 m-0">
              Property must-haves
            </h2>
          </div>
          {viewPostData?.propertyMustHave?.min !== "" ||
          viewPostData?.propertyMustHave?.max !== "" ? (
            <>
              <div className="col-12 md:col-12 mb-3">
                <div className="flex align-items-center p-0 m-0">
                  <p className="p-0 m-0 text-gray font-medium">
                    {viewPostData?.propertyMustHave?.min}
                  </p>
                </div>
              </div>
              <div className="col-12 md:col-12 mb-3">
                <div className="flex align-items-center p-0 m-0">
                  <p className="p-0 m-0 text-gray font-medium">
                    {viewPostData?.propertyMustHave?.max}
                  </p>
                </div>
              </div>
            </>
          ) : viewPostData?.propertyMustHave?.min ? (
            <div className="col-12 md:col-12 mb-3">
              <div className="flex align-items-center p-0 m-0">
                <p className="p-0 m-0 text-gray font-medium">
                  {viewPostData?.propertyMustHave?.min}
                </p>
              </div>
            </div>
          ) : viewPostData?.propertyMustHave?.max ? (
            <div className="col-12 md:col-12 mb-3">
              <div className="flex align-items-center p-0 m-0">
                <p className="p-0 m-0 text-gray font-medium">
                  {viewPostData?.propertyMustHave?.min}
                </p>
              </div>
            </div>
          ) : null}

          <div className="col-12 md:col-12 mb-3">
            <hr className="buyer-hr p-0 m-0" />
          </div>
        </div>
        <div className="buyer-form-button grid p-0 m-0 flex justify-content-between">
          <div className="col-12 md:col-4 md:text-left lg:col-3 mb-3 ">
            {viewPostData?.isVerified === "VERIFIED" ? (
              ""
            ) : (
              <div className="no-underline">
                <ButtonComponent
                  onClick={() => handleCancel()}
                  className="md:text-left text-red bg-transparent border-0 lg:font-medium lg:text-sm"
                >
                  Cancel Request
                </ButtonComponent>
              </div>
            )}
          </div>
          {viewPostData?.isVerified === "VERIFIED" ? (
            ""
          ) : (
            <div className="flex gap-3">
              <div className="col-12 md:col-4 lg:col-3 lg:w-3 ">
                <ButtonComponent
                  onClick={() => Edit(id)}
                  className="edit-button-style border-round-lg border-1 px-5 bg-transparent justify-content-center uppercase w-auto button-padding font-semibold "
                >
                  Edit
                </ButtonComponent>
              </div>
              <div className="col-12 md:col-4 lg:col-3 lg:w-3 text-right">
                <ButtonComponent
                  onClick={() => handleApprove()}
                  className="border-round-lg justify-content-center uppercase w-auto border-0 button-padding text-lightest  font-semibold lg:ml-6"
                  style={{ color: "rgba(143, 154, 153, 1)" }}
                >
                  approve
                </ButtonComponent>
              </div>
            </div>
          )}
          {console.log("viewPostData?.archived", viewPostData?.isArchived)}
          {viewPostData?.isArchived === true ? (
            ""
          ) : viewPostData?.isVerified === "VERIFIED" &&
            viewPostData?.isArchived !== true ? (
            <div className="flex gap-2">
              <div className="  text-right  ">
                <ButtonComponent
                  onClick={() => saveArchived(id)}
                  className="border-round-lg border-1 bg-transparent justify-content-center uppercase w-auto border-red-600 button-padding text-red-600  font-semibold lg:ml-6"
                >
                  Archive Brief
                </ButtonComponent>
              </div>
            </div>
          ) : null}
        </div>
      </Cardcomponent>
    </DashboardLayout>
  );
};
