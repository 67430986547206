
import { types } from "../type";

const intitalState = {
    SignupProfile: {},
    SigninProfile: {},
    forgetPasswordProfile: [],
    resetPasswordProfile: {}
}

export const signupReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.SING_UP:
            return {
                ...state,
                SignupProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }

}

export const signinReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.SING_IN:
            return {
                ...state,
                SigninProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const forgetPasswordReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.FORGET_PASSWORD:
            return {
                ...state,
                forgetPasswordProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const resetPasswordReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.RESET_PASSWORD:
            return {
                ...state,
                resetPasswordProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}