import jwt_decode from "jwt-decode";

export const isAuthenticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("realStateAdmin")) {
        return JSON.parse(localStorage?.getItem("realStateAdmin"));
    } else {
        return false;
    }
};
export const authenticate = (token, next) => {
    if (typeof window !== "undefined") {
        localStorage?.setItem("realStateAdmin", JSON.stringify(token));
        next();
    }
};
export const logout = (navigate) => {
    if (typeof window !== "undefined") {
        localStorage.clear();
       navigate("/");
    }
};