import TotalUserIcon from "../../assets/icons/user-group.png";
import UserIcon from "../../assets/icons/user.png";
import SellerIcon from "../../assets/icons/Seller.png";
import RentIcon from "../../assets/icons/Rent.png";
import UserbigIcon from "../../assets/icons/Userbig.png";
import { Link, useNavigate } from "react-router-dom";
import { countAction, postListAction } from "../../redux/action/dashboardAction";
import { inqueryMessageAction } from "../../redux/action/MessageAction";
import { propertyTypeAction } from "../../redux/action/propertyAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const DashboardData = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState({
    isVerified: "VERIFIED",
    postType: ""
  })
  const [property, setProperty] = useState("")
  const handlePassMessageId = (col, row) => {
    navigate("/message", { state: { row: row?.rowIndex, col: col._id } })
  }
  useEffect(() => {
    dispatch(countAction())
    dispatch(postListAction(form))
    dispatch(propertyTypeAction())
    dispatch(inqueryMessageAction())
  }, [])
  const actionTemplate = (col, row) => {
    return (
      <div onClick={() => handlePassMessageId(col, row)} className="no-underline text-main font-medium">View</div>
    )
  }
  const mobileTemplate = (col) => {
    return (
      <div>{col.country + " " + col.mobile}</div>
    )
  }
  const overview = useSelector((state) => state.count.countProfile)
  const ApprovedPostData = useSelector((state) => state.postList.postListProfile)
  const propertyTypeData = useSelector((state) => state.propertyType.propertyProfile)
  const RecentMessageDetail = useSelector((state) => state.inquery.InqueryProfile)
  useEffect(() => {
    let array = []
    ApprovedPostData.map((item) => {
      let newArray = item.propertyType.map((child) => { return propertyTypeData?.propertyTypes?.find((innnerChild) => { return innnerChild._id == child }) })
      array.push(newArray)
    })

    setProperty(array)
  }, [propertyTypeData, ApprovedPostData])
  const overviewData = [
    {
      title: "Total user",
      icon: TotalUserIcon,
      counting: overview?.totalUser,
      percent: "+5.6%",
      name: "All"
    },
    {
      title: "Total Buyer",
      icon: UserIcon,
      counting: overview?.totalBuyer,
      percent: "+5.6%",
      name: "Buyer"
    },
    {
      title: "Total Seller",
      icon: SellerIcon,
      counting: overview?.totalSeller,
      percent: "+5.6%",
      name: "Seller"
    },
    {
      title: "Total Renter",
      icon: RentIcon,
      counting: overview?.totalRenter,
      percent: "+5.6%",
      name: "Renter"
    },
  ];
  const RecentMessageHeading = [
    {
      field: "fullName",
      header: "Name",
    },
    {
      field: "mobile",
      header: "Phone",
      body: mobileTemplate
    },
    {
      field: "action",
      header: "Action",
      body: actionTemplate
    },
  ];

  const ApprovedPost = [
    {
      location: "103 Jasper Avenue",
      price: "$2.2 M",
      imgwidth: "60px",
      image: UserbigIcon,
      requestText: "Requesting for Buy",
      idNumber: "00001",
      datePosting: "Monday 19, Sept",
      UserName: "Cody Fisher",
      totalbedroom: "2",
      residense: "House",
      totalBathroom: "2",
      totalparking: "2",
      routelink: "/new-post-list/seller-post-detail",
      profileset: "Call to Seller"
    },
  ];

  const postsHandleChange = (name) => {
    if (name !== "All") {
      let payload = {
        ...form,
        postType: name.toUpperCase()
      }
      dispatch(postListAction(payload))
    }
    else {
      let payload = {
        ...form,
        postType: ""
      }
      dispatch(postListAction(payload))
    }

  }
  const buttons = ["All", "Buyer", "Seller", "Renter"]
  return { overviewData, RecentMessageHeading, ApprovedPost, RecentMessageDetail, overview, setForm, buttons, postsHandleChange, ApprovedPostData, property };
};
export default DashboardData;
