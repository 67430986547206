import React from 'react';
import Logo from "../../assets/images/Logo.png";
import { CustomInput } from "../../components/input/AllInput";
import { Link, useNavigate } from "react-router-dom";
import { ButtonOutlined } from "../../components/buttons/button";
import { ForgetIndex } from './ForgetIndex';
export const ForgetPassword = () => {
  const { form, handleChange, handleSubmit } = ForgetIndex()
  return (
    <div className="bg-light-white" style={{ minHeight: "100vh" }}>
      <div
        className="grid justify-content-center align-items-center w-auto md:w-full"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-11 md:col-8 lg:col-6 xl:col-5">
          <div className="bg-light-main border-round-lg p-4 relative">

            <h1 className="text-4xl text-black-dark text-center m-0 mb-4">Forgot Password</h1>
            <img src={Logo} alt="" width={229} className="m-auto block mb-3" />
            <CustomInput label="Email" placeholder="Email" col={12} name="email" value={form?.email} onChange={handleChange} errorMessage={form?.formErrors?.email} />
            <ButtonOutlined label="RESET PASSWORD" onClick={handleSubmit} />
            <div className="text-center mt-4">
              <p className="text-gray">
                Have an account?{" "}
                <Link to="/" className="text-main no-underline font-medium">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
