import { types } from "../type";

const intitalState = {
  countProfile: {},
  postListProfile: [],
  viewPostProfile: [],
  growthProfile: {},
};

export const countReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.COUNT:
      return {
        ...state,
        countProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const postListReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.POST_LIST:
      return {
        ...state,
        postListProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const viewPostReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.VIEW_POST:
      return {
        ...state,
        viewPostProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const growthReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GROWTH_CHART:
      return {
        ...state,
        growthProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
