import React, { useEffect, useState } from "react";
import { userListAction, userProfileAction } from "../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
export const UserIndex = () => {
    const dispatch = useDispatch()
    const [form, setForm] = useState("")
    const [newTableData, setNewTableData] = useState([])
    const names = useParams()
    useEffect(() => {
        if (names?.keyword === "") {
            dispatch(userListAction({}))
        }
    }, [names])
    const handleSubmit = (id) => {
        dispatch(userProfileAction(id))
    }
    const postsHandleChange = (name) => {
        if (name !== "All") {
            let payload = {
                ...form,
                role: name?.toUpperCase()
            }
            dispatch(userListAction(payload))
        }
        else {
            let payload = {
                ...form,
                role: ""
            }
            dispatch(userListAction(payload))
        }

    }
    useEffect(() => {
        if (names?.keyword !== "") {
            postsHandleChange(names?.keyword)
        }
    }, [names])
    const ParticularUserData = useSelector((state) => state.user.userProfile)
    const buttons = ["All", "Buyer", "Seller", "Renter"]
    const userListData = useSelector((state) => state.userList.userListProfile)
    return { userListData, handleSubmit, ParticularUserData, buttons, postsHandleChange, newTableData, setNewTableData,names}
}
