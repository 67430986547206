import React from "react";

export default function InputLayout({
  labelClassName,
  label,
  name,
  required,
  col,
  extraClassName,
  errorMessage,
  data,
  children,
}) {
  col = parseInt(col);
  if (col > 12) {
    col = 12;
  }
  return (
    <div
      className={` mb-3 col-12  ${
        col > 6
          ? "md:col-12 lg:col-12"
          
          : col === 4
            ? "md:col-4 lg:col-4"
            : "md:col-6 lg:col-6"
      } ${extraClassName}`}
    >
      {label ? (
        <label
          className={`block mb-2 font-medium text-lg text-black-dark font-medium ${labelClassName}`}
          htmlFor={name}
        >
          {label}
          {required ? <span className="p-error">*</span> : ""}
        </label>
      ) : null}

      <div className="w-full">{children}</div>
      <div>
        {errorMessage || data?.formErrors?.[name] ? (
          <small className="p-error">
            {errorMessage || data?.formErrors?.[name]}
          </small>
        ) : null}
      </div>
    </div>
  );
}
