import React from "react";
import { useSelector } from "react-redux";
import { ProgressSpinner } from 'primereact/progressspinner';
const Loader = () => {
    const isLoading = useSelector((state) => state.loader.isLoading);
    const ternary = (bool, truth, faulty) => (bool ? truth : faulty)
    return (
        <div style={{ display: ternary(isLoading, "block", "none") }}>
            <div
                style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#ffffff",
                    opacity: 0.6,
                    zIndex: 3000,
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        left: "50%",
                        zIndex: 1001,
                        transform: "translate(-50%,-50%)"

                    }}
                >
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} className='text-center h-screen flex justify-content-center' strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            </div>
        </div>
    );
};
export default Loader;