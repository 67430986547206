import React from "react";
import _ from "lodash";
import InputLayout from "./InputLayout";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";

export function CustomForm({ children, ...props }) {
  return (
    <div className="grid grid-nogutter" {...props}>
      {children}
    </div>
  );
}

export const CustomInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  disabled = false,
  userref,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputText
        id={name}
        name={name}
        ref={userref}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={`custom-input border-1 border-round-lg w-full  bg-transparent ${
          inputClass ? inputClass : ""
        } ${errorMessage ? "p-invalid" : ""}`}
        disabled={disabled}
        {...props}
      />
    </InputLayout>
  );
};
export const CustomPassword = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  disabled = false,
  passref,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Password
        id={name}
        name={name}
        ref={passref}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={`w-full border-0 block bg-transparent  ${
          inputClass ? inputClass : ""
        } ${errorMessage ? "p-invalid" : ""}`}
        disabled={disabled}
        {...props}
        toggleMask
      />
    </InputLayout>
  );
};
export function CustomCheckbox({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  options,
  optionLabel,
  ...props
}) {
  return (
    <div className={`flex align-items-center text-md text-gray font-medium`}>
      <Checkbox
        value={value}
        // onChange={(e) =>
        //   onChange && onChange({ name, value: e.target.value, ...e })
        // }
        onChange={onChange}
        checked={value}
        // checked={_.isEqual(value || data?.[name])}
        id={name}
        name={name}
        {...props}
      />
      &nbsp;&nbsp;
      <label htmlFor={name}>{optionLabel}</label>
    </div>
  );
}
