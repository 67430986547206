import { types } from "../type";

const intitalState = {
  SubscribersList: [],
};

export const SubscribersReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.SUBSCRIBERS:
      return {
        ...state,
        SubscribersList: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
