import { Link } from "react-router-dom";
const actionTemplate = (col) => {
  return (
    <Link to={`/user-list/user-profile/${col._id}`} className="no-underline text-main font-medium">
      View profile
    </Link>
  );
};
const nameTemplate = (col) => {
  return (
    <div>
      {col.firstName + " " + col.lastName}
    </div>
  )
}
const mobileTemplate = (col) => {
  return (
    <div>
      {col.countryCode + " " + col.mobile}
    </div>
  )
}
const addressTempltae = (col) => {
  return (
    <div>{col.address ? col.address:"-"}</div>
  )
}
const suburbTemplate = (col)=>{
  return(
    <div>{col?.suburb?.length > 40 ? col.suburb.substring(0, 40):col.suburb}</div>
  )
}
const UserData = () => {
  const RecentJoinHeading = [
    {
      field: "firstName",
      header: "Name",
      body: nameTemplate
    },
    {
      field: "suburb",
      header: "Suburb",
      body:suburbTemplate
    },
    {
      field: "mobile",
      header: "Phone",
      body: mobileTemplate
    },
    {
      field: "email",
      header: "Email",
    },
    {
      field: "role",
      header: "Status",
    },

    {
      field: "action",
      header: "Action",
      body: actionTemplate,
    },
  ];

  return { RecentJoinHeading };
};
export default UserData;
