import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { TabPanel, TabView } from "primereact/tabview";
import {
  Cardcomponent,
  PendingPostCard,
  PostCard,
} from "../../components/cards/card";
import { Button } from "primereact/button";
import { ButtonOutlined } from "../../components/buttons/button";
import checkIcon from "../../assets/icons/Checkmark-border.png";
import AlertIcon from "../../assets/icons/alert-circle.png";
import NewPostData from "./NewPostData";
import { Paginator } from "primereact/paginator";
import { useLocation } from "react-router-dom";
export const NewPostList = () => {
  const location = useLocation();
  const {
    ApprovedPostData,
    activeIndex,
    setActiveIndex,
    setHighlight,
    highlight,
    handleDropdown,
    handleSubmit,
    handleApprove,
    handleCancel,
    buttons,
    first,
    rows,
    onPageChange,
    newTableData,
    isOpen,
    setIsOpen,
  } = NewPostData();
  useEffect(() => {
    if (location.search === "?postType=Pending") {
      setActiveIndex(1);
    }
  }, []);
  console.log("location", location);
  const [name, setName] = useState();
  return (
    <DashboardLayout>
      <div className="flex align-items-center justify-content-between">
        <h1 className="text-black-dark font-semibold">New Post Brief</h1>
        <div className="relative">
          <ButtonOutlined
            label={name ? name : "All Showing"}
            className="w-auto button-padding text-sm py-2 surface-border text-light-gray nrmal-btn"
            onClick={() => setIsOpen(!isOpen)}
          />
          {isOpen && (
            <div className="absolute top-100 bg-white p-2 shadow-1 w-full">
              {buttons?.map((item, index) => {
                return (
                  <p
                    className={
                      highlight === index
                        ? "bg-lightest cursor-pointer"
                        : "cursor-pointer"
                    }
                    onClick={() => {
                      handleDropdown(item);
                      setHighlight(index);
                      setIsOpen(false);
                      setName(item);
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="flex post-tabs  align-items-center gap-2 mb-3">
        <Button
          onClick={(e) => handleSubmit("Approved")}
          className={`bg-none text-main p-button-text border-none p-3 text-lg approved font-medium px-3 border-round-xl nrmal-btn font-semibold ${
            activeIndex === 0
              ? "active border-bottom-1 border-bottom-color"
              : " "
          }`}
          name="Approved"
          label="Approved Briefs"
        />
        <Button
          onClick={(e) => handleSubmit("Pending")}
          className={`bg-none border-none p-button-text p-3 text-red text-lg pending font-medium px-3 border-round-xl nrmal-btn font-semibold ${
            activeIndex === 1
              ? "active border-bottom-1 border-bottom-color"
              : " "
          }`}
          name="Pending briefs"
          label="Pending Briefs"
        />
      </div>
      <TabView
        activeIndex={activeIndex}
        className="dashboard-news"
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel>
          <Cardcomponent
            src={checkIcon}
            showText="Approved Showings"
            listingShow={`${ApprovedPostData?.length} Listings`}
            textClass="text-gray"
            extraClass="bg-lightest "
            cardClass="border-noround shadow-2"
          >
            {newTableData?.map((item, index) => {
              return (
                <>
                  {item.postType === "BUYER" || item?.postType === "RENTER" ? (
                    item.address?.map((items) => {
                      return (
                        <PostCard item={item} index={index} items={items} />
                      );
                    })
                  ) : (
                    <PostCard item={item} index={index} />
                  )}
                </>
              );
            })}
          </Cardcomponent>
        </TabPanel>
        <TabPanel>
          <Cardcomponent
            src={AlertIcon}
            showText="Pending Showings"
            listingShow={`${ApprovedPostData?.length} Listings`}
            textClass="text-red"
            extraClass="bg-light-red border-round-top-lg"
            cardClass="shadow-2 border-noround"
          >
            {newTableData.map((item, index) => (
              <>
                {item.postType === "BUYER" || item?.postType === "RENTER" ? (
                  item.address?.map((items) => {
                    return (
                      <PendingPostCard
                        handleApprove={handleApprove}
                        handleCancel={handleCancel}
                        item={item}
                        index={index}
                        items={items}
                      />
                    );
                  })
                ) : (
                  <PendingPostCard
                    handleApprove={handleApprove}
                    handleCancel={handleCancel}
                    item={item}
                    index={index}
                  />
                )}
              </>
            ))}
          </Cardcomponent>
        </TabPanel>
      </TabView>
      <Paginator
        className="justify-content-end"
        first={first}
        rows={rows}
        totalRecords={ApprovedPostData?.length}
        onPageChange={onPageChange}
      />
    </DashboardLayout>
  );
};
