import React from "react";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import ButtonComponent, { ButtonOutlined } from "../buttons/button";
import UserIcon from "../../assets/icons/Userbig.png";
import LocationIcon from "../../assets/icons/location-02.png";
import BedIcon from "../../assets/icons/bad (1).png";
import BathtubIcon from "../../assets/icons/bath.png";
import CarIcon from "../../assets/icons/car.png";
import CalendarIcon from "../../assets/icons/Calendar.png";
import DiscoverIcon from "../../assets/icons/discover-circle.png";
import CallingIcon from "../../assets/icons/calling.png";
import CheckMarkIcon from "../../assets/icons/Checkmark.png";
import TagIcon from "../../assets/icons/Tag.png";
import { Link, useNavigate } from "react-router-dom";
import UserbigIcon from "../../assets/icons/Userbig.png";
import buyerImageIcon from "../../assets/icons/Rentbig.png";
import Constants from "../../utils/constants";
import moment from "moment";

export const OverViewCard = ({
  title,
  icon,
  counting,
  percent,
  arrowicon,
  onClick,
}) => {
  return (
    <div className="lg:col-3 md:col-6 mb-3">
      <Card
        className="border-round-lg shadow-none border-1 surface-border overview-card"
        onClick={onClick}
      >
        <div className="flex align-items-center justify-content-between mb-3">
          <p className="m-0 text-gray font-medium">{title}</p>
          <div className="bg-lightest flex align-items-center p-2 radius-14">
            <img src={icon} alt="" width={24} />
          </div>
        </div>
        <div className="flex align-items-center justify-content-between">
          <h3 className="m-0 text-black-dark text-xl inter">{counting}</h3>
          <div className="flex align-items-center">
            <img src={arrowicon} alt="" />
            &nbsp;
            <small className="text-gray text-md font-medium inter">
              {percent}
            </small>
          </div>
        </div>
      </Card>
    </div>
  );
};
export function PostCard({
  to,
  label,
  className,
  loading,
  location,
  onClick,
  price,
  requestText,
  src,
  idNumber,
  datePosting,
  UserName,
  totalbedroom,
  totalBathroom,
  totalparking,
  residense,
  profileset,
  imgwidth,
  height,
  item,
  index,
  items,
  property,
  ...props
}) {
  const navigate = useNavigate();
  function callPhoneNumber(phoneNumber) {
    window.location.href = "tel:" + phoneNumber;
  }
  return (
    <div className="grid border-bottom-1 py-3 surface-border">
      <div className="lg:col-8 col-12">
        <div className="flex align-items-center">
          <div className="bg-light-main news-img flex align-items-center justify-content-center border-round-lg">
            <img
              src={
                item.postType === "BUYER"
                  ? UserbigIcon
                  : item.postType === "RENTER"
                  ? buyerImageIcon
                  : item.postType === "SELLER" && item.image[0]?.length > 0
                  ? Constants.BASE_URL + `${item?.image[0]}`
                  : null
              }
              alt=""
              width={imgwidth}
              className="w-full h-full"
            />
          </div>
          <div className="ml-3 w-9">
            <p className="m-0 font-medium flex align-items-center mb-3 text-lg text-gray">
              <img src={LocationIcon} alt="" /> &nbsp;&nbsp;
              {item.postType === "BUYER"
                ? items?.streetAddress
                : item.postType === "SELLER"
                ? item?.address?.length > 0 && item?.address[0]?.streetAddress
                : item.postType === "RENTER"
                ? items?.streetAddress
                : "australia"}
            </p>
            <p className="m-0 font-medium flex align-items-center mb-3 text-gray">
              <img src={TagIcon} alt="" /> &nbsp;&nbsp;
              <span>
                {item.postType === "BUYER"
                  ? `$${
                      item.priceRange.min < 1000000
                        ? item.priceRange.min / 1000 + "K"
                        : item.priceRange.min / 1000000 + "M"
                    } - $${
                      item.priceRange.max < 1000000
                        ? item.priceRange.max / 1000 + "k"
                        : item.priceRange.max / 1000000 + "M"
                    }`
                  : item.postType === "SELLER"
                  ? `$${item.price}`
                  : item.postType === "RENTER"
                  ? `$${
                      item.priceRange.min < 1000000
                        ? item.priceRange.min / 1000 + "K"
                        : item.priceRange.min / 1000000 + "M"
                    } - $${
                      item.priceRange.max < 1000000
                        ? item.priceRange.max / 1000 + "k"
                        : item.priceRange.max / 1000000 + "M"
                    }`
                  : null}
              </span>
              &nbsp; | &nbsp;
              <span className="text-sm">
                {item.postType === "BUYER"
                  ? "Requesting for Buy"
                  : item.postType === "RENTER"
                  ? "Requesting for Rent"
                  : item.postType === "SELLER"
                  ? "Requesting for Sell"
                  : ""}
              </span>
              &nbsp; | &nbsp;
              <span className="text-sm inline-block min-w-max">
                {item?.postType}:
                <span className="text-black-dark font-bold ">
                  &nbsp; {item?.postType.slice(0, 1)}
                  &nbsp; {item?.id}
                </span>
              </span>
            </p>
            <div className="flex align-items-center">
              <p className="m-0 font-medium flex align-items-center">
                <span className="flex align-items-center">
                  <img src={BedIcon} alt="" />
                  &nbsp;{item.bedrooms === null ? "any" : `${item.bedrooms}+`}
                </span>
                <span className="flex align-items-center ml-3">
                  <img src={BathtubIcon} alt="" />
                  &nbsp;{item.bathrooms === null ? "any" : `${item.bathrooms}`}
                </span>
                <span className="flex align-items-center ml-3">
                  <img src={CarIcon} alt="" />
                  &nbsp;{item.carSpaces === null ? "any" : `${item.carSpaces}`}
                </span>
                &nbsp;&nbsp;|
                <span className="flex align-items-center ml-3">
                  {item?.propertyType[0]?.propertyType}{" "}
                </span>
                <span className="flex align-items-center ml-3 text-sm text-gray">
                  <img src={CalendarIcon} alt="" />
                  &nbsp;
                  {item.postType === "BUYER"
                    ? item?.purchaseTimeFrame
                    : item.postType === "RENTER"
                    ? moment(item?.availableDate?.startDate).format(
                        "dddd DD, MMMM"
                      )
                    : moment(item.buildYear).format("YYYY")}
                </span>
              </p>
              <div className="text-main text-sm font-medium bg-light-main p-2 m-0 flex align-items-center ml-4">
                Approved
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:col-4 col-12 flex-column flex justify-content-between">
        <div className="flex align-items-center justify-content-start lg:justify-content-end mb-3">
          <Avatar
            icon="pi pi-user"
            className="bg-light-main text-main"
            style={{ backgroundColor: "#9c27b0", color: "#ffffff" }}
            shape="circle"
            size="large"
          />
          <small className="text-base ml-2 font-medium text-gray-dark">
            {item.firstName + " " + item.lastName}
          </small>
        </div>
        <div className="flex justify-content-end">
          <ButtonOutlined
            className="justify-content-center border-round-lg button-padding text-sm w-auto nrmal-btn"
            onClick={() =>
              item.postType === "BUYER"
                ? navigate(`/new-post-list/buyer-post-detail/${item?._id}`)
                : item.postType === "SELLER"
                ? navigate(`/new-post-list/seller-post-detail/${item._id}`)
                : item.postType === "RENTER"
                ? navigate(`/new-post-list/renter-post-detail/${item._id}`)
                : null
            }
          >
            <img src={DiscoverIcon} alt="" className="mr-1" />
            &nbsp; View Brief
          </ButtonOutlined>
          <ButtonComponent
            className="justify-content-center ml-2 border-round-lg border-0 button-padding text-sm w-auto nrmal-btn text-lightest"
            onClick={() => callPhoneNumber(item?.mobile)}
          >
            <img src={CallingIcon} alt="" className="mr-1" />
            &nbsp;
            {item.postType === "BUYER"
              ? "Call to Buyer"
              : item.postType === "RENTER"
              ? "Call to Renter"
              : item.postType === "SELLER"
              ? "Call to Seller"
              : ""}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
}
export function PendingPostCard({
  to,
  onClick,
  label,
  className,
  loading,
  location,
  price,
  requestText,
  idNumber,
  datePosting,
  UserName,
  src,
  handleApprove,
  item,
  handleCancel,
  index,
  imgwidth,
  archived = false,
  items,
  ...props
}) {
  const navigate = useNavigate();
  return (
    <div className="grid border-bottom-1 py-3 surface-border">
      <div className="lg:col-6 col-12">
        <div className="flex align-items-center">
          <div className="bg-light-main news-img flex align-items-center justify-content-center border-round-lg">
            <img
              src={
                item.postType === "BUYER"
                  ? UserbigIcon
                  : item.postType === "RENTER"
                  ? buyerImageIcon
                  : item.postType === "SELLER" && item.image[0]?.length > 0
                  ? Constants.BASE_URL + `${item?.image[0]}`
                  : null
              }
              alt=""
              width={imgwidth}
              className="w-full h-full"
            />
          </div>
          <div className="ml-3">
            <p className="m-0 font-medium flex align-items-center mb-2 text-lg text-gray">
              <img src={LocationIcon} alt="" /> &nbsp;&nbsp;
              {item.postType === "BUYER"
                ? items.streetAddress
                : item.postType === "SELLER"
                ? item?.address[0]?.streetAddress
                : item.postType === "RENTER"
                ? items.streetAddress
                : "australia"}
            </p>
            <p className="m-0 font-medium flex align-items-center mb-2 text-gray">
              <img src={TagIcon} alt="" /> &nbsp;&nbsp;
              <span>
                {item.postType === "BUYER"
                  ? `$${
                      item.priceRange.min < 1000000
                        ? item.priceRange.min / 1000 + "K"
                        : item.priceRange.min / 1000000 + "M"
                    } - $${
                      item.priceRange.max < 1000000
                        ? item.priceRange.max / 1000 + "k"
                        : item.priceRange.max / 1000000 + "M"
                    }`
                  : item.postType === "SELLER"
                  ? `$${item.price}`
                  : item.postType === "RENTER"
                  ? `$${
                      item.priceRange.min < 1000000
                        ? item.priceRange.min / 1000 + "K"
                        : item.priceRange.min / 1000000 + "M"
                    } - $${
                      item.priceRange.max < 1000000
                        ? item.priceRange.max / 1000 + "k"
                        : item.priceRange.max / 1000000 + "M"
                    }`
                  : null}
              </span>
              &nbsp; | &nbsp;
              <span className="text-sm">
                {item.postType === "BUYER"
                  ? "Requesting for Buy"
                  : item.postType === "RENTER"
                  ? "Requesting for Rent"
                  : item.postType === "SELLER"
                  ? "Requesting for Sell"
                  : ""}
              </span>
              &nbsp; | &nbsp;
              <span className="text-sm inline-block min-w-max">
                {item?.postType} Id:
                <div className="text-black-dark font-bold">
                  &nbsp; {item?.postType.slice(0, 1)}
                  &nbsp; {item?.id}
                </div>
              </span>
            </p>
            <div className="flex ">
              <p className="m-0 font-medium flex align-items-center mb-2">
                <span className="flex align-items-center text-sm text-gray">
                  <img src={CalendarIcon} alt="" />
                  &nbsp;
                  {item.postType === "BUYER"
                    ? item?.purchaseTimeFrame
                    : item.postType === "RENTER"
                    ? item?.purchaseTimeFrame
                    : // moment(item?.availableDate?.startDate).format(
                      //     "dddd DD, MMMM"
                      //   )
                      moment(item?.buildYear).format("YYYY")}
                </span>
              </p>
              {!archived ? (
                <div className="text-sm font-medium text-red bg-light-red p-2 px-3 ml-4 m-0 flex align-items-center">
                  Pending
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:col-6 col-12 flex-column flex justify-content-between">
        <div className="flex align-items-center justify-content-start lg:justify-content-end mb-3">
          <Avatar
            icon="pi pi-user"
            className="bg-light-main text-main"
            style={{ backgroundColor: "#9c27b0", color: "#ffffff" }}
            shape="circle"
            size="large"
          />
          <small className="text-base ml-2 text-gray-dark font-medium">
            {item.firstName + " " + item.lastName}
          </small>
        </div>

        <div className="flex justify-content-end">
          {archived ? (
            <ButtonOutlined
              className="justify-content-center border-round-lg button-padding text-sm w-auto font-medium nrmal-btn"
              onClick={() =>
                item.postType === "BUYER"
                  ? navigate(`/archived/buyer-post-detail/${item?._id}`)
                  : item.postType === "SELLER"
                  ? navigate(`/archived/seller-post-detail/${item._id}`)
                  : item.postType === "RENTER"
                  ? navigate(`/archived/renter-post-detail/${item._id}`)
                  : null
              }
            >
              <img src={DiscoverIcon} alt="" className="mr-1" />
              &nbsp; View Brief
            </ButtonOutlined>
          ) : (
            <ButtonOutlined
              className="justify-content-center border-round-lg button-padding text-sm w-auto font-medium nrmal-btn"
              onClick={() =>
                item.postType === "BUYER"
                  ? navigate(`/new-post-list/buyer-post-detail/${item?._id}`)
                  : item.postType === "SELLER"
                  ? navigate(`/new-post-list/seller-post-detail/${item._id}`)
                  : item.postType === "RENTER"
                  ? navigate(`/new-post-list/renter-post-detail/${item._id}`)
                  : null
              }
            >
              <img src={DiscoverIcon} alt="" className="mr-1" />
              &nbsp; View Brief
            </ButtonOutlined>
          )}
          {!archived ? (
            <>
              <ButtonComponent
                className="justify-content-center ml-2 border-round-lg border-0 button-padding text-sm w-auto font-medium nrmal-btn text-lightest"
                onClick={(e) => handleApprove(item._id, "VERIFIED")}
              >
                <img src={CheckMarkIcon} alt="" className="mr-1" />
                &nbsp; Approve
              </ButtonComponent>
              <ButtonComponent
                className="justify-content-center align-items-center ml-2 border-round-lg bg-red-600 border-0 button-padding text-sm w-auto font-medium nrmal-btn cancel-button"
                onClick={() => handleCancel(item._id, "REJECTED")}
              >
                <i className="pi pi-times mr-1 text-sm"></i>&nbsp; Cancel
                Request
              </ButtonComponent>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export function Cardcomponent({
  children,
  extraClass,
  showText,
  src,
  textClass,
  listingShow,
  cardClass,
}) {
  return (
    <>
      {showText ? (
        <div
          className={`flex p-3 justify-content-between align-items-center ${extraClass}`}
        >
          <div className="flex align-items-center">
            <img src={src} alt="" width={25} />
            <span className={`text-base ml-2 font-medium ${textClass}`}>
              {showText}
            </span>
          </div>
          <p className="m-0 text-gray font-medium">{listingShow}</p>
        </div>
      ) : null}
      <Card className={cardClass}>{children}</Card>
    </>
  );
}
