import { types } from "../type";
import api from "../../services/api";
import Constants from "../../utils/constants";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

export const userListAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.USERLIST, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USER_LIST,
                payload: res.data.reverse()
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const userProfileAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("get", `${Constants.END_POINT.USERPROFILE}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USER_PROFILE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}