import { types } from "../type";

const intitalState = {
  getArchivedPost: [],
};

export const archivedPostReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_ARCHIVED_POST:
      return {
        ...state,
        getArchivedPost: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
export const postArchivedReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_ARCHIVED_POST:
      return {
        ...state,
        getArchivedPost: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
