import React from "react";
import Logo from "../../assets/images/Logo.png";
import {
  CustomCheckbox,
  CustomInput,
  CustomPassword,
} from "../../components/input/AllInput";
import { Link } from "react-router-dom";
import { ButtonOutlined } from "../../components/buttons/button";
import { SignupIndex } from "./signupIndex";
export const Signup = () => {
  const { form, handleChange, handleSubmit, remimber, setRemimber } = SignupIndex()
  return (
    <div className="bg-light-white" style={{ minHeight: "100vh" }}>
      <div
        className="grid justify-content-center align-items-center w-auto md:w-full"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-11 md:col-8 lg:col-6 xl:col-5">
          <div className="bg-light-main border-round-lg p-4 relative">
            <h1 className="text-4xl text-center font-semibold text-black-dark m-0 mb-4">
              Welcome Back!
            </h1>
            <img src={Logo} alt="" width={229} className="m-auto block mb-3" />
            <CustomInput label="Email" col={12} placeholder="Email" name="email" value={form.email} onChange={handleChange} errorMessage={form?.formErrors?.email} />
            <CustomPassword label="Password" col={12} placeholder="Password" name="password" value={form?.password} onChange={handleChange} errorMessage={form?.formErrors?.password} />
            <CustomPassword
              label="Confirm Password"
              col={12}
              placeholder="Confirm Password"
              name="confirmPassword"
              value={form?.confirmPassword}
              onChange={handleChange}
              errorMessage={form?.formErrors?.confirmPassword}
            />
            <div className="flex justify-content-between align-items-center mb-4">
              <CustomCheckbox optionLabel="Remember me" col={4} value={remimber} onChange={(e) => setRemimber(e.checked)} />
            </div>
            <ButtonOutlined label="SIGN UP" onClick={handleSubmit} />
            <div className="text-center mt-4">
              <p className="text-gray font-medium">
                Have an account?{" "}
                <Link to="/" className="text-main no-underline font-medium">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
