import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { PostDetailTopBar } from "../../shared/PostDetailTopBar";
import { Cardcomponent } from "../../components/cards/card";
import {
  ClimateControl,
  ContactDetail,
  IndoorFeatures,
  NewEstablishedProperty,
  OutdoorFeatures,
  PropertyDetail,
  PropertyType,
} from "./CommonFeilds";
import { CustomTextArea } from "../../components/input/CustomInputs";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { CustomCheckbox, CustomInput } from "../../components/input/AllInput";
import { EditData } from "./EditData";
import ButtonComponent from "../../components/buttons/button";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { createBuyerAction } from "../../redux/action/propertyAction";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SellerEditData, SellerIndex } from "./SellerEditData";
import moment from "moment";
import Constants from "../../utils/constants";

const SellerEditdata = () => {
  const items = [{ label: "Post details" }];
  const home = { label: "New post", url: "/" };
  const [date, setDate] = useState(null);
  const navigate = useNavigate();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const [visible, setVisible] = useState(false);
  const {
    data,
    setData,
    code,
    setCode,
    handleChange,
    show,
    id,
    handleRemoveClick,
    emailCheck,
    token,
    handleRemoveFloorPlane,
    floorPlan,
    address,
    handleChangeAddress,
    handleSelectAddress,
    handlechangeStreetAddress,
    handleSelectStreetAddress,
    handleRoomChange,
    highlight,
    bathRoomHightlight,
    carHightlight,
    handleClear,
    unitData,
    inputList,
    setInputList,
    handleChangeAddFloorPlan,
    PropertyList,
    bedRoomQuantity,
    bathroomQuantity,
    carSpaceQuantity,
    minLandSize,
    maxLandSize,
    maxLand,
    minLand,
    handleTypeChange,
    check,
    setChecked,
    page,
    setPage,
    handleSubmit,
    handleAddImage,
    handleImage,
    viewPostData,
    handleSelectAddressSuburb,
    handleChangeSuburb,
  } = SellerIndex();
  const location = useLocation();

  useEffect(() => {
    setData({
      ...data,
      buildYear: moment(date).format("YYYY"),
    });
  }, [date]);
  useEffect(() => {
    if (emailCheck === true && token === null) {
      setVisible(true);
    }
  }, [emailCheck]);
  const handleChangeName = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
  };
  return (
    <DashboardLayout>
      {console.log("data", data)}
      <PostDetailTopBar
        heading="Seller post details"
        description="Please check the details carefully. If post okay then approve it other you can cancel the post."
        id={viewPostData?.id}
        model={items}
        home={home}
      />
      <div className="grid p-0 m-0">
        <ContactDetail
          data={data}
          show={show}
          token={token}
          setData={setData}
          code={code}
          setCode={setCode}
          handleChangeName={handleChangeName}
          handleChange={handleChange}
          handleSelectAddressSuburb={handleSelectAddressSuburb}
          handleChangeSuburb={handleChangeSuburb}
          address={address}
          handleSelectStreetAddress={handleSelectStreetAddress}
          handlechangeStreetAddress={handlechangeStreetAddress}
          emailCheck={emailCheck}
        />

        <NewEstablishedProperty
          data={data}
          setData={setData}
          handleChange={handleChange}
        />
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold">
          Property details<code className="p-error text-sm">*</code>
        </h2>
        <div
          className={`col-12 md:col-6 ${
            data?.formErrors?.password?.length > 0 ||
            data?.formErrors?.confirmPassword?.length > 0
              ? "pointer-events-none"
              : ""
          }`}
        >
          <PlacesAutocomplete
            value={
              data?.address?.streetAddress || data?.address[0]?.streetAddress
            }
            onChange={handleChangeAddress}
            onSelect={handleSelectAddress}
            searchOptions={{
              componentRestrictions: { country: ["au"] },
              // types: ["locality"],
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
              item,
            }) => (
              <div className="subhurb-hightlight mt-2 w-full relative ">
                <CustomInput
                  label="Property Address"
                  extraClassName="w-full"
                  value={
                    data?.address?.streetAddress ||
                    data?.address[0]?.streetAddress
                  }
                  errorMessage={data?.formErrors?.address}
                  {...getInputProps({
                    placeholder: "Search Full Address or Suburb Only",
                  })}
                  required
                />

                {show === true ? (
                  <div className="autocomplete-dropdown-container absolute top-100  bg-white z-2 p-0 shadow-1  mb-2 ">
                    {loading && <div>Loading...</div>}
                    {suggestions?.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                        : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span className="">{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </PlacesAutocomplete>
          <p className="ml-1" style={{ marginTop: "-15px" }}>
            {" "}
            Note- Only the Suburb will display on the Brief, to keep your
            details private.
          </p>
        </div>
        <div className="col-12 md:col-6">
          <div className="w-full lg:mt-3 md:mt-3 p-0 m-0 ">
            <label className="text-dark text-lg font-medium flex align-items-start font-bold ml-1">
              Build Year<code className="p-error text-sm">*</code>
            </label>
            <Calendar
              value={date || data.buildYear}
              onChange={(e) => setDate(e.value)}
              view="year"
              dateFormat="yy"
              className="seller-calender-picker w-full"
              inputClassName="p-3 mt-2 ml-1 mr-1 mb-3 w-full"
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
            />
            {data?.formErrors?.buildYear && (
              <small className="p-error text-sm ml-2">
                {data?.formErrors?.buildYear}
              </small>
            )}
          </div>
        </div>
        <div className="col-12 md:col-6 ">
          <CustomInput
            label="Properties Description"
            extraClassName="w-full"
            placeholder="Keep it short"
            labeClassName="text-dark"
            name="propertiesTitle"
            value={data?.propertiesTitle}
            onChange={handleChange}
            required
            errorMessage={data?.formErrors?.propertiesTitle}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
        <div className="col-12 md:col-6">
          <div className="w-full lg:mt-2 md:mt-2 p-0 m-0">
            <label className="text-dark text-lg font-medium flex align-items-start font-bold ml-1">
              Asking Price ($)
              <code className="p-error text-sm">*</code>
            </label>
            <InputNumber
              inputId="integeronly"
              value={data?.price}
              onValueChange={handleChange}
              name="price"
              placeholder="How much do you want to sell your property for?"
              className="w-full  bg-transparent mb-3"
              inputClassName="mt-2 p-3 ml-1 mr-1"
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
            />
            {data?.formErrors?.price && (
              <small className="p-error text-sm ml-2">
                {data?.formErrors?.price}
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <CustomTextArea
            label="Property Highlights"
            placeholder="More Details = More Interested Buyers!"
            className=" w-full"
            extraClassName="w-full"
            name="Description"
            value={data?.Description}
            onChange={handleChange}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
        <h2 className="text-dark font-medium text-lg m-0 ml-1 py-0 col-12 mb-2 flex align-items-start font-bold">
          Add photos<code className="p-error text-sm">*</code>
        </h2>
        {data?.formErrors?.image && (
          <small className="p-error text-sm ml-3">
            {data?.formErrors?.image}
          </small>
        )}
        <div
          className={`col-12 grid p-0 m-0 ${
            data?.formErrors?.password?.length > 0 ||
            data?.formErrors?.confirmPassword?.length > 0
              ? "pointer-events-none"
              : ""
          }`}
        >
          {inputList?.map((item, index) => {
            return (
              <>
                <div className="col-12 md:col-4 lg:col-2 mb-0 ">
                  <div className="image-box">
                    <div
                      className="photo-upload bg-lightest-gray text-center border-round-lg relative"
                      style={{
                        height: "114px",
                        width: "100%",
                      }}
                    >
                      <input
                        onChange={(e) => handleImage(e, index)}
                        id="formImage"
                        type="file"
                        accept="image/*"
                        className="absolute w-full h-full left-0 top-0 opacity-0"
                        multiple
                      />
                      <label className="h-full flex flex-column align-items-center justify-content-center">
                        {inputList?.length !== 1 && (
                          <i
                            className="pi pi-times absolute left-10 top-0 right-0"
                            onClick={() => handleRemoveClick(index)}
                            style={{ color: "#708090" }}
                          ></i>
                        )}
                        <img
                          className={
                            item.upload === ""
                              ? "w-2"
                              : "w-full h-full fit-cover"
                          }
                          src={
                            typeof item?.upload === "string"
                              ? Constants.BASE_URL + `${item?.upload}`
                              : typeof item?.upload === "object" &&
                                item?.upload !== null
                              ? window.URL.createObjectURL(item?.upload)
                              : "Gallery"
                          }
                          alt=""
                        />
                        {item?.upload === "" ? (
                          <>
                            <i
                              className="pi pi-plus text-gray "
                              style={{ fontSize: "1rem" }}
                            ></i>
                            <p className="mb-0 text-black">Add a photo</p>
                          </>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                {inputList.length - 1 === index && (
                  <div className="col-12 md:col-4 lg:col-2 ">
                    <div
                      className="photo-upload bg-lightest-gray text-center border-round-lg"
                      style={{
                        height: "114px",
                        width: "100%",
                      }}
                    >
                      <label
                        className="h-full flex flex-column align-items-center justify-content-center"
                        onClick={handleAddImage}
                      >
                        <i
                          className="pi pi-plus text-gray "
                          style={{ fontSize: "1rem" }}
                        ></i>
                        <p className="mb-0">Add more photos</p>
                      </label>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
        <p className="text-gray m-0 ml-1 col-12 md:col-12 mb-4">
          Maximum 10 images & 10MB file size - PNG, JPEG
        </p>
        <h2 className="text-dark font-medium text-lg m-0 ml-1 py-0 col-12 mb-2 flex align-items-start font-bold">
          Add Floorplan
        </h2>
        <div
          className={`col-12 grid py-0 ${
            data?.formErrors?.password?.length > 0 ||
            data?.formErrors?.confirmPassword?.length > 0
              ? "pointer-events-none"
              : ""
          }`}
        >
          {floorPlan?.map((item, index) => {
            return (
              <div className="col-12 md:col-4 lg:col-2">
                <div className="image-box">
                  <div
                    className="photo-upload bg-lightest-gray text-center border-round-lg relative"
                    style={{
                      height: "114px",
                      width: "100%",
                    }}
                  >
                    <input
                      onChange={(e) => handleChangeAddFloorPlan(e, index)}
                      id="formImage"
                      type="file"
                      accept="image/*"
                      className="absolute w-full h-full left-0 top-0 opacity-0"
                      multiple
                    />
                    <label className="h-full flex flex-column align-items-center justify-content-center">
                      {floorPlan?.length !== 1 && (
                        <i
                          className="pi pi-times absolute left-10 top-0 right-0"
                          onClick={() => handleRemoveFloorPlane(index)}
                          style={{ color: "#708090" }}
                        ></i>
                      )}
                      <img
                        className={
                          item.upload === "" ? "w-2" : "w-full h-full fit-cover"
                        }
                        src={
                          typeof item?.upload === "string"
                            ? Constants.BASE_URL + `${item?.upload}`
                            : typeof item?.upload === "object" &&
                              item?.upload !== null
                            ? window.URL.createObjectURL(item?.upload)
                            : " Gallery"
                        }
                        alt=""
                      />
                      {item?.upload === "" ? (
                        <>
                          <i
                            className="pi pi-plus text-gray "
                            style={{ fontSize: "1rem" }}
                          ></i>
                          <p className="mb-0 text-black">Add a photo</p>
                        </>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <p className="text-gray m-0 ml-1 col-12 mb-3">
          Maximum 10 images & 10MB file size - PNG, JPEG
        </p>
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <PropertyType
          PropertyList={PropertyList}
          check={check}
          setChecked={setChecked}
          handleTypeChange={handleTypeChange}
          data={data}
        />
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <PropertyDetail
          bedRoomQuantity={bedRoomQuantity}
          bathroomQuantity={bathroomQuantity}
          carSpaceQuantity={carSpaceQuantity}
          data={data}
          handleRoomChange={handleRoomChange}
          highlight={highlight}
          bathRoomHightlight={bathRoomHightlight}
          carHightlight={carHightlight}
        />
        <div className="col-12 md:col-12 mb-2">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold m-0 mb-2">
          Land size m <sup>2</sup>
          <code className="p-error text-sm">*</code>
        </h2>
        <div className="col-12 md:col-6 lg:col-6">
          <CustomInput
            extraClassName="w-full"
            labelClassName="text-black"
            onChange={handleChange}
            name="minLand"
            optionLabel=""
            value={minLand}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
          {data?.formErrors?.landSize && (
            <small className="p-error text-sm ml-2">
              {data?.formErrors?.landSize}
            </small>
          )}
        </div>
        <div className="col-12 md:col-12 mb-2">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <div className="col-12">
          <OutdoorFeatures
            PropertyList={PropertyList}
            data={data}
            setData={setData}
            id={id}
          />
        </div>
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <div className="col-12">
          <IndoorFeatures
            PropertyList={PropertyList}
            data={data}
            setData={setData}
            id={id}
          />
        </div>
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <div className="col-12">
          <ClimateControl
            PropertyList={PropertyList}
            data={data}
            setData={setData}
            id={id}
          />
        </div>
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
      </div>
      <div className="buyer-data-button grid p-0 m-0 flex justify-content-between">
        <div className="col-12 md:col-3  lg:col-2 ">
          <div className="mb-3">
            <ButtonComponent
              label="CLEAR ALL"
              className=" bg-transparent text-black  border-0"
              onClick={handleClear}
            />
          </div>
        </div>
        <div className="col-12 md:col-3 lg:col-2">
          <div className=" " onClick={gotoBtn}>
            <Button
              label={
                location.pathname === "/seller-edit_deatils/" + id
                  ? "Save Changes"
                  : "Submit"
              }
              className="bg-main border-main w-full p-3 font-semibold font-base"
              onClick={() => handleSubmit(id)}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SellerEditdata;
