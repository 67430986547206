import { useEffect, useState } from "react";
import { CustomCheckbox, CustomInput } from "../../components/input/AllInput";
import {
  CustomPhoneInput,
  CustomRadioButtons,
} from "../../components/input/CustomInputs";
import { EditData } from "./EditData";
import PlacesAutocomplete from "react-places-autocomplete";
import { useLocation } from "react-router-dom";

export const ContactDetail = ({
  handleChange,
  handleChangeName,
  data,
  handleChangeSuburb,
  handleSelectAddressSuburb,
  show,
  address,
}) => {
  const { viewPostData, PropertyList } = EditData();
  // console.log(data);

  return (
    <>
      {/* Contact-Details Section */}
      <div className="col-12 md:col-12">
        <h2 className=" text-black-dark mt-0 font-medium text-2xl">
          Contact details
        </h2>
      </div>
      <CustomInput
        col={6}
        label="First Name"
        placeholder="First name"
        onChange={handleChangeName("firstName")}
        value={data?.firstName}
      />
      <CustomInput
        col={6}
        label="Last Name"
        placeholder="Last name"
        onChange={handleChangeName("lastName")}
        value={data?.lastName}
      />
      <CustomInput
        col={6}
        extraClassName={""}
        label="Email"
        placeholder="Email"
        onChange={handleChangeName("email")}
        value={data?.email}
      />
      <CustomPhoneInput
        col={6}
        label="Phone"
        placeholder="number"
        onChange={handleChangeName("mobile")}
        value={data?.mobile}
      />
      <div
        className={`col-6 p-0 m-0 ${
          data?.dataErrors?.password?.length > 0 ||
          data?.dataErrors?.confirmPassword?.length > 0
            ? "pointer-events-none"
            : ""
        } `}
      >
        <PlacesAutocomplete
          value={address || data?.suburb}
          onChange={handleChangeSuburb}
          onSelect={handleSelectAddressSuburb}
          searchOptions={{ componentRestrictions: { country: ["au"] } }}
          disabled={data?.dataErrors?.password?.length > 0 ? true : false}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <>
              <CustomInput
                extraClassName="w-full"
                label="Full Address"
                required
                value={address || data?.suburb}
                disabled={data?.dataErrors?.password?.length > 0 ? true : false}
                errorMessage={data?.dataErrors?.suburb}
                {...getInputProps({
                  placeholder: "Enter Full Address",
                  // className: "location-search-input w-full p-3",
                })}
              />
              {show === true ? (
                <div className="autocomplete-dropdown-container absolute top-500 bg-white z-2 p-0 shadow-1  mb-2 ">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                      : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span className="">{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </PlacesAutocomplete>
      </div>
      {/* <CustomInput
        col={6}
        label="Suburb"
        placeholder="Suburb"
        onChange={() => handleChange("suburb")}
        value={data?.suburb}
      />
      <CustomInput
        col={6}
        label="State"
        placeholder="State"
        onChange={() => handleChange("state")}
        value={data?.state}
      />
      <CustomInputNumber
        col={6}
        label="Pose code"
        placeholder="Pose code"
        onChange={() => handleChange("zipCode")}
        value={data?.zipCode}
      /> */}
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>
    </>
  );
};

export const OutdoorFeatures = ({ PropertyList, data, setData, id }) => {
  const [check, setChecked] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  const location = useLocation();
  const handleTypeChange = (e) => {
    let selectType = [...check];
    if (e.checked) {
      selectType.push(e.value);
    } else {
      selectType = selectType.filter((type) => type._id !== e.value._id);
    }
    setChecked(selectType);
    setData({
      ...data,
      outdoorFeatures: selectType,
    });
  };
  useEffect(() => {
    if (id || data?.outdoorFeatures) {
      setChecked(data?.outdoorFeatures);
    }
  }, [id, data?.outdoorFeatures]);
  return (
    <>
      {/* <Accordion activeIndex={0}>
        <AccordionTab
          className="accordin"
          header={
            
          }
        > */}
      <span className=" ml-1 vertical-align-middle text-2xl text-dark  block md:hidden mb-3 font-bold ">
        Outdoor features
      </span>
      <div className=" align-items-center  justify-content-between w-full mb-3 hidden md:flex">
        <span
          className=" vertical-align-middle text-2xl text-dark font-bold  flex align-items-start"
          style={{ marginLeft: "4px" }}
        >
          Outdoor features
        </span>
        <div>
          {location.pathname === "/edit_deatils/" ||
          location.pathname === "/renter-edit_deatils/" ||
          location.pathname === "/seller-edit_deatils/" ? (
            <span
              className="text-main mr-1 text-lg font-medium"
              onClick={handleClick}
            >
              {showMore === true
                ? "See fewer outdoor features"
                : "See More outdoor features"}{" "}
              &nbsp;
              <i
                className={`pi pi-chevron-down cursor-pointer ${
                  showMore === true ? "rotate-180" : ""
                }`}
              ></i>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      <div
        className={`grid overflow-hidden ${showMore ? "show-all" : "show-min"}`}
      >
        {PropertyList?.propertyFeature?.length > 0 &&
          PropertyList?.propertyFeature?.map((item) => {
            // console.log(
            //   "PropertyList?.propertyFeature",
            //   PropertyList?.propertyFeature
            // );
            return (
              <>
                {item?.forFeature === "OUTDOOR" ? (
                  <div className="col-12 md:col-6">
                    <CustomCheckbox
                      optionLabel={item?.title}
                      id={item?.title}
                      name={item?.title}
                      value={item}
                      onChange={handleTypeChange}
                      disabled={
                        data?.dataErrors?.password?.length > 0 ||
                        data?.dataErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={
                        check?.some((type) => type._id === item._id)
                          ? true
                          : false
                      }
                    />{" "}
                  </div>
                ) : null}
              </>
            );
          })}
      </div>
      <span
        className={
          "text-main mr-2 w-full md:w-auto flex md:hidden justify-content-between text-lg font-medium ml-1 "
        }
        onClick={handleClick}
      >
        {showMore === true
          ? "See fewer outdoor features"
          : "See More outdoor features"}{" "}
        &nbsp;
        <i
          className={`pi pi-chevron-down cursor-pointer mr-2 ${
            showMore === true ? "rotate-180" : ""
          }`}
        ></i>
      </span>
      {/* </AccordionTab>
      </Accordion> */}
    </>
  );
};
export const ClimateControl = ({ PropertyList, data, setData, id }) => {
  const [check, setChecked] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const location = useLocation();
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  const handleTypeChange = (e) => {
    let selectType = [...check];
    if (e.checked) {
      selectType.push(e.value);
    } else {
      selectType = selectType.filter((type) => type._id !== e.value._id);
    }
    setChecked(selectType);
    setData({
      ...data,
      climateControlEnergy: selectType,
    });
  };
  useEffect(() => {
    if (id || data?.climateControlEnergy) {
      setChecked(data?.climateControlEnergy);
    }
  }, [id, data?.climateControlEnergy]);
  return (
    <>
      <span className=" vertical-align-middle text-2xl text-dark font-bold block md:hidden mb-3 ml-1 ">
        Climate control & energy
      </span>
      <div className=" align-items-center  justify-content-between w-full mb-3 hidden md:flex">
        <span
          className=" vertical-align-middle text-2xl text-dark font-bold  flex align-items-start"
          style={{ marginLeft: "4px" }}
        >
          Climate control & energy
        </span>
        {data?.dataErrors?.climateControlEnergy && (
          <small className="p-error text-sm">
            {data?.dataErrors?.climateControlEnergy}
          </small>
        )}
        <div>
          {location.pathname === "/edit_deatils/" ||
          location.pathname === "/renter-edit_deatils/" ||
          location.pathname === "/seller-edit_deatils/" ? (
            <span
              className="text-main mr-1 text-lg font-medium"
              onClick={handleClick}
            >
              {showMore == true
                ? "See fewer climate control & energy"
                : "See More climate control & energy"}{" "}
              &nbsp;
              <i
                className={`pi pi-chevron-down cursor-pointer ${
                  showMore == true ? "rotate-180" : ""
                }`}
              ></i>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      <div
        className={`grid overflow-hidden ${showMore ? "show-all" : "show-min"}`}
      >
        {PropertyList?.propertyFeature?.length > 0 &&
          PropertyList?.propertyFeature?.map((item) => {
            return (
              <>
                {item?.forFeature === "CLIMATECONTROLENERGY" ? (
                  <div className="col-12 md:col-6">
                    <CustomCheckbox
                      optionLabel={item?.title}
                      id={item?.title}
                      name="features"
                      value={item}
                      onChange={handleTypeChange}
                      disabled={
                        data?.dataErrors?.password?.length > 0 ||
                        data?.dataErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={
                        check?.some((type) => type._id === item._id)
                          ? true
                          : false
                      }
                    />
                  </div>
                ) : null}
              </>
            );
          })}
      </div>
      <span
        className="text-main mr-2 w-full md:w-auto flex md:hidden justify-content-between text-lg font-medium ml-1"
        onClick={handleClick}
      >
        {showMore === true
          ? "See fewer climate control & energy"
          : "See More climate control & energy"}{" "}
        &nbsp;
        <i
          className={`pi pi-chevron-down cursor-pointer mr-2 ${
            showMore === true ? "rotate-180" : ""
          }`}
        ></i>
      </span>
      {/* </AccordionTab>
      </Accordion> */}
    </>
  );
};
export const IndoorFeatures = ({ PropertyList, data, setData, id }) => {
  const location = useLocation();
  const [check, setChecked] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  const handleTypeChange = (e) => {
    let selectType = [...check];
    if (e.checked) {
      selectType.push(e.value);
    } else {
      selectType = selectType.filter((type) => type._id !== e.value._id);
    }
    setChecked(selectType);
    setData({
      ...data,
      indoorFeatures: selectType,
    });
  };
  useEffect(() => {
    if (id || data?.indoorFeatures) {
      setChecked(data?.indoorFeatures);
    }
  }, [id, data?.indoorFeatures]);
  return (
    <>
      {/* <Accordion activeIndex={0}>
        <AccordionTab
          className="accordin"
          header={
            
          }
        > */}
      <span className=" vertical-align-middle text-2xl text-dark font-bold block md:hidden mb-3 ml-1">
        Indoor Features
      </span>
      <div className=" align-items-center  justify-content-between w-full mb-3 hidden md:flex">
        <span
          className=" vertical-align-middle text-2xl text-dark font-bold flex align-items-start "
          style={{ marginLeft: "4px" }}
        >
          Indoor features
        </span>
        <div>
          {location.pathname === "/edit_deatils/" ||
          location.pathname === "/renter-edit_deatils/" ||
          location.pathname === "/seller-edit_deatils/" ? (
            <span
              className="text-main mr-1 text-lg font-medium"
              onClick={handleClick}
            >
              {showMore === true
                ? "See fewer indoor features"
                : "See More indoor features"}{" "}
              &nbsp;
              <i
                className={`pi pi-chevron-down cursor-pointer ${
                  showMore === true ? "rotate-180" : ""
                }`}
              ></i>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      <div
        className={`grid overflow-hidden ${showMore ? "show-all" : "show-min"}`}
      >
        {PropertyList?.propertyFeature?.length > 0 &&
          PropertyList?.propertyFeature?.map((item) => {
            return (
              <>
                {item?.forFeature === "INDOOR" ? (
                  <div className="col-12 md:col-6">
                    <CustomCheckbox
                      optionLabel={item?.title}
                      id={item?.title}
                      name="features"
                      value={item}
                      disabled={
                        data?.dataErrors?.password?.length > 0 ||
                        data?.dataErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      onChange={handleTypeChange}
                      checked={
                        check?.some((type) => type._id === item._id)
                          ? true
                          : false
                      }
                    />
                  </div>
                ) : null}
              </>
            );
          })}
      </div>
      <span
        className="text-main mr-2 w-full md:w-auto flex md:hidden justify-content-between text-lg font-medium ml-1"
        onClick={handleClick}
      >
        {showMore === true
          ? "See fewer indoor features"
          : "See More indoor features"}{" "}
        &nbsp;
        <i
          className={`pi pi-chevron-down cursor-pointer mr-2 ${
            showMore === true ? "rotate-180" : ""
          }`}
        ></i>
      </span>
      {/* </AccordionTab>
      </Accordion> */}
    </>
  );
};
export const PropertyDetail = ({
  data,
  handleRoomChange,
  highlight,
  bathRoomHightlight,
  carHightlight,
}) => {
  return (
    <>
      <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold m-0">
        Bedrooms<code className="p-error text-sm">*</code>
      </h2>
      <div
        className={`col-12 md:col-12 lg:col-12 ${
          data?.dataErrors?.password?.length > 0 ||
          data?.dataErrors?.confirmPassword?.length > 0
            ? "pointer-events-none"
            : ""
        } `}
      >
        <div className=" flex align-items-center justify-content-between lg:w-5 md:w-10 ">
          <p
            label="0+"
            className={`font-medium text-lg cursor-pointer text-black  md:px-5 p-0 m-0 lg:ml-1 lg:px-5 p-3 font-bold ${
              highlight === "0+"
                ? "room-hightlight  bg-light-skin  "
                : " bg-light-skin  "
            }`}
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "0+")}
            style={{ borderRadius: "10px" }}
          >
            0
          </p>
          <p
            label="1+"
            className={
              highlight === "1+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
                : " font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "1+")}
            style={{ borderRadius: "10px" }}
          >
            1+
          </p>

          <p
            label="2+"
            className={
              highlight === "2+"
                ? "font-bold room-hightlight  lg:ml-5  md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold   lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "2+")}
            style={{ borderRadius: "10px" }}
          >
            2+
          </p>

          <p
            label="3+"
            className={
              highlight === "3+"
                ? " font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : " font-bold  lg:ml-5 md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "3+")}
            style={{ borderRadius: "10px" }}
          >
            3+
          </p>

          <p
            label="4+"
            className={
              highlight === "4+"
                ? " font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "4+")}
            style={{ borderRadius: "10px" }}
          >
            4+
          </p>

          <p
            label="5+"
            className={
              highlight === "5+"
                ? " font-bold room-hightlight  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "5+")}
            style={{ borderRadius: "10px" }}
          >
            5+
          </p>
        </div>
        {data?.dataErrors?.bedrooms && (
          <small className="p-error text-sm  ml-2">
            {data?.dataErrors?.bedrooms}
          </small>
        )}
      </div>
      <div className="col-12 md:col-12 lg:col-12 mb-3">
        <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
      </div>
      <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold m-0">
        Bathrooms<code className="p-error text-sm">*</code>
      </h2>
      <div
        className={`col-12 md:col-12 lg:col-12 ${
          data?.dataErrors?.password?.length > 0 ||
          data?.dataErrors?.confirmPassword?.length > 0
            ? "pointer-events-none"
            : ""
        } `}
      >
        <div className=" flex align-items-center justify-content-between lg:w-5 md:w-10 ">
          <p
            label="0+"
            className={
              bathRoomHightlight === "0+"
                ? "font-bold room-hightlight p-0 m-0 lg:ml-1 md:px-5 lg:px-5 p-3 bg-light-skin text-black font-medium text-lg cursor-pointer"
                : "font-bold lg:ml-1 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg md:px-5 p-0 m-0 cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "0+")}
            style={{ borderRadius: "10px" }}
          >
            0
          </p>

          <p
            label="1+"
            className={
              bathRoomHightlight === "1+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : " font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "1+")}
            style={{ borderRadius: "10px" }}
          >
            1+
          </p>

          <p
            label="2+"
            className={
              bathRoomHightlight === "2+"
                ? "font-bold room-hightlight  lg:ml-5  md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold   lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "2+")}
            style={{ borderRadius: "10px" }}
          >
            2+
          </p>

          <p
            label="3+"
            className={
              bathRoomHightlight === "3+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
                : " font-bold  lg:ml-5 md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "3+")}
            style={{ borderRadius: "10px" }}
          >
            3+
          </p>

          <p
            label="4+"
            className={
              bathRoomHightlight === "4+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "4+")}
            style={{ borderRadius: "10px" }}
          >
            4+
          </p>

          <p
            label="5+"
            className={
              bathRoomHightlight === "5+"
                ? "font-bold room-hightlight  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lgcursor-pointer"
                : " font-bold lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "5+")}
            style={{ borderRadius: "10px" }}
          >
            5+
          </p>
        </div>
        {data?.dataErrors?.bathrooms && (
          <small className="p-error text-sm ml-2">
            {data?.dataErrors?.bathrooms}
          </small>
        )}
      </div>
      <div className="col-12 md:col-12 mb-3">
        <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
      </div>
      <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold m-0">
        Car spaces<code className="p-error text-sm">*</code>
      </h2>
      <div
        className={`col-12 md:col-12 lg:col-12 ${
          data?.dataErrors?.password?.length > 0 ||
          data?.dataErrors?.confirmPassword?.length > 0
            ? "pointer-events-none"
            : ""
        } `}
      >
        <div className=" flex align-items-center justify-content-between lg:w-5 md:w-10 ">
          <p
            label="0+"
            className={
              carHightlight === "0+"
                ? "font-bold room-hightlight p-0 m-0 lg:ml-1 md:px-5 lg:px-5 p-3 bg-light-skin text-black font-medium text-lg cursor-pointer"
                : "font-bold lg:ml-1 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg md:px-5 p-0 m-0 cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "0+")}
            style={{ borderRadius: "10px" }}
          >
            0
          </p>

          <p
            label="1+"
            className={
              carHightlight === "1+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
                : " font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "1+")}
            style={{ borderRadius: "10px" }}
          >
            1+
          </p>

          <p
            label="2+"
            className={
              carHightlight === "2+"
                ? "font-bold room-hightlight  lg:ml-5  md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold   lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "2+")}
            style={{ borderRadius: "10px" }}
          >
            2+
          </p>

          <p
            label="3+"
            className={
              carHightlight === "3+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : " font-bold  lg:ml-5 md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "3+")}
            style={{ borderRadius: "10px" }}
          >
            3+
          </p>

          <p
            label="4+"
            className={
              carHightlight === "4+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : " font-bold lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "4+")}
            style={{ borderRadius: "10px" }}
          >
            4+
          </p>

          <p
            label="5+"
            className={
              carHightlight === "5+"
                ? "font-bold room-hightlight  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
                : "font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "5+")}
            style={{ borderRadius: "10px" }}
          >
            5+
          </p>
        </div>
        {data?.dataErrors?.carSpaces && (
          <small className="p-error text-sm ml-2">
            {data?.dataErrors?.carSpaces}
          </small>
        )}
      </div>
    </>
  );
};
export const PropertyType = ({
  PropertyList,
  check,
  data,
  handleTypeChange,
}) => {
  return (
    <>
      <div className="col-12">
        <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
          Property type<code className="p-error text-sm ">*</code>
          <br />
        </h2>
        {data?.formErrors?.propertyType && (
          <small className="p-error text-sm ml-1">
            {data?.formErrors?.propertyType}
          </small>
        )}
      </div>
      <>
        {PropertyList?.propertyTypes?.length > 0 &&
          PropertyList?.propertyTypes?.map((item) => {
            return (
              <>
                {item?.forProfile?.includes("SELLER") ? (
                  <div className="col-12 md:col-6">
                    <CustomCheckbox
                      optionLabel={item?.propertyType}
                      id={item?.propertyType}
                      name="propertyType"
                      value={item}
                      onChange={handleTypeChange}
                      disabled={
                        data?.formErrors?.password?.length > 0 ||
                        data?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={check?.some((type) =>
                        item._id === type._id ? true : false
                      )}
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
      </>
    </>
  );
};
export const NewEstablishedProperty = ({ data, setData, handleChange }) => {
  const [ingredient, setIngredient] = useState("");
  return (
    <>
      <div className="col-12">
        <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
          New or Established property<code className="p-error text-sm">*</code>
        </h2>
        {data?.dataErrors?.establishedProperty && (
          <small className="p-error text-sm ml-2">
            {data?.dataErrors?.establishedProperty}
          </small>
        )}
      </div>

      <div className="col-12 md:col-3 lg:col-2 lg:w-2">
        <CustomRadioButtons
          optionLabel="New"
          extraClassName="w-auto"
          id="New"
          name="establishedProperty"
          value="new"
          onChange={handleChange}
          checked={data?.establishedProperty === "new"}
          disabled={
            data?.dataErrors?.password?.length > 0 ||
            data?.dataErrors?.confirmPassword?.length > 0
              ? true
              : false
          }
        />
      </div>
      <div className="col-12 md:col-3 lg:col-2 lg:w-2">
        <CustomRadioButtons
          optionLabel="Established"
          extraClassName="w-auto"
          id="Established"
          name="establishedProperty"
          value="established"
          onChange={handleChange}
          checked={data?.establishedProperty === "established"}
          disabled={
            data?.dataErrors?.password?.length > 0 ||
            data?.dataErrors?.confirmPassword?.length > 0
              ? true
              : false
          }
        />
      </div>
    </>
  );
};
