import api from "../../services/api";
import Constants from "../../utils/constants";
import { types } from "../type";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

export const GetArchivedPostList = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.GETARCHIVEDPOST);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_ARCHIVED_POST,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const PostArchived = (id, navigate) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api(
    "post",
    `${Constants.END_POINT.ARCHIVEDPOST}/${id}`,
    {}
  );
  if (res.success) {
    navigate("/archived");
    if (res.data) {
      dispatch({
        type: types.ARCHIVED_POST,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
