import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { InputText } from "primereact/inputtext";
import { Cardcomponent, PendingPostCard } from "../../components/cards/card";
import { useDispatch, useSelector } from "react-redux";
import { GetArchivedPostList } from "../../redux/action/archivedAction";
import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";

export const ArchivedPost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [newTableData, setNewTableData] = useState([]);
  const [count, setCount] = useState(0);
  const onPageChange = (event) => {
    let startPage = event.page + 1;
    let itemsPerPage = event.rows;
    let value = [];
    for (var i = 0; i < getArchivedPost.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(getArchivedPost[i]);
      }
    }
    setNewTableData(value);
    setCount(event.first);
    setRows(event.rows);
  };

  const getArchivedPost = useSelector(
    (state) => state.getarchivedPost.getArchivedPost
  );
  useEffect(() => {
    dispatch(GetArchivedPostList());
  }, []);

  return (
    <DashboardLayout>
      <div className="flex align-items-center justify-content-between">
        <h1 className="text-black-dark font-semibold">Archived Brief</h1>
        <div className="relative">
          <span className="p-input-icon-left align-items-center ">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search"
              className="pl-5 padding-serachbar bg-light-main border-0 "
            />
          </span>
        </div>
      </div>
      <Cardcomponent
        extraClass="bg-lightest "
        cardClass="border-noround shadow-2"
      >
        {getArchivedPost?.map((item, index) =>
          item.address?.map((items) => {
            return (
              <PendingPostCard
                archived={true}
                item={item}
                index={index}
                items={items}
              />
            );
          })
        )}
      </Cardcomponent>
      <Paginator
        className="justify-content-end"
        first={first}
        rows={rows}
        totalRecords={getArchivedPost?.length}
        onPageChange={onPageChange}
      />
    </DashboardLayout>
  );
};
