import React from "react";

import { Button } from "primereact/button";
export default function ButtonComponent({
  onClick,
  label,
  loading,
  onChange,
  style,
  icon,
  iconPos,
  className,
  ...props
}) {
  return (
    <Button icon={icon}  label={label} style={style} className={`bg-main border-main  w-full  p-3 font-semibold  ${className}`} onClick={onClick} loading={loading} {...props} />
  )
}
export function ButtonOutlined({ onClick, label,  className, loading, ...props }) {
  return (
    <Button
      label={label}
      className={`border-button-component bg-transparent  border-round-lg font-semibold border-noround p-3 text-center text-main w-full ${className}`}
      onClick={onClick}
      loading={loading}
      {...props}
    />
  );
}

