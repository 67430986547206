import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { PostDetailTopBar } from "../../shared/PostDetailTopBar";
import { Cardcomponent, PendingPostCard } from "../../components/cards/card";
import { Avatar } from "primereact/avatar";
import ProfileImg from "../../assets/images/user-img.png";
import CopyIcon from "../../assets/icons/Path 2.png";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import { Dialog } from "primereact/dialog";
import { useNavigate, useParams } from "react-router-dom";
import { UserIndex } from "./UserIndex";
import Constants from "../../utils/constants";
import ProfileData from "../../assets/images/Profile.jpg";
import {
  CustomRadioButtons,
  CustomTextArea,
} from "../../components/input/CustomInputs";
import {
  hideLoaderAction,
  showLoaderAction,
} from "../../redux/action/loaderAction";
import api from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
export const UserProfile = () => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [newTableData, setNewTableData] = useState([]);
  const [count, setCount] = useState(0);
  const items = [{ label: "Profile details" }];
  const home = { label: "New users", url: "/user-list" };
  const [comment, setComment] = useState(false);
  const [Feedback, setFeedback] = useState("");
  const { handleSubmit, ParticularUserData } = UserIndex();
  const [ingredient, setIngredient] = useState("");
  const email = "iliasmiah000@gmail.com";
  const number = "+61 04483913838";
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(false);
  const navigate = useNavigate();
  const navigateToPrevious = () => {
    navigate("/user-list");
  };
  const { id } = useParams();
  useEffect(() => {
    handleSubmit(id);
  }, []);
  const onPageChange = (event) => {
    let startPage = event.page + 1;
    let itemsPerPage = event.rows;
    let value = [];
    for (var i = 0; i < ParticularUserData.posts.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(ParticularUserData.posts[i]);
      }
    }
    setNewTableData(value);
    setCount(event.first);
    setRows(event.rows);
  };

  const DeleteUser = async () => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.DeleteUser + id, {
      reason: ingredient || Feedback,
    });
    if (res.success) {
      setVisible(false);
      navigateToPrevious();
    }
    dispatch(hideLoaderAction());
  };

  return (
    <DashboardLayout>
      <PostDetailTopBar heading="Profiles" model={items} home={home} />
      <Cardcomponent cardClass="mb-3 shadow-2">
        <Avatar
          image={
            ParticularUserData?.image !== null
              ? Constants.BASE_URL + `${ParticularUserData?.image}`
              : ProfileData
          }
          shape="circle"
          className="user-profile-img"
        />
        <p className="text-gray font-medium">
          {ParticularUserData?.firstName + " " + ParticularUserData?.lastName}
        </p>
        <div className="flex align-items-center mb-3">
          <p className="text-gray m-0 font-medium">
            {ParticularUserData?.email}{" "}
          </p>
          &nbsp;&nbsp;
          <img
            src={CopyIcon}
            alt=""
            width={11}
            height={13}
            className="cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(email);
            }}
          />
        </div>

        <div className="flex align-items-center mb-3">
          <p className="text-gray m-0 font-medium">
            {ParticularUserData?.countryCode + " " + ParticularUserData?.mobile}{" "}
          </p>
          &nbsp;&nbsp;
          <img
            src={CopyIcon}
            alt=""
            width={11}
            height={13}
            className="cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(number);
            }}
          />
        </div>
        <p className="text-gray mt-0 font-medium">{ParticularUserData?.role}</p>
        <p className="text-gray mt-0 font-medium">
          {ParticularUserData?.suburb}
        </p>
        <p className="text-gray mt-0 font-medium">
          <div className="flex align-items-center">
            <h4 className="m-0">Subscription Status: &nbsp;</h4>
            {!ParticularUserData?.isDeleted ? (
              <span className="text-green-600 font-semibold">Active</span>
            ) : (
              <>
                <span className="text-red-600 font-semibold">InActive </span>
              </>
            )}
          </div>
        </p>
        {ParticularUserData.isDeleted ? (
          <div className="flex font-semibold mb-6">
            {/* <div className="flex flex-column text-right">
              <h3 className="text-red-600">Subscription Cancelled</h3> */}
            <div className="flex align-items-center">
              <h4 className="my-0 mr-2">Reason:</h4>
              <span>{ParticularUserData.reason}</span>
            </div>
            {/* </div> */}
          </div>
        ) : (
          <div className="flex justify-content-end w-full">
            <ButtonComponent
              label="Cancel subscription"
              className="bg-red-dark text-sm w-auto button-padding border-none border-round-lg button-padding font-medium cancel-button text-white-new"
              icon="pi pi-times"
              showIcon
              onClick={() => setVisible(true)}
            />
          </div>
        )}

        <div>
          <div className="flex align-items-center justify-content-between">
            <h1 className="text-black-dark font-semibold">All Users Brief</h1>
            {/* <div className="relative">
              <span className="p-input-icon-left align-items-center ">
                <i className="pi pi-search" />
                <InputText
                  placeholder="Search"
                  className="pl-5 padding-serachbar bg-light-main border-0 "
                />
              </span>
            </div> */}
          </div>
          <Cardcomponent
            extraClass="bg-lightest "
            cardClass="border-noround shadow-2"
          >
            {ParticularUserData?.posts?.length === 0
              ? "no data found"
              : ParticularUserData?.posts?.map((item, index) =>
                  item.address?.map((items) => {
                    return (
                      <PendingPostCard
                        archived={true}
                        item={item}
                        index={index}
                        items={items}
                      />
                    );
                  })
                )}
          </Cardcomponent>
          <Paginator
            className="justify-content-end"
            first={first}
            rows={rows}
            totalRecords={ParticularUserData.posts?.length}
            onPageChange={onPageChange}
          />
        </div>
      </Cardcomponent>
      <div className="flex justify-content-end">
        <ButtonOutlined
          className="mt-3 uppercase w-auto button-padding text-sm font-semibold"
          icon="pi pi-chevron-left"
          label="back to user list"
          onClick={navigateToPrevious}
        />
      </div>
      <div className="subscription-modal">
        <Dialog
          header="Cancel Subscription"
          visible={visible}
          style={{ width: "100vw" }}
          onHide={() => setVisible(false)}
          className="subscription-modal lg:w-5 md:w-10 w-11"
        >
          <div className="m-0">
            <h1
              className="cancel-subsription text-4xl font-semibold mb-5"
              style={{ color: "#231F20" }}
            >
              Reasons for leaving
            </h1>
            {console.log("ingredient", ingredient)}
            <CustomRadioButtons
              optionLabel="I successfully purchased a property"
              value="I successfully purchased a property"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "I successfully purchased a property"}
            />
            <CustomRadioButtons
              optionLabel="I am no longer in the market to Buy a Property"
              value="I am no longer in the market to Buy a Property"
              onChange={(e) => setIngredient(e.value)}
              checked={
                ingredient === "I am no longer in the market to Buy a Property"
              }
            />
            <CustomRadioButtons
              optionLabel="I didn't find what I want"
              value="I didn't find what I want"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "I didn't find what I want"}
            />
            <CustomRadioButtons
              optionLabel="I wasn't able to secure a deal"
              value="I wasn't able to secure a deal"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "I wasn't able to secure a deal"}
            />
            <CustomRadioButtons
              optionLabel="The Platform is too complicated"
              value="The Platform is too complicated"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "The Platform is too complicated"}
            />
            <CustomRadioButtons
              optionLabel="I found a property using another Buyers Platform"
              value="I found a property using another Buyers Platform"
              onChange={(e) => setIngredient(e.value)}
              checked={
                ingredient ===
                "I found a property using another Buyers Platform"
              }
            />
            {ingredient === "" ? (
              <div className="other mb-2 py-2" onClick={() => setComment(true)}>
                <i
                  className="pi pi-plus border-1 text-center p-2 border-300 border-round-2xl"
                  style={{ fontSize: "1rem" }}
                />
                <span className="text-base font-semibold text-black ml-2">
                  Other
                </span>
              </div>
            ) : null}
          </div>
          <div className="p-1">
            <hr className=""></hr>
          </div>

          <div className="btn flex justify-content-between p-3">
            <div className="button">
              <ButtonOutlined
                label="BACK "
                className="border-none text-black font-semibold text-sm"
                onClick={() => {
                  setVisible(false);
                  setIngredient("");
                }}
              ></ButtonOutlined>
            </div>
            <div className="button">
              <ButtonOutlined
                label="CANCEL SUBSCRIPTION"
                className="text-sm font-semibold"
                onClick={() => DeleteUser(id)}
              ></ButtonOutlined>
            </div>
          </div>
        </Dialog>
        <Dialog
          header=""
          visible={comment}
          style={{ width: "100vw" }}
          className=" other-feedbaack lg:w-4 md:w-8 w-11"
          onHide={() => setComment(false)}
        >
          <CustomTextArea
            label="Leave Feedback"
            onChange={(e) => setFeedback(e.value)}
            col={12}
          />
          <ButtonComponent label="Submit" onClick={() => setComment(false)} />
        </Dialog>
      </div>
    </DashboardLayout>
  );
};
