import React from "react";
import { length, equal, customPasswordCheck, notEqual } from "./javascript";
import { firstLetterToUppercase, emailValidation, number } from "./regex";
let password = ''
export const allValidations = (name, value, state) => {
    const formErrors = { ...state.formErrors };
    if (name === 'password') {
        password = value
    }
    switch (name) {
        case "email":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!emailValidation(value)) {
                formErrors[name] = `Please enter valid email!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "password":
            if (!equal(length(value))) {
                const valid = customPasswordCheck(value)
                if (valid) {
                    formErrors[name] = valid
                }
                else {
                    formErrors[name] = ''
                }
            } else formErrors[name] = 'Password is required!'
            break;
        case 'confirmPassword':
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`
            } else if (password === "") {
                formErrors[name] = ''
            }
            else if (notEqual(password, value)) {
                formErrors[name] = `Password and Confirm Password is not match!`
            }
            else if (equal(password, value)) {
                formErrors[name] = ''
            }
            break;
        default:
            break;
    }
    return formErrors;
}