import React from "react";
import DashboardLayout from "../../layout";
import { Cardcomponent } from "../../components/cards/card";
import { PostDetailTopBar } from "../../shared/PostDetailTopBar";
import {
  ClimateControl,
  ContactDetail,
  IndoorDetail,
  OutdoorDetail,
  PropertySpaceDetail,
  Propertytype,
} from "./CommanFeild";
import ButtonComponent from "../../components/buttons/button";
import { CommonIndex } from "./CommonIndex";
import Constants from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { AcceptRejectPostListAction } from "../../redux/action/postListAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import { PostArchived } from "../../redux/action/archivedAction";
export const SellerPostDetail = () => {
  const { viewPostData, property, outdoor, indoor, climate } = CommonIndex();
  const items = [{ label: "Post details" }];
  const home = { label: "New post", url: "/" };
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleApprove = () => {
    dispatch(AcceptRejectPostListAction({ isVerified: "VERIFIED" }, id)).then(
      () => {
        navigate("/new-post-list");
      }
    );
  };
  const saveArchived = (id) => {
    dispatch(PostArchived(id, navigate));
  };
  const Edit = (id) => {
    navigate("/seller-edit_deatils/" + id);
  };
  const handleCancel = () => {
    dispatch(AcceptRejectPostListAction({ isVerified: "REJECTED" }, id)).then(
      () => {
        navigate("/new-post-list");
      }
    );
  };
  return (
    <DashboardLayout>
      <PostDetailTopBar
        heading="Seller post details"
        description="Please check the details carefully. If post okay then approve it other you can cancel the post."
        id={viewPostData?.id}
        model={items}
        home={home}
        postType={viewPostData?.postType}
      />
      <Cardcomponent>
        <ContactDetail
          fullName={viewPostData?.firstName + " " + viewPostData?.lastName}
          email={viewPostData?.email}
          number={viewPostData?.mobile}
          fulladdress={viewPostData?.streetAddress}
          rentAmount={viewPostData?.suburb}
        />
        <div className="grid">
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium text-2xl">
              Property details
            </h2>
          </div>
          <div className="col-12 md:col-12 mb-3">
            <span className="font-medium text-gray ">
              {viewPostData?.propertiesTitle}
            </span>
          </div>
          <div className="col-12 md:col-12 mb-3">
            <span className="font-medium text-gray ">
              {moment(viewPostData?.buildYear).format("YYYY")}
            </span>
          </div>
          <div className="col-12 md:col-12 mb-3">
            <span className="font-medium text-gray ">
              {viewPostData?.address?.length > 0 &&
                viewPostData?.address[0]?.streetAddress}
            </span>
          </div>
          <div className="col-12 md:col-12 mb-2">
            <span className="font-medium text-gray ">
              Total price- $ {viewPostData?.price}
            </span>
          </div>

          <div className="col-12 md:col-12 mb-3">
            <p className="text-black font-medium p-0 m-0 line-height-3">
              {viewPostData?.Description}
            </p>
          </div>

          <div className="col-12 md:col-12">
            <h2 className=" text-dark font-medium text-lg p-0 m-0">Photos </h2>
          </div>
          <div className="overflow-auto flex mb-3">
            {viewPostData?.image?.length > 0 &&
              viewPostData?.image?.map((item) => {
                return (
                  <div className="col-12 md:col-3">
                    <img
                      src={Constants.BASE_URL + `${item}`}
                      className="w-full h-full "
                      alt=""
                    />
                  </div>
                );
              })}
          </div>

          <div className="col-12 md:col-12">
            <h2 className=" text-dark font-medium text-lg p-0 m-0">
              Floorplan{" "}
            </h2>
          </div>

          {viewPostData?.addFloorplan?.length > 0 &&
            viewPostData?.addFloorplan?.map((item) => {
              return item?.length > 0 ? (
                <div className="col-7 md:col-3 mb-3">
                  <img
                    src={Constants.BASE_URL + `${item}`}
                    className="w-full h-full"
                    alt=""
                  />
                </div>
              ) : (
                <span className="font-medium text-gray ">N/A</span>
              );
            })}

          <div className="col-12 md:col-12">
            <hr className="buyer-hr p-0 m-0" />
          </div>

          <Propertytype viewPostData={viewPostData} />
          <PropertySpaceDetail viewPostData={viewPostData} />
          {/* land size */}
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium text-2xl">Land size</h2>
          </div>
          <div className="col-12 md:col-12 mb-3">
            <span className="font-medium text-gray ">{`${viewPostData?.landSize} ㎡`}</span>
          </div>
          <div className="col-12 md:col-12">
            <hr className="buyer-hr p-0 m-0" />
          </div>
          {/* New or established property Section */}
          <div className="col-12 md:col-12">
            <h2 className=" text-black-dark font-medium text-2xl">
              New or established property
            </h2>
          </div>
          <div className="col-12 md:col-12 mb-2">
            <span className="font-medium text-gray ">
              {viewPostData?.establishedProperty}
            </span>
          </div>
          <div className="col-12 md:col-12">
            <hr className="buyer-hr p-0 m-0" />
          </div>

          <OutdoorDetail viewPostData={viewPostData} />
          <IndoorDetail viewPostData={viewPostData} />
          <ClimateControl viewPostData={viewPostData} />
        </div>

        <div className="buyer-form-button grid p-0 m-0 flex justify-content-between">
          <div className="col-12 md:col-4 md:text-left lg:col-3 mb-3 ">
            {viewPostData?.isVerified === "VERIFIED" ? (
              ""
            ) : (
              <div className="no-underline">
                <ButtonComponent
                  className="md:text-left text-red bg-transparent border-0 lg:font-medium lg:text-sm"
                  onClick={() => handleCancel()}
                >
                  Cancel Request
                </ButtonComponent>
              </div>
            )}
          </div>
          <div className="col-12 md:col-4 lg:col-3 lg:w-3 text-right">
            {viewPostData?.isVerified === "VERIFIED" ? (
              ""
            ) : (
              <div className="flex gap-3">
                <div>
                  <ButtonComponent
                    onClick={() => Edit(id)}
                    className="edit-button-style border-round-lg border-1 px-5 bg-transparent justify-content-center uppercase w-auto button-padding font-semibold "
                  >
                    Edit
                  </ButtonComponent>
                </div>
                <div>
                  <ButtonComponent
                    onClick={() => handleApprove()}
                    className="border-round-lg justify-content-center uppercase w-auto border-0 button-padding text-lightest nrmal-btn font-semibold"
                    style={{ color: "rgba(143, 154, 153, 1)" }}
                  >
                    approve
                  </ButtonComponent>
                </div>{" "}
              </div>
            )}
          </div>
          {viewPostData?.isVerified === "VERIFIED" &&
          !viewPostData?.isArchived ? (
            <>
              <div className="flex gap-2">
                {/* <div className=" text-right  ">
                  <ButtonComponent
                    onClick={() => Edit(id)}
                    className="edit-button-style border-round-lg border-1 bg-transparent px-5 justify-content-center uppercase w-auto button-padding font-semibold lg:ml-6"
                  >
                    Edit
                  </ButtonComponent>
                </div> */}
                <div className="  text-right  ">
                  <ButtonComponent
                    onClick={() => saveArchived(id)}
                    className="border-round-lg border-1 bg-transparent justify-content-center uppercase w-auto border-red-600 button-padding text-red-600  font-semibold lg:ml-6"
                  >
                    Archive Brief
                  </ButtonComponent>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Cardcomponent>
    </DashboardLayout>
  );
};
