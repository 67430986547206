import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layout";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import { TableComponent } from "../../components/table/table";
import UserData from "./UserDetail";
import { Paginator } from "primereact/paginator";
import { UserIndex } from "./UserIndex";

export const UserList = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { RecentJoinHeading } = UserData();
  const {
    userListData,
    buttons,
    postsHandleChange,
    newTableData,
    setNewTableData,
    names,
  } = UserIndex();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [count, setCount] = useState(0);
  const onPageChange = (event) => {
    let startPage = event.page + 1;
    let itemsPerPage = event.rows;
    let value = [];
    for (var i = 0; i < userListData.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(userListData[i]);
      }
    }
    setNewTableData(value);
    setCount(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    let startPage = 1;
    let itemsPerPage = rows;
    let value = [];
    for (var i = 0; i < userListData?.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(userListData[i]);
      }
    }
    setNewTableData(value);
    // setCount(0)
  }, [userListData]);
  useEffect(() => {
    if (names?.keyword !== "") {
      let filterIndex = buttons?.findIndex((item) => item == names?.keyword);
      if (filterIndex === -1) {
        setActiveIndex(0);
      } else {
        setActiveIndex(filterIndex);
      }
    }
  }, [names]);
  return (
    <DashboardLayout>
      <div>
        <h1 className="font-semibold text-black-dark">Users</h1>
        <div className="shadow-2 p-3 mb-3">
          <div className="flex align-items-center justify-content-between mb-3">
            <h3 className="text-black-dark text-2xl m-0 font-medium">
              Recently joined
            </h3>
            <div className="post-tab flex align-items-center">
              <div className="flex tab-button align-items-center gap-2 border-1 surface-border border-round-xl">
                {buttons.map((item, index) => {
                  return (
                    <Button
                      name={item}
                      onClick={() => {
                        postsHandleChange(item);
                        setActiveIndex(index);
                      }}
                      className={`bg-none border-none p-button-text font-medium  button-padding text-sm border-round-xl ${
                        activeIndex === index ? "active" : "text-light-gray "
                      }`}
                      label={item}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <TabView
            activeIndex={activeIndex}
            className="dashboard-news"
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel>
              {userListData?.length == 0 ? (
                <div className="d-4 p-3 bg-light-main text-center">
                  Data not avialable
                </div>
              ) : (
                <TableComponent
                  tableHeading={RecentJoinHeading}
                  tableData={userListData}
                  newTableData={newTableData}
                />
              )}
            </TabPanel>

            <TabPanel>
              {userListData?.length == 0 ? (
                <div className="d-4 p-3 bg-light-main text-center">
                  Data not avialable
                </div>
              ) : (
                <TableComponent
                  tableHeading={RecentJoinHeading}
                  tableData={userListData}
                  newTableData={newTableData}
                />
              )}
            </TabPanel>
            <TabPanel>
              {userListData?.length == 0 ? (
                <div className="d-4 p-3 bg-light-main text-center">
                  Data not avialable
                </div>
              ) : (
                <TableComponent
                  tableHeading={RecentJoinHeading}
                  tableData={userListData}
                  newTableData={newTableData}
                />
              )}
            </TabPanel>
            <TabPanel>
              {userListData?.length == 0 ? (
                <div className="d-4 p-3 bg-light-main text-center">
                  Data not avialable
                </div>
              ) : (
                <TableComponent
                  tableHeading={RecentJoinHeading}
                  tableData={userListData}
                  newTableData={newTableData}
                />
              )}
            </TabPanel>
          </TabView>
        </div>
        <Paginator
          className="justify-content-end"
          first={first}
          rows={rows}
          totalRecords={userListData?.length}
          onPageChange={onPageChange}
        />
      </div>
    </DashboardLayout>
  );
};
