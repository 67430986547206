import { combineReducers } from "redux";
import {
  forgetPasswordReducer,
  resetPasswordReducer,
  signinReducer,
  signupReducer,
} from "./signupReducer";
import { loaderReducer } from "./loaderReducer";
import { toastReducer } from "./toastReducer";
import {
  countReducer,
  growthReducer,
  postListReducer,
  viewPostReducer,
} from "./dashboardReducer";
import {
  buyerGeneralInfoReducer,
  createBuyerReducer,
  myProfileReducer,
  propertyTypeReducer,
  sellerUploadImageReducer,
} from "./propertyTypeReducer";
import { acceptRejectReducer } from "./postListReducer.jsx";
import { userListReducer, userProfileReducer } from "./userReducer";
import {
  ContactMessageReducer,
  inqueryMessageReducer,
  particularContactReducer,
  particularInqueryReducer,
} from "./messageReducer";
import {
  notificationCountReducer,
  notificationReadReducer,
  notificationReducer,
} from "./notificationReducer";
import {
  archivedPostReducer,
  postArchivedReducer,
} from "./archivedReducer.jsx";
import { SubscribersReducer } from "./SubscriptionReducer.jsx";

export default combineReducers({
  signup: signupReducer,
  loader: loaderReducer,
  toast: toastReducer,
  signin: signinReducer,
  forgetPassword: forgetPasswordReducer,
  resetPassword: resetPasswordReducer,
  count: countReducer,
  postList: postListReducer,
  viewPost: viewPostReducer,
  propertyType: propertyTypeReducer,
  acceptReject: acceptRejectReducer,
  userList: userListReducer,
  user: userProfileReducer,
  inquery: inqueryMessageReducer,
  particularInquery: particularInqueryReducer,
  growthChart: growthReducer,
  notification: notificationReducer,
  notificationCount: notificationCountReducer,
  notificationRead: notificationReadReducer,
  getarchivedPost: archivedPostReducer,
  postArchived: postArchivedReducer,
  buyerGeneralInfo: buyerGeneralInfoReducer,
  myProfile: myProfileReducer,
  createBuyer: createBuyerReducer,
  sellerUploadImage: sellerUploadImageReducer,
  SubscribersList: SubscribersReducer,
  CONTACTLIST: ContactMessageReducer,
  particularContact: particularContactReducer,
});
