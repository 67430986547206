import React, { useRef, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";
import AvtarImg from "../assets/images/Ellipse 1594.png";
import {
  notificationAction,
  notificationCountAction,
  notificationReadAction,
} from "../redux/action/notificationAction";
import { useDispatch, useSelector } from "react-redux";
import { OverlayPanel } from "primereact/overlaypanel";
import bellIcon from "../assets/icons/notification.png";
import { useNavigate } from "react-router-dom";
export const Topbar = () => {
  const op = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [notification, setNotification] = useState();
  useEffect(() => {
    dispatch(notificationAction());
    dispatch(notificationCountAction()).then(() => {
      dispatch(notificationAction());
    });
  }, []);
  const notificationData = useSelector(
    (state) => state.notification.notificationProfile
  );
  const notificationCount = useSelector(
    (state) => state.notificationCount.notificationCountProfile
  );
  console.log(notificationCount, "notificationCount");
  // useEffect(() => {
  //   let array = [];
  //   let obj;
  //   let arrayData = [];
  //   notificationData?.length > 0 &&
  //     notificationData?.map((item) => {
  //       obj = {
  //         message: item?.notification,
  //         image: messageIcon,
  //         duration: moment.duration(
  //           moment(item?.time).diff(moment(new Date()))
  //         ),
  //       };
  //       array.push(obj);
  //     });
  //   let object;
  //   array.map((item) => {
  //     object = {
  //       message: item?.message,
  //       image: messageIcon,
  //       receivedTime:
  //         item?.duration?.years() === 0
  //           ? item?.duration?.months() === 0
  //             ? item?.duration?.days() === 0
  //               ? item?.duration?.hours() === 0
  //                 ? item?.duration.minutes() === 0
  //                   ? `${item?.duration.seconds().toString().substring(1)} sec`
  //                   : `${item?.duration.minutes().toString().substring(1)} min`
  //                 : `${item?.duration.hours().toString().substring(1)} hour`
  //               : `${item?.duration.days().toString().substring(1)} days`
  //             : `${item?.duration?.months().toString().substring(1)} Month`
  //           : `${item?.duration.years().toString().substring(1)} Year`,
  //     };
  //     arrayData.push(object);
  //   });
  //   setNotification(arrayData?.reverse());
  // }, [notificationData]);
  const handleReadNotification = (id) => {
    dispatch(notificationReadAction(id)).then(() => {
      dispatch(notificationAction());
      dispatch(notificationCountAction());
    });
  };
  return (
    <div className="p-4 px-5">
      <div className="flex align-items-center justify-content-between">
        <span className="p-input-icon-left align-items-center ">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            className="pl-5 padding-serachbar bg-light-main border-0 "
          />
        </span>
        <div className="flex align-items-center">
          <div className="bg-light-main p-2 border-round-lg flex align-items-center relative">
            <img
              src={bellIcon}
              alt=""
              width={20}
              onClick={(e) => op.current.toggle(e)}
            />
            <p
              style={{
                bottom: "5px",
                width: "20px",
                borderRadius: "50px",
                position: "absolute",
                left: "20px",
                right: "0px",
              }}
              className="font-medium bg-main text-white text-center"
            >
              {notificationCount?.notificationCount === 0
                ? ""
                : notificationCount?.notificationCount}
            </p>
          </div>
          <div className="flex align-items-center ml-5">
            <p className="text-lg font-semibold">Admin</p>
            {/* <Avatar
              image={AvtarImg}
              size="large"
              className="ml-2"
              shape="circle"
            /> */}
          </div>
        </div>
      </div>
      <OverlayPanel className="shadow-4 overlay-admin" ref={op}>
        <div>
          <span>
            <p
              className="px-2"
              style={{ textAlign: "end" }}
              onClick={() => navigate("/message")}
            >
              View All
            </p>
          </span>
          {notificationData?.length > 0 &&
            notificationData?.map((item) => {
              return (
                <>
                  <div
                    className={
                      item?.isRead === false
                        ? "w-full flex align-items-center surface-300	"
                        : "w-full flex align-items-center"
                    }
                  >
                    <i
                      className="pi pi-comment ml-2"
                      style={{ fontSize: "1rem" }}
                    ></i>
                    <span className="ml-2">
                      <p
                        className="text-medium font-lg"
                        onClick={(e) => handleReadNotification(item?._id)}
                      >
                        {item?.notification}
                      </p>
                    </span>
                  </div>
                  <hr className="p-0 m-0" />
                </>
              );
            })}
        </div>
      </OverlayPanel>
    </div>
  );
};
