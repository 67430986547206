import Constants from "../../utils/constants";
import api from "../../services/api";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { types } from "../type";

export const inqueryMessageAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.INQUERYLIST);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.INQUERY_LIST,
        payload: res,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const ContactMessageAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.CONTACTLIST);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CONTACTLIST,
        payload: res,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const ParticularInqueryMessageAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api(
    "get",
    `${Constants.END_POINT.PARTICULARINQUERYLIST}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.PARTICULAR_INQUERY_LIST,
        payload: res,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const ParticularContactMessageAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api(
    "get",
    `${Constants.END_POINT.PARTICULARCONTACTLIST}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.PARTICULAR_CONTACT_LIST,
        payload: res,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
