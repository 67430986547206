import React, { useState } from "react";
import DashboardLayout from "../../layout";
import { Cardcomponent } from "../../components/cards/card";
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";
import { MessgaeData } from "./MessageData";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { TableComponent } from "../../components/table/table";

export const Message = () => {
  const {
    handleSubmit,
    particularRecentMessageDetail,
    duration,
    form,
    contactform,
    InqueryMessage,
    ContactMessage,
    ContacthandleSubmit,
    PContactRecentMessageDetail,
    ContactMessageDetail,
    setIsActive,
    isActive,
  } = MessgaeData();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <DashboardLayout>
      <div>
        <h1
          className="heading_text font-semibold text-black-dark p-0 m-0 mb-4"
          style={{ fontSize: "32px" }}
        >
          Messages
        </h1>
        <Cardcomponent cardClass="px-3 border-round-2xl shadow-2">
          <div className="grid">
            <div className="md:col-12">
              <div>
                {/* <span className="p-input-icon-left w-full align-items-center mb-5">
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Search conversation"
                    className="pl-5 py-2 w-full bg-light-main"
                  />
                </span> */}
                <div className="flex align-items-center">
                  <div className="flex tab-button align-items-center gap-2 border-1 surface-border border-round-xl">
                    <Button
                      onClick={() => {
                        setActiveIndex(0);
                        ContactMessage();
                      }}
                      className={`bg-none border-none p-button-text font-medium button-padding text-sm border-round-xl ${
                        activeIndex === 0 ? "active" : " "
                      }`}
                      label="Contact message"
                    />
                    <Button
                      onClick={() => {
                        setActiveIndex(1);
                        InqueryMessage();
                      }}
                      className={`bg-none border-none p-button-text font-medium button-padding text-sm border-round-xl ${
                        activeIndex === 1 ? "active" : " "
                      }`}
                      label="Enquiry form. "
                    />
                  </div>
                </div>
                <TabView
                  activeIndex={activeIndex}
                  className="dashboard-news subscriber-table"
                  onTabChange={(e) => setActiveIndex(e.index)}
                >
                  <TabPanel className="flex">
                    <div className="md:col-6">
                      {contactform?.length > 0 &&
                        contactform?.map((item, index) => {
                          return (
                            <div
                              className={`flex justify-content-between align-items-center border-round-lg message-list p-4 ${
                                isActive === index ? "active" : ""
                              }`}
                              onClick={() => {
                                setIsActive(index);
                                ContacthandleSubmit(item._id);
                              }}
                            >
                              <div className="flex align-items-center">
                                <Avatar
                                  icon="pi pi-user"
                                  size="large"
                                  shape="circle"
                                />
                                <div className="ml-3">
                                  <h3 className="text-black-dark m-0 mb-2 text-lg font-medium">
                                    {item?.fullName}
                                  </h3>
                                  <p className="text-gray m-0 font-medium">
                                    {item?.message?.length > 40
                                      ? item?.message.substring(0, 40)
                                      : item?.message}
                                  </p>
                                </div>
                              </div>
                              <p className="text-sm text-gray font-medium">
                                {/* <div className="mb-3 font-bold">
                                Id &nbsp;{item?.postId?.id}
                              </div> */}
                                {item?.duration?.years() === 0
                                  ? item?.duration?.months() === 0
                                    ? item?.duration?.days() === 0
                                      ? item?.duration?.hours() === 0
                                        ? item?.duration.minutes() === 0
                                          ? `${item?.duration
                                              .seconds()
                                              .toString()
                                              .substring(1)} sec`
                                          : `${item?.duration
                                              .minutes()
                                              .toString()
                                              .substring(1)} min`
                                        : `${item?.duration
                                            .hours()
                                            .toString()
                                            .substring(1)} hour`
                                      : `${item?.duration
                                          .days()
                                          .toString()
                                          .substring(1)} days`
                                    : `${item?.duration
                                        ?.months()
                                        .toString()
                                        .substring(1)} Month`
                                  : `${item?.duration
                                      .years()
                                      .toString()
                                      .substring(1)} Year`}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                    <div className="md:col-6 pl-5">
                      <div className="bg-dark-gray p-3 border-round-lg">
                        <div className="flex justify-content-between mb-4 ">
                          <div className="flex ">
                            <Avatar
                              icon="pi pi-user"
                              size="large"
                              shape="circle"
                            />
                            <div className="ml-3 w-9">
                              <h3 className="text-black-dark m-0 mb-2 text-lg font-medium">
                                {PContactRecentMessageDetail?.data?.fullName}
                              </h3>
                              <p className="text-gray m-0 font-medium">
                                Email:
                                {PContactRecentMessageDetail?.data?.email}
                              </p>
                              <p className="text-gray m-0 font-medium">
                                phone:
                                {PContactRecentMessageDetail?.mobile}
                              </p>
                            </div>
                          </div>
                          <p className="text-sm text-gray m-0 font-medium">
                            {duration?.years() === 0
                              ? duration?.months() === 0
                                ? duration?.days() === 0
                                  ? duration?.hours() === 0
                                    ? duration.minutes() === 0
                                      ? `${duration
                                          .seconds()
                                          .toString()
                                          .substring(1)} sec`
                                      : `${duration
                                          .minutes()
                                          .toString()
                                          .substring(1)} min`
                                    : `${duration
                                        .hours()
                                        .toString()
                                        .substring(1)} hour`
                                  : `${duration
                                      .days()
                                      .toString()
                                      .substring(1)} days`
                                : `${duration
                                    ?.months()
                                    .toString()
                                    .substring(1)} Month`
                              : `${duration
                                  .years()
                                  .toString()
                                  .substring(1)} Year`}
                          </p>
                        </div>
                        <p className="text-gray line-height-3 text-justify font-medium">
                          {PContactRecentMessageDetail?.data?.message}
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="flex">
                    <div className="md:col-6">
                      {form?.length > 0 &&
                        form?.map((item, index) => {
                          return (
                            <div
                              className={`flex justify-content-between align-items-center border-round-lg message-list p-4 ${
                                isActive == index ? "active" : ""
                              }`}
                              onClick={() => {
                                setIsActive(index);
                                handleSubmit(item._id);
                              }}
                            >
                              <div className="flex align-items-center">
                                <Avatar
                                  icon="pi pi-user"
                                  size="large"
                                  shape="circle"
                                />
                                <div className="ml-3">
                                  <h3 className="text-black-dark m-0 mb-2 text-lg font-medium">
                                    {item?.fullName}
                                  </h3>
                                  <p className="text-gray m-0 font-medium">
                                    {item?.message?.length > 40
                                      ? item?.message.substring(0, 40)
                                      : item?.message}
                                  </p>
                                </div>
                              </div>
                              <p className="text-sm text-gray font-medium">
                                {item?.duration?.years() === 0
                                  ? item?.duration?.months() === 0
                                    ? item?.duration?.days() === 0
                                      ? item?.duration?.hours() === 0
                                        ? item?.duration.minutes() === 0
                                          ? `${item?.duration
                                              .seconds()
                                              .toString()
                                              .substring(1)} sec`
                                          : `${item?.duration
                                              .minutes()
                                              .toString()
                                              .substring(1)} min`
                                        : `${item?.duration
                                            .hours()
                                            .toString()
                                            .substring(1)} hour`
                                      : `${item?.duration
                                          .days()
                                          .toString()
                                          .substring(1)} days`
                                    : `${item?.duration
                                        ?.months()
                                        .toString()
                                        .substring(1)} Month`
                                  : `${item?.duration
                                      .years()
                                      .toString()
                                      .substring(1)} Year`}
                              </p>
                            </div>
                          );
                        })}
                    </div>

                    <div className="md:col-6 pl-5">
                      <div className="bg-dark-gray p-3 border-round-lg">
                        <div className="flex justify-content-between mb-4 ">
                          <div className="flex ">
                            <Avatar
                              icon="pi pi-user"
                              size="large"
                              shape="circle"
                            />
                            <div className="ml-3 w-9">
                              <h3 className="text-black-dark m-0 mb-2 text-lg font-medium">
                                {particularRecentMessageDetail
                                  ? particularRecentMessageDetail?.data
                                      ?.fullName
                                  : ""}
                              </h3>
                              <p className="text-gray m-0 font-medium">
                                Email:
                                {particularRecentMessageDetail?.data?.email}
                              </p>
                              <p className="text-gray my-2 font-medium">
                                phone:
                                {particularRecentMessageDetail?.data?.mobile}
                              </p>
                              <p className="text-gray m-0 font-medium">
                                {
                                  particularRecentMessageDetail?.data?.postId
                                    ?.postType
                                }
                                &nbsp; ID - &nbsp;
                                <span className="font-bold">
                                  {particularRecentMessageDetail?.data?.postId?.postType.slice(
                                    0,
                                    1
                                  )}
                                </span>{" "}
                                &nbsp;
                                <span className="font-bold">
                                  {
                                    particularRecentMessageDetail?.data?.postId
                                      ?.id
                                  }
                                </span>
                              </p>
                            </div>
                          </div>
                          <p className="text-sm text-gray m-0 font-medium">
                            {duration?.years() === 0
                              ? duration?.months() === 0
                                ? duration?.days() === 0
                                  ? duration?.hours() === 0
                                    ? duration.minutes() === 0
                                      ? `${duration
                                          .seconds()
                                          .toString()
                                          .substring(1)} sec`
                                      : `${duration
                                          .minutes()
                                          .toString()
                                          .substring(1)} min`
                                    : `${duration
                                        .hours()
                                        .toString()
                                        .substring(1)} hour`
                                  : `${duration
                                      .days()
                                      .toString()
                                      .substring(1)} days`
                                : `${duration
                                    ?.months()
                                    .toString()
                                    .substring(1)} Month`
                              : `${duration
                                  .years()
                                  .toString()
                                  .substring(1)} Year`}
                          </p>
                        </div>
                        <p className="text-gray line-height-3 text-justify font-medium">
                          {particularRecentMessageDetail?.data?.message}
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel></TabPanel>
                  <TabPanel></TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </Cardcomponent>
      </div>
    </DashboardLayout>
  );
};
