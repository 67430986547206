import { types } from "../type";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import Constants from "../../utils/constants";
import api from "../../services/api";

export const countAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.count);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.COUNT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const postListAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("post", Constants.END_POINT.POSTLIST, data);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.POST_LIST,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const viewPostAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", `${Constants.END_POINT.VIEWPOST}/${id}`);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VIEW_POST,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const growthChartAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.GROWTHCHART);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GROWTH_CHART,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
