import React, { useState, useEffect } from "react";
import { RenterIndex } from "./RenterIndex";
import { CustomCheckbox, CustomInput } from "../../components/input/AllInput";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  ClimateControl,
  ContactDetail,
  IndoorFeatures,
  OutdoorFeatures,
  PropertyDetail,
} from "./CommonFeilds";
import {
  CustomDropDown,
  CustomTextArea,
} from "../../components/input/CustomInputs";
import { Button } from "primereact/button";
import ButtonComponent from "../../components/buttons/button";
import DashboardLayout from "../../layout";
import { useLocation, useNavigate } from "react-router-dom";
import { PostDetailTopBar } from "../../shared/PostDetailTopBar";

const RenterForms = ({ SetSelectProfile, selectProfile }) => {
  const items = [{ label: "Post details" }];
  const home = { label: "New post", url: "/" };
  const {
    data,
    setData,
    code,
    setCode,
    handleChange,
    show,
    id,
    minProperty,
    emailCheck,
    token,
    maxProperty,
    inputList,
    timeFrameDropdown,
    handleAddClick,
    handleChangeAddress,
    handleRemoveClick,
    handleSelectAddress,
    handleRoomChange,
    handlechangeStreetAddress,
    handleSelectStreetAddress,
    handleRangeChange,
    highlight,
    handleChangeSuburb,
    address,
    handleSelectAddressSuburb,
    bathRoomHightlight,
    carHightlight,
    PropertyList,
    viewPostData,
    check,
    page,
    setPage,
    handleClear,
    handleTypeChange,
    dates,
    setDates,
    handlePropertyRequirments,
    handleSubmit,
  } = RenterIndex();
  const location = useLocation();
  const handleChangeName = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
  };
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (emailCheck === true && token === null) {
      setVisible(true);
    }
  }, [emailCheck]);
  console.log(data, "====fomr=====");

  return (
    <DashboardLayout>
      <PostDetailTopBar
        heading="Renter post details"
        description="Please check the details carefully. If post okay then approve it other you can cancel the post."
        id={viewPostData?.id}
        model={items}
        home={home}
      />
      {/* Contact-details */}
      <div className="grid p-0 m-0 ">
        <ContactDetail
          data={data}
          setData={setData}
          token={token}
          emailCheck={emailCheck}
          show={show}
          handlechangeStreetAddress={handlechangeStreetAddress}
          handleSelectStreetAddress={handleSelectStreetAddress}
          code={code}
          setCode={setCode}
          handleChangeName={handleChangeName}
          handleChange={handleChange}
          address={address}
          handleSelectAddressSuburb={handleSelectAddressSuburb}
          handleChangeSuburb={handleChangeSuburb}
        />

        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
            Property type<code className="p-error text-sm">*</code>
          </h2>
          {data?.formErrors?.propertyType && (
            <small className="p-error text-sm ml-1">
              {data?.formErrors?.propertyType}
            </small>
          )}
        </div>
        {PropertyList?.propertyTypes?.length > 0 &&
          PropertyList?.propertyTypes?.map((item) => {
            return (
              <div className="col-12 md:col-6">
                {item?.forProfile?.includes("RENTER") ? (
                  <CustomCheckbox
                    optionLabel={item?.propertyType}
                    id={item?.propertyType}
                    name="propertyType"
                    value={item}
                    onChange={handleTypeChange}
                    disabled={
                      data?.formErrors?.password?.length > 0 ||
                      data?.formErrors?.confirmPassword?.length > 0
                        ? true
                        : false
                    }
                    checked={check?.some((type) =>
                      item._id === type._id ? true : false
                    )}
                  />
                ) : (
                  ""
                )}
              </div>
            );
          })}
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>

        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold m-0 ">
            Weekly Price Range
            <code className="p-error text-sm">*</code>
          </h2>
        </div>
        <div className="col-12 md:col-12  text-center"></div>
        <div className=" p-0 m-0 grid w-full ">
          <div className="col-12 md:col-6">
            <CustomInput
              extraClassName="w-full"
              placeholder="Select"
              label="Min"
              name="min"
              keyfilter="int"
              value={data?.priceRange?.min}
              onChange={handleRangeChange}
              errorMessage={data?.formErrors?.min}
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
            />
          </div>
          <div className="col-12 md:col-6">
            <CustomInput
              extraClassName="w-full"
              placeholder="Select"
              label="Max"
              keyfilter="int"
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
              name="max"
              value={data?.priceRange?.max}
              onChange={handleRangeChange}
              errorMessage={data?.formErrors?.max}
            />
          </div>
          {/* <Slider className=" mr-1 ml-1 p-0 m-0 price-range"
                      range onChange={(e) => handleRangeChange(e, "pricerange")} name="priceRange"
                      value={data.selectPriceRange} min={50000} max={15000000} /> */}
        </div>
        {data?.formErrors?.priceRange && (
          <small className="p-error text-sm ml-3">
            {data?.formErrors?.priceRange}
          </small>
        )}
        <div className="col-12 md:col-12 mt-2">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
            Purchase Location
            <code className="p-error text-sm">*</code>
          </h2>
        </div>
        <>
          {inputList?.map((item, index) => {
            return (
              <div
                className={`renter-suburb col-12 md:col-12 lg:col-12 ${
                  data?.formErrors?.password?.length > 0 ||
                  data?.formErrors?.confirmPassword?.length > 0
                    ? "pointer-events-none"
                    : ""
                }`}
              >
                <PlacesAutocomplete
                  value={item.streetAddress}
                  onChange={(e) => handleChangeAddress(e, index)}
                  onSelect={(e) => handleSelectAddress(e, index)}
                  searchOptions={{
                    componentRestrictions: { country: ["au"] },
                    types: ["locality"],
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                    item,
                  }) => (
                    <div className="subhurb-hightlight relative ">
                      <CustomInput
                        label="Suburb"
                        value={item?.streetAddress}
                        {...getInputProps({
                          placeholder: "Search Suburb",
                          className: "location-search-input w-full p-3",
                        })}
                      />
                      {inputList.length !== 1 && (
                        <i
                          className="pi pi-minus lg:p-2 md:p-2 "
                          style={{
                            " color": "rgb(112, 128, 144)",
                            // "padding": "10px",
                            marginTop: "10px",
                            marginLeft: "5px",
                            backgroundColor: "#f9fafa",
                            borderRadius: "10px",
                          }}
                          onClick={() => handleRemoveClick(index)}
                        ></i>
                        // <button className="mr10" >
                        //   Remove
                        // </button>
                      )}
                      {show === true ? (
                        <div className="autocomplete-dropdown-container absolute top-100 bg-white z-2 p-0 shadow-1  mb-2 lg:col-6">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                              : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                key={suggestion.placeId}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span className="">
                                  {suggestion.description}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
                {inputList.length - 1 === index && (
                  <div className=" add-suburb py-2 p-1 ">
                    <p
                      onClick={handleAddClick}
                      className="text-xl text-dark font-medium justify-content-between flex p-2 border-round-lg p-0 m-0"
                      style={{
                        backgroundColor: "rgba(249, 250, 250, 1)",
                      }}
                    >
                      &nbsp; Add Suburb
                      <span>
                        <i
                          className="pi pi-plus"
                          style={{
                            fontSize: "1rem",
                            color: "rgba(42, 53, 61, 1)",
                          }}
                        ></i>
                      </span>
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </>
        {data?.formErrors?.address && (
          <small className="p-error text-sm ml-3">
            {data?.formErrors?.address}
          </small>
        )}
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0 mt-2" />
        </div>

        <PropertyDetail
          highlight={highlight}
          carHightlight={carHightlight}
          bathRoomHightlight={bathRoomHightlight}
          data={data}
          handleRoomChange={handleRoomChange}
        />
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>

        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold m-0">
            Ready to Rent<code className="p-error text-sm">*</code>
          </h2>
        </div>
        <div className="col-12 md:col-6">
          <CustomDropDown
            placeholder="Select"
            extraClassName="w-full"
            labelClassName="text-black"
            options={timeFrameDropdown}
            onChange={handleChange}
            name="purchaseTimeFrame"
            optionLabel=""
            value={data.purchaseTimeFrame}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
          {data?.formErrors?.purchaseTimeFrame && (
            <small className="p-error text-sm ml-2">
              {data?.formErrors?.purchaseTimeFrame}
            </small>
          )}
        </div>
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>

        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
            Property requierments
            <code className="p-error text-sm">*</code>
          </h2>
        </div>
        <div className="col-12 md:col-4 lg:col-6">
          <CustomCheckbox
            optionLabel="Furnished"
            extraClassName=" w-auto"
            id="Furnished"
            name="furnished"
            value="furnished"
            onChange={handlePropertyRequirments}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
            checked={
              data?.propertyRequierments?.furnished !== null ? true : false
            }
          />
        </div>
        <div className="col-12 md:col-4 lg:col-6 ">
          <CustomCheckbox
            optionLabel="Pets considered"
            extraClassName="w-auto"
            id="Pets considered"
            name="petsConsidered"
            value="petsConsidered"
            onChange={handlePropertyRequirments}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
            checked={
              data?.propertyRequierments?.petsConsidered !== null ? true : false
            }
          />
        </div>
        {data?.formErrors?.propertyRequierments && (
          <small className="p-error text-sm ml-3">
            {data?.formErrors?.propertyRequierments}
          </small>
        )}
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>

        <div className="col-12 md:col-12">
          <OutdoorFeatures
            PropertyList={PropertyList}
            setData={setData}
            data={data}
            id={id}
          />
        </div>
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <div className="col-12">
          <IndoorFeatures
            PropertyList={PropertyList}
            setData={setData}
            data={data}
            id={id}
          />
        </div>
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>

        <div className="col-12">
          <ClimateControl
            PropertyList={PropertyList}
            setData={setData}
            data={data}
            id={id}
          />
          {/* <RenterClimateControl /> */}
        </div>
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 m-0 flex align-items-start font-bold">
            Top 2 Property must-haves
            <code className="p-error text-sm">*</code>
          </h2>
        </div>
        <div className="grid p-0 m-0 w-full">
          <div className="col-12 md:col-6 lg:col-6 flex ">
            <span className="mt-3 text-xl"> 1.</span>
            <span className="w-full ml-2">
              <CustomInput
                extraClassName="w-full"
                labelClassName="text-black"
                onChange={handleChange}
                name="minProperty"
                optionLabel=""
                value={minProperty}
                disabled={
                  data?.formErrors?.password?.length > 0 ||
                  data?.formErrors?.confirmPassword?.length > 0
                    ? true
                    : false
                }
              />
            </span>
          </div>
          <div className="col-12 md:col-6 lg:col-6 flex">
            <span className="mt-3 text-xl"> 2.</span>
            <span className="w-full ml-2">
              <CustomInput
                extraClassName="w-full"
                labelClassName="text-black"
                optionLabel=""
                name="maxProperty"
                onChange={handleChange}
                value={maxProperty}
                disabled={
                  data?.formErrors?.password?.length > 0 ||
                  data?.formErrors?.confirmPassword?.length > 0
                    ? true
                    : false
                }
              />
            </span>
          </div>
          {data?.formErrors?.propertyMustHave && (
            <small className="p-error text-sm ml-3">
              {data?.formErrors?.propertyMustHave}
            </small>
          )}
        </div>

        <div className=" renter-textarea col-12 md:col-12 lg:col-12 ">
          <CustomTextArea
            label="Notes"
            className="input_textarea w-full bg-transparent    p-3  mb-3"
            placeholder="Tell us more about the property you are looking for"
            name="note"
            onChange={handleChange}
            value={data?.note}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
      </div>
      <div className="buyer-data-button grid p-0 m-0 flex justify-content-between">
        <div className="col-12 md:col-3  lg:col-2 ">
          <div className="mb-3 lg:mb-0">
            <ButtonComponent
              label="CLEAR ALL"
              className="  bg-transparent text-black  border-0"
              onClick={handleClear}
            />
          </div>
        </div>
        <div className="col-12 md:col-3 lg:col-2">
          <div className=" ">
            <Button
              label={
                location.pathname === "/edit_deatils/" + id
                  ? "Save Changes"
                  : "Submit"
              }
              className="bg-main border-main w-full p-3 font-semibold font-base"
              onClick={() => handleSubmit(id)}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default RenterForms;
