import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useRef, useState } from "react";
import { TableComponent } from "../../components/table/table";
import { Button } from "primereact/button";
import DashboardLayout from "../../layout";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector } from "react-redux";
import { SubscribersList } from "../../redux/action/SubscriptionsAction";
import ButtonComponent from "../../components/buttons/button";
import {
  hideLoaderAction,
  showLoaderAction,
} from "../../redux/action/loaderAction";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Constants from "../../utils/constants";
import { showToast } from "../../redux/action/toastAction";
import api from "../../services/api";
import { Dialog } from "primereact/dialog";

export const Subscribers = () => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [first, setFirst] = useState(0);
  const [show, setshow] = useState(false);
  const [rows, setRows] = useState(10);
  const Subscribers = useSelector(
    (state) => state.SubscribersList.SubscribersList
  );

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const actionTemplate = (col) => {
    const confirm = (id) => {
      confirmDialog({
        message: "Do you want to delete this record?",
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        defaultFocus: "reject",
        acceptClassName: "p-button-danger",
        accept: () => Delete(id),
      });
    };

    return (
      <>
        <ButtonComponent
          label="Delete"
          className="bg-red-dark text-sm w-auto button-padding border-none border-round-lg button-padding font-medium cancel-button text-white-new "
          showIcon
          onClick={() => confirm(col._id)}
        />
      </>
    );
  };

  const emailTemplate = (col) => {
    return (
      <>
        <span>{col.email}</span>
      </>
    );
  };

  const RecentJoinHeading = [
    {
      field: "email",
      header: "Email",
      body: emailTemplate,
    },

    {
      field: "action",
      header: "Action",
      body: actionTemplate,
    },
  ];
  const Delete = async (id) => {
    dispatch(showLoaderAction());
    const res = await api(
      "delete",
      `${Constants.END_POINT.DeleteSubscribers}/${id}`
    );
    if (res.success) {
      dispatch(SubscribersList());

      dispatch(hideLoaderAction());
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

  useEffect(() => {
    dispatch(SubscribersList());
    console.log("SubscribersList", Subscribers);
  }, []);
  return (
    <DashboardLayout>
      <div>
        <ConfirmDialog />
        <h1 className="font-semibold text-black-dark">Subscribers</h1>
        <div className="shadow-2 p-3 border-round-lg mb-3">
          <div className="flex align-items-center justify-content-between mb-3">
            <h3 className="text-black-dark text-2xl m-0 font-medium">
              Subscribers list
            </h3>
            <div className="flex align-items-center">
              <div className="flex tab-button align-items-center gap-2 border-1 surface-border border-round-xl">
                {/* <Button
                  onClick={() => setActiveIndex(0)}
                  className={`bg-none border-none p-button-text font-mediumbutton-padding text-sm border-round-xl ${
                    activeIndex === 0 ? "active" : " "
                  }`}
                  label="All"
                /> */}
                {/* <Button
                  onClick={() => setActiveIndex(1)}
                  className={`bg-none border-none p-button-text font-mediumbutton-padding text-sm border-round-xl ${
                    activeIndex === 1 ? "active" : " "
                  }`}
                  label="Buyer"
                />
                <Button
                  onClick={() => setActiveIndex(2)}
                  className={`bg-none border-none p-button-text font-mediumbutton-padding text-sm border-round-xl ${
                    activeIndex === 2 ? "active" : " "
                  }`}
                  label="Seller"
                />
                <Button
                  onClick={() => setActiveIndex(3)}
                  className={`bg-none border-none p-button-text font-mediumbutton-padding text-sm border-round-xl ${
                    activeIndex === 3 ? "active" : " "
                  }`}
                  label="Renter"
                /> */}
              </div>
            </div>
          </div>
          <TabView
            activeIndex={activeIndex}
            className="dashboard-news subscriber-table"
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel>
              <TableComponent
                tableHeading={RecentJoinHeading}
                tableData={Subscribers}
                selectionMode={false}
              />
            </TabPanel>
            <TabPanel></TabPanel>
            <TabPanel></TabPanel>
            <TabPanel></TabPanel>
          </TabView>
        </div>
        <Paginator
          className="justify-content-end"
          first={first}
          rows={rows}
          totalRecords={120}
          onPageChange={onPageChange}
        />
      </div>
    </DashboardLayout>
  );
};
