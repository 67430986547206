import React, { useState, useEffect, useRef } from "react";
import { CustomCheckbox, CustomInput } from "../../components/input/AllInput";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  ClimateControl,
  ContactDetail,
  IndoorFeatures,
  OutdoorFeatures,
  PropertyDetail,
} from "./CommonFeilds";
import {
  CustomDropDown,
  CustomRadioButtons,
  CustomTextArea,
} from "../../components/input/CustomInputs";
import { Button } from "primereact/button";
import ButtonComponent from "../../components/buttons/button";
import DashboardLayout from "../../layout";
import { PostDetailTopBar } from "../../shared/PostDetailTopBar";
import { EditData } from "./EditData";
import { InputNumber } from "primereact/inputnumber";
import { useLocation } from "react-router-dom";
const EditDeatils = ({ SetSelectProfile, selectProfile }) => {
  const items = [{ label: "Post details" }];
  const home = { label: "New post", url: "/" };
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const {
    data,
    handleChangeSuburb,
    token,
    address,
    handleSelectAddressSuburb,
    navigate,
    id,
    handleSubmit,
    emailCheck,
    minProperty,
    maxProperty,
    timeFrameDropdown,
    handlechangeStreetAddress,
    handleSelectStreetAddress,
    PropertyList,
    handleSelectAddress,
    handleChangeAddress,
    inputList,
    show,
    page,
    setPage,
    code,
    setCode,
    handleChange,
    check,
    handleClear,
    handleTypeChange,
    handleRoomChange,
    handleRangeChange,
    highlight,
    bathRoomHightlight,
    carHightlight,
    setData,
    minLandSize,
    maxLandSize,
    maxLand,
    minLand,
    handleAddClick,
    handleRemoveClick,
    minPriceRange,
    viewPostData,
  } = EditData();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (emailCheck === true && token === null) {
      setVisible(true);
    }
  }, [emailCheck]);
  const handleChangeName = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
  };
  return (
    <DashboardLayout>
      <PostDetailTopBar
        heading="Buyer post details"
        description="Please check the details carefully. If post okay then approve it other you can cancel the post."
        id={viewPostData?.id}
        model={items}
        home={home}
      />
      {/* / Contact-details / */}
      <div className="grid p-0 m-0  ">
        <ContactDetail
          handleChange={handleChange}
          handleChangeName={handleChangeName}
          token={token}
          address={address}
          handleSelectAddressSuburb={handleSelectAddressSuburb}
          handleChangeSuburb={handleChangeSuburb}
          setData={setData}
          data={data}
          code={code}
          setCode={setCode}
          highlight={highlight}
          handlechangeStreetAddress={handlechangeStreetAddress}
          handleSelectStreetAddress={handleSelectStreetAddress}
          show={show}
          emailCheck={emailCheck}
          navigate={navigate}
        />

        {/* {/ Type of Purchase Section /} */}
        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
            Type of Purchase<code className="p-error text-sm">*</code>
          </h2>
        </div>
        <div className="col-12 md:col-4 lg:col-3 lg:w-3 ">
          <CustomRadioButtons
            className="Owner-occupied"
            optionLabel="Owner occupied"
            extraClassName="Owner-occupied w-auto"
            labelClass="font-bold"
            id="Owner"
            name="typeOfPurchase"
            value="ownerOccupied"
            onChange={handleChange}
            checked={data?.typeOfPurchase === "ownerOccupied"}
            errorMessage={data?.formErrors?.typeOfPurchase}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
        <div className="col-12 md:col-4 lg:col-3 ">
          <CustomRadioButtons
            optionLabel="Investment"
            extraClassName="w-auto"
            id="Investment"
            name="typeOfPurchase"
            value="investment"
            onChange={handleChange}
            checked={data?.typeOfPurchase === "investment"}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>

        {/* {/ New or Established property section /} */}
        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
            New or Established property
            <code className="p-error text-sm">*</code>
          </h2>
        </div>
        <div className="col-12 md:col-3 lg:col-2 lg:w-2">
          <CustomRadioButtons
            optionLabel="Any"
            extraClassName="w-auto"
            id="types"
            name="establishedProperty"
            value="allTypes"
            onChange={handleChange}
            checked={data?.establishedProperty === "allTypes"}
            errorMessage={data?.formErrors?.establishedProperty}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
        <div className="col-12 md:col-3 lg:col-2 lg:w-2">
          <CustomRadioButtons
            optionLabel="New"
            extraClassName="w-auto"
            id="New"
            name="establishedProperty"
            value="new"
            onChange={handleChange}
            checked={data?.establishedProperty === "new"}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
        <div className="col-12 md:col-3 lg:col-2 lg:w-2">
          <CustomRadioButtons
            optionLabel="Established"
            extraClassName="w-auto"
            id="establishedProperty"
            name="establishedProperty"
            value="established"
            onChange={handleChange}
            checked={data?.establishedProperty === "established"}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>

        {/* {/ Property type Section /} */}
        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
            Property type<code className="p-error text-sm">*</code>
          </h2>
          {data?.formErrors?.propertyType && (
            <small className="p-error text-sm ml-2">
              {data?.formErrors?.propertyType}
            </small>
          )}
        </div>
        {PropertyList?.propertyTypes?.length > 0 &&
          PropertyList?.propertyTypes?.map((item) => {
            return (
              <div className="col-12 md:col-6">
                {item?.forProfile?.includes("BUYER") ? (
                  <CustomCheckbox
                    optionLabel={item?.propertyType}
                    id={item?.propertyType}
                    name="propertyType"
                    value={item}
                    onChange={handleTypeChange}
                    disabled={
                      data?.formErrors?.password?.length > 0 ||
                      data?.formErrors?.confirmPassword?.length > 0
                        ? true
                        : false
                    }
                    checked={check?.some((type) =>
                      item._id === type._id ? true : false
                    )}
                  />
                ) : (
                  ""
                )}
              </div>
            );
          })}

        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>

        {/* {/ Price Range Section /} */}
        <div className="col-12 md:col-12">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
            Price Range<code className="p-error text-sm">*</code>
          </h2>
        </div>
        {/* <p className="col-12 md:col-12 p-0 m-0 text-center">
                    <div style={{ marginTop: "-37px", fontSize: "20px", color: "#00051A", fontWeight: "bold" }}>{Object.keys(data?.priceRange)?.length === 0 ? "Any Price" : data?.priceRange?.min === 50000 && data?.priceRange?.max === 15000000 || data?.priceRange?.max > 15000000 ? "Any Price" : data.priceRange?.max === 15000000 ? `More than $${data?.priceRange?.min < 1000000 ? Math.floor(data.priceRange?.min / 1000) + "k" : Math.floor(data?.priceRange?.min / 1000000) + "M"}` : data?.priceRange?.max < 15000000 && data.priceRange?.min === 50000 ? `Up to $${data.priceRange?.max < 1000000 ? Math.floor(data?.priceRange?.max / 1000) + "K" : Math.floor(data?.priceRange?.max / 1000000) + "M"}` : `$${data?.priceRange?.min < 100000 ? Math.floor(data?.priceRange?.min / 1000) + "k" : Math.floor(data?.priceRange?.min / 1000000) + "M"} to $${data?.priceRange?.max < 1000000 ? Math.floor(data?.priceRange?.max / 1000) + "K" : Math.floor(data?.priceRange?.max / 1000000) + "M"}`}</div>
                  </p> */}
        <div className="  p-2 grid w-full">
          <div className="col-12 md:col-6">
            <CustomDropDown
              extraClassName="w-full"
              placeholder="Select"
              label="Min"
              options={minPriceRange}
              optionLabel="label"
              name="min"
              value={data?.priceRange?.min}
              onChange={handleRangeChange}
              errorMessage={data?.formErrors?.min}
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
            />
          </div>
          <div className="col-12 md:col-6">
            <CustomDropDown
              extraClassName="w-full"
              placeholder="Select"
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
              label="Max"
              options={
                data?.priceRange?.min
                  ? minPriceRange.slice(
                      minPriceRange.findIndex((item) => {
                        return item.value === data?.priceRange.min;
                      })
                    )
                  : minPriceRange.filter((range) => range.value !== 0)
              }
              optionLabel="label"
              name="max"
              value={data?.priceRange?.max}
              onChange={handleRangeChange}
              errorMessage={data?.formErrors?.max}
            />
          </div>
          {/* <Slider className=" mr-1 ml-1 p-0 m-0 price-range"
                      range onChange={(e) => handleRangeChange(e, "pricerange")} name="priceRange"
                      value={data.selectPriceRange} min={50000} max={15000000} /> */}
        </div>
        {data?.formErrors?.priceRange && (
          <small className="p-error text-sm ml-3">
            {data?.formErrors?.priceRange}
          </small>
        )}
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>
        {/* 


                  {/* {/ Bedrooms Section /} */}

        <PropertyDetail
          handleRoomChange={handleRoomChange}
          data={data}
          highlight={highlight}
          bathRoomHightlight={bathRoomHightlight}
          carHightlight={carHightlight}
        />
        <div className="col-12 md:col-12 ">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
        </div>

        {/* {/ Purchase timeframe section /} */}
        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
            Purchase timeframe
            <code className="p-error text-sm">*</code>
          </h2>
        </div>
        <div className="col-12 md:col-6 lg:col-6">
          <CustomDropDown
            label="Min"
            placeholder="Select"
            extraClassName="w-full"
            labelClassName="text-black"
            options={timeFrameDropdown}
            onChange={handleChange}
            name="purchaseTimeFrame"
            optionLabel=""
            value={data.purchaseTimeFrame}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
          {data?.formErrors?.purchaseTimeFrame && (
            <small className="p-error text-sm ml-2">
              {data?.formErrors?.purchaseTimeFrame}
            </small>
          )}
        </div>
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0 mt-2" />
        </div>

        {/* {/ Land size Section /} */}
        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
            Land size<code className="p-error text-sm">*</code>
          </h2>
        </div>
        <div className="col-12 md:col-6 lg:col-6">
          <CustomDropDown
            label="Min"
            placeholder="Select"
            extraClassName="w-full"
            labelClassName="text-black"
            options={["Any", ...minLandSize]}
            onChange={handleChange}
            name="minLand"
            optionLabel=""
            value={minLand}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
        <div className="col-12 md:col-6 lg:col-6">
          <CustomDropDown
            label="Max"
            placeholder="Select"
            extraClassName="w-full"
            labelClassName="text-black"
            optionLabel=""
            name="maxLand"
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
            options={
              minLand
                ? [
                    "Any",
                    ...minLandSize.slice(
                      minLand !== "Any" ? minLandSize.indexOf(minLand) : 0
                    ),
                  ]
                : minLandSize
            }
            onChange={handleChange}
            value={maxLand}
          />
        </div>
        {data?.formErrors?.landSize && (
          <small className="p-error text-sm ml-3">
            {data?.formErrors?.landSize}
          </small>
        )}
        <div className="col-12 md:col-12">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0 mt-2" />
        </div>

        {/* {/ Purchase Location section /} */}
        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
            Purchase Location
            <code className="p-error text-sm">*</code>
          </h2>
        </div>
        <>
          {inputList?.map((item, index) => {
            return (
              <div
                className={`col-12 md:col-12 lg:col-12 ${
                  data?.formErrors?.password?.length > 0 ||
                  data?.formErrors?.confirmPassword?.length > 0
                    ? "pointer-events-none"
                    : ""
                }`}
              >
                <PlacesAutocomplete
                  value={item.streetAddress}
                  onChange={(e) => handleChangeAddress(e, index)}
                  onSelect={(e) => handleSelectAddress(e, index)}
                  searchOptions={{
                    componentRestrictions: { country: ["au"] },
                    types: ["locality"],
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                    item,
                  }) => (
                    <div className="subhurb-hightlight relative lg:mr-0 md:mr-0 mr-4">
                      <CustomInput
                        label="Suburb"
                        value={item?.streetAddress}
                        {...getInputProps({
                          placeholder: "Search Suburb",
                          className: "location-search-input w-full p-3",
                        })}
                      />
                      {inputList.length !== 1 && (
                        <i
                          className="pi pi-minus lg:p-2 md:p-2 "
                          style={{
                            " color": "rgb(112, 128, 144)",
                            // "padding": "10px",
                            marginTop: "10px",
                            marginLeft: "5px",
                            backgroundColor: "#f9fafa",
                            borderRadius: "10px",
                          }}
                          onClick={() => handleRemoveClick(index)}
                        ></i>
                        // <button className="mr10" >
                        //   Remove
                        // </button>
                      )}
                      {show === true ? (
                        <div className="autocomplete-dropdown-container absolute top-100 bg-white z-2 p-0 shadow-1  mb-2 lg:col-6">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                              : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                key={suggestion.placeId}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span className="">
                                  {suggestion.description}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
                {inputList.length - 1 === index && (
                  <div className=" add-suburb py-2 p-1 ">
                    <p
                      onClick={handleAddClick}
                      className="text-xl text-dark font-medium justify-content-between flex p-2 border-round-lg p-0 m-0"
                      style={{
                        backgroundColor: "rgba(249, 250, 250, 1)",
                      }}
                    >
                      &nbsp; Add Suburb
                      <span>
                        <i
                          className="pi pi-plus"
                          style={{
                            fontSize: "1rem",
                            color: "rgba(42, 53, 61, 1)",
                          }}
                        ></i>
                      </span>
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </>
        {data?.formErrors?.address && (
          <small className="p-error text-sm ml-3">
            {data?.formErrors?.address}
          </small>
        )}
        <div className="col-12 md:col-12 mb-3">
          <hr className="buyer-hr ml-1 mr-1 p-0 m-0 mt-2" />
        </div>

        {/* {/ Purchase method section /} */}
        <div className="col-12 md:col-12 ">
          <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold m-0">
            Purchase method<code className="p-error text-sm">*</code>
          </h2>
        </div>
        <div className="col-12 md:col-4 lg:col-3 lg:w-2">
          <CustomRadioButtons
            optionLabel="Finance"
            extraClassName="Owner-occupied w-auto"
            id="Finance"
            name="purchaseMethod"
            value="FINANCE"
            onChange={handleChange}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
            checked={data?.purchaseMethod === "FINANCE"}
          />
        </div>
        <div className="col-12 md:col-4 lg:col-3 ">
          <CustomRadioButtons
            optionLabel="Own Funds"
            extraClassName="w-auto"
            id="Cash"
            name="purchaseMethod"
            value="OWNFUNDS"
            onChange={handleChange}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
            checked={data?.purchaseMethod === "OWNFUNDS"}
          />
        </div>

        {/* <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div> */}
      </div>

      {data?.purchaseMethod === "FINANCE" ? (
        <div className="grid m-0 p-0">
          <div className="col-12 md:col-4 lg:col-4 lg:w-3">
            <CustomRadioButtons
              optionLabel="Finance is arranged"
              extraClassName="Owner-occupied w-auto"
              id="arranged"
              name="financeDetails"
              value={true}
              onChange={handleChange}
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
              checked={data?.financeDetails?.isArranged === true}
            />
          </div>
          <div className="col-12 md:col-8 lg:col-8 ">
            <CustomRadioButtons
              optionLabel="Need to arrange finance"
              extraClassName="w-auto"
              id="financeDetails"
              name="financeDetails"
              value={false}
              onChange={handleChange}
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
              checked={data?.financeDetails?.isArranged === false}
            />
          </div>
        </div>
      ) : null}
      {data?.formErrors?.financeDetails && (
        <small className="p-error text-sm ml-3">
          {data?.formErrors?.financeDetails}
        </small>
      )}
      {data?.purchaseMethod === "FINANCE" &&
      data?.financeDetails?.isArranged === false ? (
        <div className="buyer-finance-content col-12 md:col-6 buyer-finance-section mb-3 ">
          <label
            htmlFor="integer"
            className="font-bold block mb-2 text-lg ml-1"
          >
            Deposit ($)
          </label>
          <div
            className="border-1 border-round-lg flex align-items-center ml-1"
            style={{ borderColor: "#8F9A99" }}
          >
            <span>
              <i
                className="pi pi-dollar ml-2 "
                style={{ fontSize: "1rem", marginTop: "6px" }}
              ></i>
            </span>
            <InputNumber
              inputClassName="border-0"
              className=" buyer-finance-content w-full p-2  border-0"
              extraClassName="border-0"
              inputId="integeronly"
              name="depositAmount"
              onValueChange={handleChange}
              placeholder="amount"
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
              value={data?.financeDetails?.depositAmount}
              style={{ border: "0px" }}
            />
          </div>
        </div>
      ) : null}
      {data?.financeDetails?.isArranged === true &&
      data?.purchaseMethod === "FINANCE" ? (
        <div className="grid p-0 m-0 mb-3">
          <div className="buyer-finance-content col-12 md:col-6 buyer-finance-section">
            <label htmlFor="integer" className="font-bold block mb-2 text-lg">
              Pre-approved ($)
            </label>
            <div
              className="border-1 border-round-lg flex align-items-center ml-1"
              style={{ borderColor: "#8F9A99" }}
            >
              <span>
                <i
                  className="pi pi-dollar ml-2  "
                  style={{ fontSize: "1rem", marginTop: "6px" }}
                ></i>
              </span>
              <InputNumber
                inputClassName="border-0"
                label="Pre-approved ($)"
                className=" buyer-finance-content w-full p-2 border-0"
                inputId="integeronly"
                name="preApproved"
                onValueChange={handleChange}
                value={data?.financeDetails?.preApproved}
                disabled={
                  data?.formErrors?.password?.length > 0 ||
                  data?.formErrors?.confirmPassword?.length > 0
                    ? true
                    : false
                }
                placeholder="amount"
              />
            </div>
          </div>
          <div className="buyer-finance-content col-12 md:col-6 buyer-finance-section">
            <label htmlFor="integer" className="font-bold block mb-2 text-lg">
              Deposit ($)
            </label>
            <div
              className="border-1 border-round-lg flex align-items-center ml-1"
              style={{ borderColor: "#8F9A99" }}
            >
              <span>
                <i
                  className="pi pi-dollar ml-2 "
                  style={{ fontSize: "1rem", marginTop: "6px" }}
                ></i>
              </span>
              <InputNumber
                inputClassName="border-0"
                label="Deposit ($)"
                className=" buyer-finance-content w-full p-2 border-0"
                inputId="integeronly"
                name="depositAmount"
                onValueChange={handleChange}
                value={data?.financeDetails?.depositAmount}
                placeholder="amount"
                disabled={
                  data?.formErrors?.password?.length > 0 ||
                  data?.formErrors?.confirmPassword?.length > 0
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </div>
      ) : null}
      {data?.formErrors?.depositAmount && (
        <small className="p-error text-sm ml-3">
          {data?.formErrors?.depositAmount}
        </small>
      )}
      {data?.formErrors?.purchaseMethod && (
        <small className="p-error text-sm ml-3">
          {data?.formErrors?.purchaseMethod}
        </small>
      )}
      <div className="col-12 md:col-12 mb-3 ">
        <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
      </div>
      {/* {/ Outdoor Features section /} */}
      <div className="col-12 md:col-12 ">
        <OutdoorFeatures
          PropertyList={PropertyList}
          data={data}
          setData={setData}
          id={id}
        />
      </div>
      <div className="col-12 md:col-12 mb-3">
        <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
      </div>

      {/* {/ Indoor Features section /} */}
      <div className="col-12 md:col-12">
        <IndoorFeatures
          PropertyList={PropertyList}
          data={data}
          setData={setData}
          id={id}
        />
      </div>
      <div className="col-12 md:col-12 mb-3">
        <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
      </div>

      {/* {/ Climate Control Features section /} */}
      <div className="col-12 md:col-12">
        <ClimateControl
          PropertyList={PropertyList}
          data={data}
          setData={setData}
          id={id}
        />
      </div>
      <div className="col-12 md:col-12 ">
        <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
      </div>
      <div className="col-12 md:col-12 ">
        <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
          Top 2 Property must-haves
          <code className="p-error text-sm">*</code>
        </h2>
      </div>
      <div className="grid p-0 m-0">
        <div className="col-12 md:col-6 lg:col-6 flex ">
          <span className="mt-3 text-xl"> 1.</span>
          <span className="w-full ml-2">
            <CustomInput
              extraClassName="w-full"
              labelClassName="text-black"
              onChange={handleChange}
              name="minProperty"
              optionLabel=""
              value={minProperty}
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
            />
          </span>
        </div>
        <div className="col-12 md:col-6 lg:col-6 flex">
          <span className="mt-3 text-xl"> 2.</span>
          <span className="w-full ml-2">
            <CustomInput
              extraClassName="w-full"
              labelClassName="text-black"
              optionLabel=""
              name="maxProperty"
              onChange={handleChange}
              value={maxProperty}
              disabled={
                data?.formErrors?.password?.length > 0 ||
                data?.formErrors?.confirmPassword?.length > 0
                  ? true
                  : false
              }
            />
          </span>
        </div>
        {data?.formErrors?.propertyMustHave && (
          <small className="p-error text-sm ml-3">
            {data?.formErrors?.propertyMustHave}
          </small>
        )}
      </div>

      {/* {/ notes /} */}
      <div className=" buyer-textarea grid m-0 p-0 mt-3">
        <div className="col-12 md:col-12 lg:col-12">
          <CustomTextArea
            label="Notes"
            className="buyer-input-textarea w-full bg-transparent    p-3  mb-3"
            placeholder="Tell us more about the property you are looking for."
            name="note"
            onChange={handleChange}
            value={data?.note}
            disabled={
              data?.formErrors?.password?.length > 0 ||
              data?.formErrors?.confirmPassword?.length > 0
                ? true
                : false
            }
          />
        </div>
      </div>
      {/* {/ hr /} */}
      <div className="col-12 md:col-12 mb-3">
        <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
      </div>
      <div className="buyer-data-button grid p-0 m-0 flex justify-content-between">
        <div className="col-12 md:col-3  lg:col-2 ">
          <div className="mb-3">
            <ButtonComponent
              onClick={() => handleClear()}
              label="CLEAR ALL"
              className=" bg-transparent text-black  border-0"
            />
          </div>
        </div>
        <div className="col-12 md:col-3 lg:col-2">
          <div className=" ">
            {console.log("location", location)}
            <Button
              label={
                location.pathname === "/edit_deatils/" + id
                  ? "Save Changes"
                  : "Submit"
              }
              className="bg-main border-main w-full p-3 font-semibold font-base"
              onClick={() => {
                handleSubmit(id);
                gotoBtn();
              }}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default EditDeatils;
