export default class Constants {
  static BASE_URL = "https://api.thebuyersplace.com.au/";
  static END_POINT = {
    SIGNUP: "api/admin/signup",
    LOGIN: "api/admin/login",
    UPLOADFILE: "api/user/upload-files",
    FORGETPASSWORD: "api/admin/forgot-password",
    RESETPASSWORD: "api/admin/change-forget-password",
    count: "api/admin/count",
    POSTLIST: "api/admin/post-lists",
    VIEWPOST: "api/admin/view-post",
    PROPERTYCONSTANT: "api/user/gernal-propety-info",
    ACCEPTREJECT: "api/admin/accept-reject",
    USERLIST: "api/admin/user-list",
    USERPROFILE: "api/admin/user",
    INQUERYLIST: "api/admin/inquery-list",
    CONTACTLIST: "api/admin/contacts",
    PARTICULARINQUERYLIST: "api/admin/inquery-info",
    PARTICULARCONTACTLIST: "api/admin/contact",
    GROWTHCHART: "api/admin/monthlyCounts",
    NOTIFICATION: "api/admin/notification",
    NOTIFICATIONCOUNT: "api/admin/notification-count",
    READNOTIFICATION: "api/admin/notification-read",
    GETARCHIVEDPOST: "api/admin/archived-post-lists",
    SUBSCRIPTIONS: "api/admin/news-letters",
    ARCHIVEDPOST: "api/admin/archived-post",
    GERENALPROPERTYINFO: "api/user/gernal-propety-info",
    MYPROFILE: "api/user/my-profile",
    CREATEBUYERFORM: "api/admin/edit-post",
    DeleteSubscribers: "/api/admin/delete-subscriber",
    DeleteUser: "api/admin/delete-user/",
  };
}
