import React from "react";
import Logo from "../../assets/images/Logo.png";
import {
  CustomCheckbox,
  CustomInput,
  CustomPassword,
} from "../../components/input/AllInput";
import { Link, useNavigate } from "react-router-dom";
import { ButtonOutlined } from "../../components/buttons/button";
import { LoginIndex } from "./LoginIndex";
export const Login = () => {
  const { form, handleChange, handleSubmit, remimber, setRemimber,userRef,passRef,rememberFunc } = LoginIndex()
  return (
    <div className="bg-light-white" style={{ minHeight: "100vh" }}>
      <div
        className="grid justify-content-center align-items-center w-auto md:w-full"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-11 md:col-8 lg:col-6 xl:col-5">
          <div className="bg-light-main border-round-lg p-4 relative">

            <h1 className="text-4xl text-black-dark font-semibold text-center m-0 mb-4">Welcome Back!</h1>
            <img src={Logo} alt="" width={229} className="m-auto block mb-3" />
            <CustomInput userref={userRef} label="Email" col={12}  placeholder="Email" name="email" onChange={handleChange} value={form?.email} errorMessage={form?.formErrors?.email} />
            <CustomPassword passref={passRef} label="Password" col={12}  placeholder="Password" name="password" value={form?.password} onChange={handleChange} errorMessage={form?.formErrors?.password} />
            <div className="flex justify-content-between align-items-center mb-4">
              <CustomCheckbox optionLabel="Remember me" col={4} value={remimber} onChange={(e) => rememberFunc(e)} />
              <Link to="/forget-password" className="no-underline text-main font-medium">
                Forgot password?
              </Link>
            </div>
            <ButtonOutlined label="SIGN IN" onClick={handleSubmit} />
            <div className="text-center mt-4">
              <p className="text-gray font-medium">
                New User?
                <Link
                  to="/signup"
                  className="text-main ml-2 no-underline font-medium"
                >
                  Create an Account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
