import { types } from "../type";

const intitalState = {
    acceptRejectProfile: []
}

export const acceptRejectReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ACCEPT_REJECT:
            return {
                ...state,
                acceptRejectProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}