export const types = {
  SING_UP: "SING_UP ",
  SHOW_TOAST: "SHOW_TOAST",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SING_IN: "SING_IN",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  COUNT: "COUNT",
  POST_LIST: "POST_LIST",
  VIEW_POST: "VIEW_POST",
  PROPERTY_CONSTANT: "PROPERTY_CONSTANT",
  ACCEPT_REJECT: "ACCEPT_REJECT",
  USER_LIST: "USER_LIST",
  USER_PROFILE: "USER_PROFILE",
  INQUERY_LIST: "INQUERY_LIST",
  CONTACTLIST: "CONTACTLIST",
  PARTICULAR_INQUERY_LIST: "PARTICULAR_INQUERY_LIST",
  PARTICULAR_CONTACT_LIST: "PARTICULAR_CONTACT_LIST",
  GROWTH_CHART: "GROWTH_CHART",
  NOTIFICATION: "NOTIFICATION",
  MARK_NOTIFICATION_AS_READ: "MARK_NOTIFICATION_AS_READ",
  NOTIFICATION_COUNT: "NOTIFICATION_COUNT",
  NOTIFICATION_READ: "NOTIFICATION_READ",
  GET_ARCHIVED_POST: "GET_ARCHIVED_POST",
  ARCHIVED_POST: "ARCHIVED_POST",
  GENERAL_PROPERTY_INFO: "GENERAL_PROPERTY_INFO",
  MY_PROFILE: "MY_PROFILE",
  CREATE_BUYER_FORM: "CREATE_BUYER_FORM",
  UPLOAD_FILE: "UPLOAD_FILE",
  SUBSCRIBERS: "SUBSCRIBERS",
};
