import { types } from "../type";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import Constants from "../../utils/constants";
import api from "../../services/api";

export const notificationAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.NOTIFICATION);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.NOTIFICATION,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const notificationCountAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.NOTIFICATIONCOUNT);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.NOTIFICATION_COUNT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const notificationReadAction = (params) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api(
    "put",
    `${Constants.END_POINT.READNOTIFICATION}/${params}`,
    {}
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.NOTIFICATION_READ,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
