import React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import { Login } from "../pages/login";
import { Signup } from "../pages/signup";
import { ForgetPassword } from "../pages/signup/ForgetPassword";
import { EmailVerify } from "../pages/signup/EmailVerify";
import { SetPassword } from "../pages/signup/SetPassword";
import { Dashboard } from "../views/dashboard/Dashboard";
import { NewPostList } from "../views/NewPostList/Index";
import { BuyerPostDetail } from "../views/NewPostList/BuyerPostDetail";
import { RenterpostDetail } from "../views/NewPostList/RenterpostDetail";
import { SellerPostDetail } from "../views/NewPostList/SellerPostDetail";
import { UserList } from "../views/users/UserList";
import { UserProfile } from "../views/users/UserProfile";
import { Message } from "../views/message/Message";
import { Subscribers } from "../views/subscriber/Subscribers";
import Loader from "../shared/Loader";
import ToastContainer from "../shared/ToastContainer";
import ProtectedRoutes from "./protectedRoutes";
import { ArchivedPost } from "../views/archived-post";
import EditDeatils from "../views/Edit/EditDeatils";
import RenterForm from "../views/Edit/RenterForms";
import SellerEditForm from "../views/Edit/SellerEditFrom";

export const Index = () => {
  const forgetUrl = "/set-password/:token";
  return (
    <div>
      <Loader />
      <ToastContainer />

      {/* <ProtectedRoutes/> */}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/email-verify" element={<EmailVerify />} />
        <Route path={forgetUrl} element={<SetPassword />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoutes>
              <Dashboard />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/new-post-list"
          element={
            <ProtectedRoutes>
              <NewPostList />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/new-post-list/buyer-post-detail/:id"
          element={
            <ProtectedRoutes>
              <BuyerPostDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/archived/buyer-post-detail/:id"
          element={
            <ProtectedRoutes>
              <BuyerPostDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/edit_deatils/:id"
          element={
            <ProtectedRoutes>
              <EditDeatils />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/renter-edit_deatils/:id"
          element={
            <ProtectedRoutes>
              <RenterForm />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/seller-edit_deatils/:id"
          element={
            <ProtectedRoutes>
              <SellerEditForm />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/renter-post-detail/:id"
          element={
            <ProtectedRoutes>
              <RenterpostDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/new-post-list/renter-post-detail/:id"
          element={
            <ProtectedRoutes>
              <RenterpostDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/archived/renter-post-detail/:id"
          element={
            <ProtectedRoutes>
              <RenterpostDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path=":id"
          element={
            <ProtectedRoutes>
              <RenterpostDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/new-post-list/seller-post-detail/:id"
          element={
            <ProtectedRoutes>
              <SellerPostDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/archived/seller-post-detail/:id"
          element={
            <ProtectedRoutes>
              <SellerPostDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/archived"
          element={
            <ProtectedRoutes>
              <ArchivedPost />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/user-list"
          element={
            <ProtectedRoutes>
              <UserList />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/user-list/:keyword"
          element={
            <ProtectedRoutes>
              <UserList />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/user-list/user-profile/:id"
          element={
            <ProtectedRoutes>
              <UserProfile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/message"
          element={
            <ProtectedRoutes>
              <Message />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/subscriber"
          element={
            <ProtectedRoutes>
              <Subscribers />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </div>
  );
};
