import api from "../../services/api";
import Constants from "../../utils/constants";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { types } from "../type";

export const AcceptRejectPostListAction = (data, id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", `${Constants.END_POINT.ACCEPTREJECT}/${id}`, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ACCEPT_REJECT,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({severity:"error",summary:res.message}))
    }
}