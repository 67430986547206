import React from 'react'
import { Route, Navigate, Routes } from "react-router-dom";
import { isAuthenticated } from '../services/authService';
import { allRoutes } from './routesIndex';



const ProtectedRoutes = ({children}) => {
    const AuthenticateRoutes = () => {
    return isAuthenticated() ? children : <Navigate to="/" replace/>
    }
  return (
    <div>{AuthenticateRoutes()}</div>
  )
}


export default ProtectedRoutes;