export const ContactDetail = ({
  fullName,
  email,
  number,
  fulladdress,
  rentAmount,
}) => {
  return (
    <>
      <div className="grid p-0 m-0 ">
        {/* Contact-Details Section */}
        <div className="col-12 md:col-12">
          <h2 className=" text-black-dark m-0 font-medium text-2xl">
            Contact details
          </h2>
        </div>
        <div className="col-12 md:col-12 mb-2">
          <span className="font-medium text-gray">{fullName}</span>
        </div>
        <div className="col-12 md:col-12 mb-2">
          <span className="font-medium text-gray">{email}</span>
        </div>
        <div className="col-12 md:col-12 mb-2">
          <span className="font-medium text-gray">{number}</span>
        </div>
        <div className="col-12 md:col-12 mb-2">
          <span className="font-medium text-gray">{fulladdress}</span>
        </div>
        <div className="col-12 md:col-12 mb-2">
          <span className="font-medium text-gray">{rentAmount}</span>
        </div>
      </div>
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>
    </>
  );
};

export const Propertytype = ({ viewPostData }) => {
  return (
    <>
      {/* Property type  Section*/}
      <div className="col-12 md:col-12">
        <h2 className=" text-black-dark font-medium text-2xl">Property type</h2>
      </div>
      {viewPostData?.propertyType?.length > 0 && viewPostData?.propertyType?.map((item, index) => {
        return (
          <div className="col-12 md:col-6  mb-3">
            <span className="font-medium text-gray text-lg ">{item?.propertyType}</span>
          </div>
        )
      })}
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>
    </>
  );
};
export const PropertySpaceDetail = ({ viewPostData }) => {
  return (
    <>
      <div className="col-12 md:col-12">
        <h2 className=" text-black-dark font-medium text-2xl">Bedrooms</h2>
      </div>
      <div className="col-12 md:col-12 mb-3">
        <span className="font-medium text-gray ">{viewPostData?.bedrooms === null ? "any":`${viewPostData.bedrooms}+`}</span>
      </div>
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>

      {/* Bathrooms Section */}
      <div className="col-12 md:col-12">
        <h2 className=" text-black-dark font-medium text-2xl">Bathrooms</h2>
      </div>
      <div className="col-12 md:col-12 mb-3">
        <span className="font-medium text-gray ">{viewPostData?.bathrooms === null ? "any":`${viewPostData.bathrooms}+`}</span>
      </div>
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>

      {/* Car spaces Section */}
      <div className="col-12 md:col-12">
        <h2 className=" text-black-dark font-medium text-2xl">Car spaces</h2>
      </div>
      <div className="col-12 md:col-12 mb-3">
        <span className="font-medium text-gray ">{viewPostData?.carSpaces === null ? "any":`${viewPostData.carSpaces}+`}</span>
      </div>
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>
    </>
  );
};
export const OutdoorDetail = ({ viewPostData }) => {
  return (
    <>
      <div className="col-12 md:col-12">
        <h2 className=" text-black-dark font-medium text-2xl">
          Outdoor features
        </h2>
      </div>
      {viewPostData?.outdoorFeatures?.length > 0 ?
       viewPostData?.outdoorFeatures?.map((item) => {
        return (
          <div className="col-12 md:col-6 mb-3">
            <span className="font-medium text-gray text-lg">{item?.title}</span>
          </div>
        )
      }):<span className="ml-2 font-medium text-black text-lg ">N/A</span>}


      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>
    </>
  );
};

export const IndoorDetail = ({ viewPostData }) => {
  return (
    <>
      {/* Indoor features Section */}
      <div className="col-12 md:col-12">
        <h2 className=" text-black-dark font-medium text-2xl">
          Indoor features
        </h2>
      </div>
      {viewPostData?.indoorFeatures?.length > 0 ? viewPostData?.indoorFeatures?.map((item) => {
        return (
          <div className="col-12 md:col-6 mb-3">
            <span className="font-medium text-gray text-lg">{item?.title}</span>
          </div>
        )
      }):<span className="ml-2 font-medium text-black text-lg ">N/A</span>}

      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>
    </>
  );
};

export const ClimateControl = ({ viewPostData }) => {
  return (
    <>
      {/* Climate control & energy Section */}
      <div className="col-12 md:col-12">
        <h2 className=" text-black-dark font-medium text-2xl">
          Climate control & energy
        </h2>
      </div>
      {viewPostData?.climateControlEnergy?.length > 0 ? viewPostData?.climateControlEnergy?.map((item) => {
        return (
          <div className="col-12 md:col-6 mb-3">
            <span className="font-medium text-gray text-lg">{item?.title ? item?.title:"N/A"}</span>
          </div>
        )
      }):<span className="ml-2 font-medium text-black text-lg ">N/A</span>}

      <div className="col-12 md:col-12 mb-3">
        <hr className="buyer-hr p-0 m-0" />
      </div>
    </>
  );
};
