import { types } from "../type";

const intitalState = {
    userListProfile: [],
    userProfile: {}
}

export const userListReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.USER_LIST:
            return {
                ...state,
                userListProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const userProfileReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }

}