import React, { useEffect, useState } from "react";
import { viewPostAction } from "../../redux/action/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { propertyTypeAction } from "../../redux/action/propertyAction";
import { useParams } from "react-router-dom";
export const CommonIndex = () => {
    const dispatch = useDispatch()
    const [property, setProperty] = useState([])
    const [outdoor, setOutdoor] = useState([])
    const [indoor, setIndoor] = useState([])
    const [climate, setClimate] = useState([])
    const { id } = useParams()
    useEffect(() => {
        dispatch(viewPostAction(id))
        dispatch(propertyTypeAction())
    }, [])
    const viewPostData = useSelector((state) => state.viewPost.viewPostProfile)
    const propertyTypeData = useSelector((state) => state.propertyType.propertyProfile) 
    return { viewPostData, propertyTypeData, property, outdoor, indoor, climate }
}