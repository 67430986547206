import React from "react";
import { BreadCrumb } from "primereact/breadcrumb";

export const PostDetailTopBar = ({
  heading,
  description,
  model,
  home,
  id,
  postType,
}) => {
  return (
    <>
      <BreadCrumb className="border-none" model={model} home={home} />
      <div className="grid align-items-center justify-content-between lg:mb-3 lg:mt-4 mt-3 mb-3  p-0 m-0">
        <div className="lg:col-10 md:col-10 md:col-12">
          <h1
            className="heading_text font-semibold text-black-dark p-0 m-0"
            style={{ fontSize: "32px" }}
          >
            {heading}
          </h1>
          {description ? (
            <p className="text-lg text-gray line-height-3 font-medium">
              {description}
            </p>
          ) : null}
        </div>
        {id || postType ? (
          <div className="lg:col-2 md:col-2 ">
            <small className="text-gray text-sm font-medium">
              {postType} Id:
              <small className="text-black-dark text-lg font-bold">
                &nbsp;{postType?.slice(0, 1)}&nbsp;
                {id}
              </small>
            </small>
          </div>
        ) : null}
      </div>
    </>
  );
};
