import { types } from "../type";

const initialState = {
  notificationProfile: {},
  notificationCountProfile: [],
  notificationReadProfile: [],
};
export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NOTIFICATION:
      return {
        ...state,
        notificationProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export const notificationCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCountProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
export const notificationReadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NOTIFICATION_READ:
      return {
        ...state,
        notificationReadProfile: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
