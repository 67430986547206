import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  SellerUploadImageAction,
  buyerGeneralInfo,
  createBuyerAction,
  myProfileAction,
} from "../../redux/action/propertyAction";
import { showToast } from "../../redux/action/toastAction";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { viewPostAction } from "../../redux/action/dashboardAction";

export const SellerIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [code, setCode] = useState("+61");
  const [inputList, setInputList] = useState([{ upload: "" }]);
  const [floorPlan, setFloorPlan] = useState([{ upload: "" }]);
  const [maxLand, setMaxLand] = useState(null);
  const [minLand, setMinLand] = useState(null);
  const [page, setPage] = useState(1);
  const [check, setChecked] = useState([]);
  const [highlight, setHightlight] = useState([]);
  const [selectAddress, setSelectAddress] = useState(false);
  const [selectSubhurb, setSelectSuburb] = useState(false);
  const [emailCheck, setEmailCheck] = useState();
  const [address, setAddress] = useState("");
  const [bathRoomHightlight, setBathRoomHightlight] = useState([]);
  const [carHightlight, setCarHightlight] = useState([]);
  const [show, setShow] = useState(false);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobile: "",
    suburb: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    establishedProperty: "",
    buildYear: "",
    postType: "SELLER",
    propertiesTitle: "",
    address: {
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      location: { lat: "", long: "" },
    },
    price: "",
    Unit: "",
    Description: "",
    propertyType: "",
    bedrooms: "",
    bathrooms: "",
    carSpaces: "",
    landSize: {},
    outdoorFeatures: [],
    indoorFeatures: [],
    climateControlEnergy: [],
    image: "",
    addFloorplan: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    let array = [];
    const { name, value } = e?.target;
    let formErrors = {};

    if (name === "email") {
      setData({
        ...data,
        email: value,
        formErrors,
      });
    } else if (name === "minLand" || name === "maxLand") {
      if (name === "minLand") {
        setData((prev) => {
          return {
            ...prev,
            landSize: {
              ...prev.landSize,
              min: value,
            },
            formErrors,
          };
        });
        setMinLand(value);
      }
    } else if (name === "propertyType") {
      array.push(value);
      setData({
        ...data,
        propertyType: array,
        formErrors,
      });
    } else {
      setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };
  useEffect(() => {
    if (code) {
      setData({
        ...data,
        countryCode: code,
      });
    } else {
      setData({
        ...data,
        countryCode: "+61",
      });
    }
  }, [code, data.mobile]);
  useEffect(() => {
    dispatch(buyerGeneralInfo());
  }, []);
  const unitData = [
    { year: "1", value: "1" },
    { year: "2", value: "2" },
    { year: "3", value: "3" },
    { year: "4", value: "4" },
    { year: "5", value: "5" },
    { year: "6", value: "6" },
    { year: "7", value: "7" },
    { year: "8", value: "8" },
    { year: "9", value: "9" },
    { year: "10", value: "10" },
  ];
  const minLandSize = [
    "Any",
    "200 ㎡",
    "300 ㎡",
    "400 ㎡",
    "500 ㎡",
    "600 ㎡",
    "700 ㎡",
    "800 ㎡",
    "900 ㎡",
    "1,000 ㎡",
    "1,200 ㎡",
    "1,500 ㎡",
    "1,750 ㎡",
    "2,000 ㎡",
    "3,000 ㎡",
    "4,000 ㎡",
    "5,000 ㎡",
    "1 ha",
    "2 ha",
    "3 ha",
    "4 ha",
    "5 ha",
    "10 ha",
    "20 ha",
    "30 ha",
    "40 ha",
    "50 ha",
    "60 ha",
    "70 ha",
    "80 ha",
    "90 ha",
    "100 ha",
    "150 ha",
    "200 ha",
    "300 ha",
    "500 ha",
    "1000 ha",
    "3000 ha",
    "5000 ha",
    "10000 ha",
  ];
  const handleRoomChange = (name, value) => {
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    if (name === "bedrooms") {
      setHightlight(value);
    } else if (name === "bathrooms") {
      setBathRoomHightlight(value);
    } else {
      setCarHightlight(value);
    }
  };
  const handleTypeChange = (e) => {
    if (e.value._id === "6502e67c4bae95323d453d17" && e.checked === true) {
      let selectType = [...PropertyList?.propertyTypes];

      setChecked(selectType);
      setData({
        ...data,
        propertyType: selectType,
      });
    } else if (
      e.value._id === "6502e67c4bae95323d453d17" &&
      e.checked === false
    ) {
      setChecked([]);
      setData({
        ...data,
        propertyType: [],
      });
    } else {
      let selectType = [...check];
      if (e.checked) {
        selectType.push(e.value);
      } else {
        selectType = selectType.filter((type) => type._id !== e.value._id);
      }
      setChecked(selectType);

      setData({
        ...data,
        propertyType: selectType,
      });
    }
  };

  const maxInputFields = 10;
  const handleAddImage = () => {
    if (inputList.length < maxInputFields) {
      setInputList([...inputList, { upload: "" }]);
    }
  };
  const handleImage = (e, index) => {
    let array = [];
    const list = [...inputList];
    if (e.target.files.length === 1) {
      let obj = {
        ...list[index],
        upload: e.target.files[0],
      };
      list.splice(index, 1, obj);
      setInputList(list);
    } else {
      Object?.entries(e.target.files).map((item) => {
        let obj = {
          ...list[index],
          upload: item[1],
        };
        array.push(obj);
      });
      setInputList([...array]);
      if (array.length < 11) {
        setInputList(array);
      } else {
        dispatch(
          showToast({
            severity: "error",
            summary: "Maximum 10 images you can upload",
          })
        );
      }
    }
  };
  const handleChangeAddFloorPlan = (e, index) => {
    const list = [...floorPlan];
    let array = [];
    Object.entries(e.target.files).map((item) => {
      let obj = {
        ...list[index],
        upload: item[1],
      };
      array.push(obj);
    });
    if (array.length < 11) {
      setFloorPlan(array);
    } else {
      dispatch(
        showToast({
          severity: "error",
          summary: "Maximum 10 images you can upload",
        })
      );
    }
  };
  useEffect(() => {
    setData({
      ...data,
      image: inputList,
    });
  }, [inputList]);
  useEffect(() => {
    setData({
      ...data,
      addFloorplan: floorPlan,
    });
  }, [floorPlan]);
  const handleClear = () => {
    window.scrollTo({ top: 0, left: 0 });
    window.location.reload();
    setData({
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      mobile: "",
      suburb: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      establishedProperty: "",
      buildYear: "",
      postType: "SELLER",
      propertiesTitle: "",
      address: {
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        location: { lat: "", long: "" },
      },
      price: "",
      Unit: "",
      Description: "",
      propertyType: "",
      bedrooms: "",
      bathrooms: "",
      carSpaces: "",
      landSize: {},
      outdoorFeatures: [],
      indoorFeatures: [],
      climateControlEnergy: [],
      image: "",
      addFloorplan: "",
    });
  };
  const handleChangeSuburb = (newAddress) => {
    setShow(true);
    setSelectAddress(false);
    // setAddress(newAddress);
    setData({
      ...data,
      suburb: newAddress,
    });
  };
  const handleSelectAddressSuburb = (newAddress) => {
    setSelectAddress(true);

    // setAddress(newAddress)
    let obj = {
      state: "",
      city: "",
      zipcode: "",
      suburb: "",
    };
    geocodeByAddress(newAddress)
      .then((results) =>
        getLatLng(
          results[0]?.address_components?.length > 0 &&
            results[0]?.address_components?.map((item) => {
              if (item?.types?.includes("administrative_area_level_1")) {
                obj.state = item.long_name;
              } else if (item?.types?.includes("postal_code")) {
                obj.suburb = results[0]?.formatted_address;
                obj.zipcode = item.long_name;
              } else if (item?.types?.includes("locality")) {
                obj.city = item.long_name;
              }
            }),

          setData({
            ...data,
            state: obj.state,
            zipCode: obj.zipcode,
            suburb: obj.suburb,
            city: obj.city,
          })
        )
      )
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  const handleChangeAddress = (newAddress) => {
    setShow(true);
    setSelectSuburb(false);
    let obj = {
      streetAddress: newAddress,
      city: "",
      state: "",
      zipCode: "",
      location: { lat: "", long: "" },
    };
    setData({
      ...data,
      address: obj,
    });
  };
  const handleSelectAddress = (newAddress) => {
    setSelectSuburb(true);

    let array = [];
    let obj = {
      streetAddress: newAddress,
      city: "",
      state: "",
      zipCode: "",
      location: { lat: "", long: "" },
    };
    geocodeByAddress(newAddress)
      .then((results) =>
        getLatLng(
          results[0]?.address_components?.length > 0 &&
            results[0]?.address_components?.map((item) => {
              if (item?.types?.includes("administrative_area_level_1")) {
                obj.state = item.long_name;
              } else if (item?.types?.includes("postal_code")) {
                obj.zipCode = item.long_name;
              } else if (item?.types?.includes("locality")) {
                obj.city = item.long_name;
              }
            }),
          (obj.location.lat = results[0]?.geometry?.location.lng()),
          (obj.location.long = results[0]?.geometry?.location.lat()),
          (obj = {
            streetAddress: newAddress?.includes(obj?.zipCode)
              ? newAddress + "," + obj.state
              : newAddress + " " + obj?.zipCode + "," + obj.state,
            city: obj.city,
            state: obj.state,
            zipCode: obj.zipCode,
            location: { lat: obj.location.lat, long: obj.location.long },
          }),
          array.push(obj),
          setData({
            ...data,
            address: array,
          })
        )
      )
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  useEffect(() => {
    if (id) {
      dispatch(viewPostAction(id)).then((res) => {
        if (res.success) {
          setSelectSuburb(true);
        }
      });
    }
  }, [id]);
  const viewPostData = useSelector((state) => state.viewPost.viewPostProfile);
  useEffect(() => {
    if (Object.values(viewPostData)?.length > 0) {
      let array = [];
      let payload = {
        city: viewPostData?.address[0]?.city,
        state: viewPostData?.address[0]?.state,
        streetAddress: viewPostData?.address[0]?.streetAddress,
        zipCode: viewPostData?.address[0]?.zipCode,
        location: {
          lat: viewPostData?.address[0]?.location?.coordinates[0],
          long: viewPostData?.address[0]?.location?.coordinates[1],
        },
      };
      array.push(payload);
      setData({
        ...data,
        firstName: viewPostData?.firstName,
        lastName: viewPostData?.lastName,
        email: viewPostData?.email,
        countryCode: viewPostData?.countryCode,
        mobile: viewPostData?.mobile,
        streetAddress: viewPostData?.streetAddress,
        suburb: viewPostData?.suburb,
        state: viewPostData?.state,
        zipCode: viewPostData?.zipCode,
        establishedProperty: viewPostData?.establishedProperty,
        buildYear: new Date(viewPostData?.buildYear),
        propertiesTitle: viewPostData?.propertiesTitle,
        address: array,
        price: viewPostData?.price,
        Unit: viewPostData?.Unit,
        Description: viewPostData?.Description,
        image: viewPostData?.image,
        addFloorplan: viewPostData?.addFloorplan,
        propertyType: viewPostData?.propertyType,
        bedrooms: viewPostData?.bedrooms,
        bathrooms: viewPostData?.bathrooms,
        carSpaces: viewPostData?.carSpaces,
        landSize: viewPostData?.landSize.min,
        outdoorFeatures: viewPostData?.outdoorFeatures,
        indoorFeatures: viewPostData?.indoorFeatures,
        climateControlEnergy: viewPostData?.climateControlEnergy,
      });
      if (viewPostData.propertyType?.length > 0) {
        setHightlight(
          viewPostData.bedrooms === null
            ? "any"
            : `${viewPostData?.bedrooms?.toString()}+`
        );
        setBathRoomHightlight(
          viewPostData?.bathrooms === null
            ? "any"
            : `${viewPostData?.bathrooms?.toString()}+`
        );
        setCarHightlight(
          viewPostData?.carSpaces === null
            ? "any"
            : `${viewPostData?.carSpaces?.toString()}+`
        );
        // setMinLand(viewPostData?.landSize?.min);
        setMinLand(viewPostData?.landSize);
        setMaxLand(viewPostData?.landSize?.max);
        setChecked(viewPostData?.propertyType);
        setCode(viewPostData?.countryCode);
        let image = viewPostData?.image?.map((item) => {
          return {
            upload: item,
          };
        });

        setInputList(image);
        let floorPlan = viewPostData?.addFloorplan?.map((item) => {
          return {
            upload: item,
          };
        });
        setFloorPlan(floorPlan);
        setSelectAddress(true);
      }
    }
  }, [viewPostData]);
  useEffect(() => {
    if (!id) {
      setData({
        firstName: "",
        lastName: "",
        email: "",
        countryCode: "",
        mobile: "",
        suburb: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        establishedProperty: "",
        buildYear: "",
        postType: "SELLER",
        propertiesTitle: "",
        address: {
          streetAddress: "",
          city: "",
          state: "",
          zipCode: "",
          location: { lat: "", long: "" },
        },
        price: "",
        Unit: "",
        Description: "",
        propertyType: "",
        bedrooms: "",
        bathrooms: "",
        carSpaces: "",
        landSize: {},
        outdoorFeatures: [],
        indoorFeatures: [],
        climateControlEnergy: [],
        image: "",
        addFloorplan: "",
      });
      setHightlight("");
      setBathRoomHightlight("");
      setCarHightlight("");
      setMinLand("");
      setMaxLand("");
      setChecked([]);
      setInputList([{ upload: "" }]);
      setFloorPlan([{ upload: "" }]);
    }
  }, [id]);
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    // list.splice(index, 1);
    setInputList(remove);
  };
  const handleRemoveFloorPlane = (index) => {
    const list = [...floorPlan];
    const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    setFloorPlan(remove);
  };
  const token = localStorage?.getItem("realState");
  // useEffect(() => {
  //   if (token) {
  //     dispatch(myProfileAction());
  //   }
  // }, [token]);
  const GetImagePath = async (value, dispatch, type) => {
    if (value === null || value === "" || value === undefined) {
      return false;
    } else if (value && typeof value === "object") {
      let NewValue = [];
      await dispatch(SellerUploadImageAction(value)).then((res) => {
        if (res.success) {
          res.data?.length > 0 &&
            res.data?.map((item) => {
              NewValue.push(item?.path);
            });
        }
      });
      return NewValue;
    } else {
      return value;
    }
  };
  const handleSubmit = async () => {
    let array = [];
    let addFloor = [];
    // let addressArray = []
    data.image?.forEach((item) => {
      array.push(item.upload || item);
    });
    data.addFloorplan?.forEach((item) => {
      addFloor.push(item.upload);
    });
    let addimageArrayObject = [];
    let addimageArraystring = [];
    let floorImageArrayObject = [];
    let floorImageArrayString = [];
    array.map(async (item) => {
      if (typeof item === "object") {
        addimageArrayObject.push(item);
      } else {
        addimageArraystring.push(item);
      }
    });
    let addimageArray = await GetImagePath(addimageArrayObject, dispatch);
    addFloor.map(async (item) => {
      if (typeof item === "object") {
        floorImageArrayObject.push(item);
      } else {
        floorImageArrayString.push(item);
      }
    });
    let floorimageArray = await GetImagePath(floorImageArrayObject, dispatch);
    let propertyTypeArray = [];
    let outdoorFeaturesArray = [];
    let indoorFeaturesArray = [];
    let climateControlEnergyArray = [];
    data?.propertyType?.map((item) => {
      propertyTypeArray.push(item._id);
    });
    data?.outdoorFeatures?.map((item) => {
      outdoorFeaturesArray.push(item._id);
    });
    data?.indoorFeatures?.map((item) => {
      indoorFeaturesArray.push(item._id);
    });
    data?.climateControlEnergy?.map((item) => {
      climateControlEnergyArray.push(item._id);
    });
    // addressArray.push(data.address)

    let payload = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      countryCode: data?.countryCode,
      mobile: data?.mobile,
      suburb: data?.suburb,
      city: data?.city,
      state: data?.state,
      zipCode: data?.zipCode,
      establishedProperty: data?.establishedProperty,
      buildYear: data?.buildYear,
      propertiesTitle: data?.propertiesTitle,
      address: data.address,
      price: data?.price,
      Unit: data?.Unit,
      Description: data?.Description,
      outdoorFeatures: outdoorFeaturesArray,
      indoorFeatures: indoorFeaturesArray,
      climateControlEnergy: climateControlEnergyArray,
      propertyType: propertyTypeArray,
      bedrooms:
        data?.bedrooms === "any"
          ? null
          : typeof data.bedrooms === "number"
          ? data.bedrooms
          : Number(data.bedrooms?.slice(0, 1)),
      bathrooms:
        data?.bathrooms === "any"
          ? null
          : typeof data.bathrooms === "number"
          ? data?.bathrooms
          : Number(data.bathrooms?.slice(0, 1)),
      carSpaces:
        data?.carSpaces === "any"
          ? null
          : typeof data.carSpaces === "number"
          ? data?.carSpaces
          : Number(data.carSpaces?.slice(0, 1)),
      landSize: minLand,
      image: [...addimageArray, ...addimageArraystring],
      addFloorplan: [...floorimageArray, ...floorImageArrayString],
      postType: "SELLER",
      password: data?.password,
    };
    dispatch(createBuyerAction(payload, id, navigate));
  };
  const PropertyList = useSelector(
    (state) => state.propertyType.propertyProfile
  );

  useEffect(() => {
    if (token) {
      setEmailCheck(true);
    }
  }, [token, emailCheck]);
  useEffect(() => {
    if (id) {
      setChecked(viewPostData?.propertyType);
    }
  }, [id, viewPostData]);
  return {
    data,
    setData,
    code,
    setCode,
    show,
    handleChange,
    handleRemoveClick,
    id,
    handleRemoveFloorPlane,
    handleChangeAddress,
    handleSelectAddress,
    address,
    unitData,
    inputList,
    highlight,
    bathRoomHightlight,
    carHightlight,
    handleChangeSuburb,
    handleSelectAddressSuburb,
    setInputList,
    PropertyList,
    minLandSize,
    maxLand,
    minLand,
    handleTypeChange,
    check,
    setChecked,
    floorPlan,
    setFloorPlan,
    page,
    setPage,
    handleSubmit,
    handleAddImage,
    handleImage,
    handleChangeAddFloorPlan,
    handleClear,
    handleRoomChange,
    emailCheck,
    token,
  };
};
