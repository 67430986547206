import { types } from "../type";
import Constants from "../../utils/constants";
import api from "../../services/api";
import { authenticate, isAuthenticated } from "../../services/authService";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import forgetApi from "../../services/forgetPasswordApi";
export const signupAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.SIGNUP, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.SING_UP,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const loginAction = (data, navigate) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.LOGIN, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.SING_IN,
                payload: res.data
            })
            authenticate(res.data.token, () => {
                if (isAuthenticated()) {
                    navigate("/dashboard");
                }
                if (!isAuthenticated) {
                    navigate("/");
                }
            });
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
    return res;
}

export const forgetPasswordAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.FORGETPASSWORD, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.FORGET_PASSWORD,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const resetPasswordAction = (data,token, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await forgetApi("post", Constants.END_POINT.RESETPASSWORD,token, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.RESET_PASSWORD,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}