import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ContactMessageAction,
  inqueryMessageAction,
  ParticularContactMessageAction,
  ParticularInqueryMessageAction,
} from "../../redux/action/MessageAction";
import moment from "moment";
import { useLocation } from "react-router-dom";
export const MessgaeData = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState("");
  const [contactform, setContactForm] = useState("");
  const location = useLocation();
  const index = location.state?.row;
  const id = location.state?.col;
  const _id = location.state?.col;
  const [isActive, setIsActive] = useState(0);

  useEffect(() => {
    setIsActive(index);
  }, [location]);
  useEffect(() => {
    dispatch(ContactMessageAction());
  }, []);

  const [messageData, setMessageData] = useState("");

  const ContactMessage = () => {
    dispatch(ContactMessageAction());
  };
  const InqueryMessage = () => {
    dispatch(inqueryMessageAction());
  };

  const RecentMessageDetail = useSelector(
    (state) => state.inquery.InqueryProfile
  );
  const ContactMessageDetail = useSelector(
    (state) => state.CONTACTLIST.CONTACTLIST
  );
  const PContactRecentMessageDetail = useSelector(
    (state) => state.particularContact.particularContact
  );
  console.log(
    "PContactRecentMessageDetail ======>",
    PContactRecentMessageDetail
  );

  const particularRecentMessageDetail = useSelector(
    (state) => state.particularInquery.particularInqueryProfile
  );

  const handleSubmit = (id) => {
    dispatch(ParticularInqueryMessageAction(id));
  };
  const ContacthandleSubmit = (id) => {
    dispatch(ParticularContactMessageAction(id));
  };

  useEffect(() => {
    if (RecentMessageDetail?.data?.length > 0) {
      dispatch(
        ParticularInqueryMessageAction(RecentMessageDetail?.data[0]._id || id)
      );
    }
  }, [RecentMessageDetail, id]);
  useEffect(() => {
    if (ContactMessageDetail?.data?.length > 0) {
      dispatch(
        ParticularContactMessageAction(ContactMessageDetail?.data[0]._id || _id)
      );
    }
  }, [ContactMessageDetail, _id]);
  useEffect(() => {
    let array = [];
    RecentMessageDetail?.data?.length > 0 &&
      RecentMessageDetail?.data?.map((item) => {
        let obj = {
          country: item?.country,
          email: item?.email,
          fullName: item?.fullName,
          message: item?.message,
          mobile: item?.mobile,
          postId: item?.postId,
          time: item?.time,
          userId: item?.userId,
          _id: item?._id,
          duration: moment.duration(
            moment(item?.time).diff(moment(new Date()))
          ),
        };
        array.push(obj);
      });
    setForm(array);
  }, [RecentMessageDetail]);
  const duration = moment.duration(
    moment(particularRecentMessageDetail?.data?.time).diff(moment(new Date()))
  );
  useEffect(() => {
    let array = [];
    ContactMessageDetail?.data?.length > 0 &&
      ContactMessageDetail?.data?.map((item) => {
        let obj = {
          country: item?.country,
          email: item?.email,
          fullName: item?.fullName,
          message: item?.message,
          mobile: item?.mobile,
          postId: item?.postId,
          time: item?.time,
          _id: item?._id,
          duration: moment.duration(
            moment(item?.time).diff(moment(new Date()))
          ),
        };
        array.push(obj);
      });
    setContactForm(array);
  }, [ContactMessageDetail]);
  const Contactduration = moment.duration(
    moment(PContactRecentMessageDetail?.data?.time).diff(moment(new Date()))
  );
  return {
    RecentMessageDetail,
    InqueryMessage,
    handleSubmit,
    ContactMessage,
    ContactMessageDetail,
    particularRecentMessageDetail,
    PContactRecentMessageDetail,
    form,
    contactform,
    Contactduration,
    messageData,
    duration,
    isActive,
    ContacthandleSubmit,
    setIsActive,
  };
};
