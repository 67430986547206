import React from "react";
import LogoMain from "../assets/images/Logo.png";
import { Link, useLocation } from "react-router-dom";
import LayoutData from "./LayoutData";
import LogOutIcon from "../assets/icons/Logout.png";
import { logout } from "../services/authService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
export default function Sidebar({ style, extraClass }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeLink = location?.pathname;
  const { menuItems } = LayoutData();

  const logOut = () => {
    localStorage.removeItem("realStateAdmin");
    navigate("/");
  };
  return (
    <div
      className={`hidden lg:flex sidebar-nav flex-column justify-content-between h-full fixed overflow-y-scroll bg-lightest pt-3 left-0 text-center ${extraClass}`}
      style={style}
    >
      <div className="">
        <div className="p-4">
          <img
            src={LogoMain}
            style={{ width: "163px", height: "40px" }}
            alt=""
            className="mb-3"
          />
        </div>

        <ul className="list-none">
          {menuItems.map((item, key) => (
            <li key={key}>
              <Link
                to={item.link}
                className={`p-3 flex align-items-center gap-2 justify-content-start font-medium no-underline side_menuitem text-md ${
                  activeLink.includes(item.link)
                    ? "text-white active border-round-xl"
                    : " text-gray"
                }`}
              >
                {activeLink.includes(item.link) ? (
                  <img
                    src={item.activeicon}
                    alt=""
                    width={item.iconWidth}
                    height={item.iconHeight}
                  />
                ) : (
                  <img
                    src={item.icon}
                    alt=""
                    width={item.iconWidth}
                    height={item.iconHeight}
                  />
                )}
                <span className="text-left">{item.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <ul className="list-none">
        <li className="">
          <div
            onClick={logOut}
            className="p-3 flex align-items-center font-medium no-underline side_menuitem text-md cursor-pointer"
          >
            <img src={LogOutIcon} alt="" width={24} height={24} /> &nbsp; Logout
          </div>
        </li>
      </ul>
    </div>
  );
}
