import React, { useState } from "react";
import { allValidations } from "../../utils/formValidation";
import { showFormErrors } from "../../utils/commonFunction";
import { signupAction } from "../../redux/action/signupAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
export const SignupIndex = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [remimber, setRemimber] = useState(false)
    const [form, setForm] = useState({
        email: "",
        password: "",
        confirmPassword: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        const formErrors = allValidations(name, value, form)
        setForm((prev) => ({ ...prev, [name]: value, formErrors }));
    }
    const handleSubmit = () => {
        if (showFormErrors(form, setForm)) {
            dispatch(signupAction(form, () => navigate("/")))
        }
    }
    return { form, handleChange,handleSubmit,remimber,setRemimber}
}