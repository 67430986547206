import React, { useEffect, useRef, useState } from "react";
import { allValidations } from "../../utils/formValidation";
import { useDispatch } from "react-redux";
import { showFormErrors } from "../../utils/commonFunction";
import { loginAction } from "../../redux/action/signupAction";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

export const LoginIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRef = useRef();
  const passRef = useRef();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [remimber, setRemimber] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const formErrors = allValidations(name, value, form);
    setForm((prev) => ({ ...prev, [name]: value, formErrors }));
  };
  const handleSubmit = () => {
    if (showFormErrors(form, setForm)) {
      dispatch(loginAction(form, navigate)).then((data) => {
        if (data.data) {
          if (remimber == true) {
            const encryptedUser = CryptoJS.AES.encrypt(
              userRef.current.value,
              "123abc"
            ).toString();
            const encryptedPass = CryptoJS.AES.encrypt(
              passRef?.current.props.value,
              "123abc"
            ).toString();
            localStorage.setItem("userName", encryptedUser);
            localStorage.setItem("password", encryptedPass);
          } else {
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
          }
        }
      });
    }
  };
    const rememberFunc = (e) => {
      setRemimber(e.checked);
    //   if (e.checked == true) {
    //       const encryptedUser = CryptoJS.AES.encrypt(userRef.current.value, '123abc').toString();
    //       const encryptedPass = CryptoJS.AES.encrypt(passRef?.current.props.value, '123abc').toString();
    //     localStorage.setItem("userName", encryptedUser);
    //     localStorage.setItem("password", encryptedPass);
    //   } else {
    //     localStorage.removeItem("userName");
    //     localStorage.removeItem("password");
    //   }
    };
  useEffect(() => {
    let userName = localStorage.getItem("userName");
    let passWord = localStorage.getItem("password");

    if (userName || passWord) {
      // const decryptedUser = CryptoJS.AES.decrypt(userName, "123abc").toString(
      //   CryptoJS.enc.Utf8
      // );
      // const decryptedPass = CryptoJS.AES.decrypt(passWord, "123abc").toString(
      //   CryptoJS.enc.Utf8
      // );
      setForm((prev) => {
        return {
          ...prev,
          email: userName,
          password: passWord,
        };
      });
      setRemimber(true);
    }
  }, []);

  return {
    form,
    handleChange,
    handleSubmit,
    remimber,
    setRemimber,
    userRef,
    passRef,
    rememberFunc,
  };
};
