import { types } from "../type";
const intitalState = {

}
export const toastReducer = (state = intitalState,action)=> {
    switch (action.type) {
        case types.SHOW_TOAST:
            return {
                ...state,
                toastInfo: action.payload,
            };
        default:
            return { ...state };
    }
}