import { entries, notEqual, values } from "./javascript";
import { allValidations } from "./formValidation";

export const showFormErrors = (data, setData) => {
  let formErrors = {};
  entries(data).forEach(([key, value]) => {
    formErrors = {
      ...formErrors,
      ...allValidations(key, value, data),
    };
  });
  setData({ ...data, formErrors });
  return !values(formErrors).some((v) => notEqual(v, ""));
};
