import React from "react";
import Sidebar from "./sidebar";
import SidebarIcons from "./SidebarIcons";
import { Topbar } from "./topbar";


export default function DashboardLayout({ children }) {
  return (
    <div>
      <div className="flex justify-content-end m-0 relative top-0 overflow-hidden">
        <Sidebar extraClass="hidden lg:flex"
          style={{
            width: "250px" ,
            height: "100vh",
          }}
        />
        <SidebarIcons style={{
            width: "70px",
            height: "100vh",
          }} />
        <div
          className="main-content p-0  bg-dashboard"
          style={{
            width:"calc(100% - 250px)"
          }}
        >
          <Topbar />
          <div className="px-5">{children}</div>
        </div>
      </div>
    </div>
  );
}
