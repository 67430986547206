import { postListAction } from "../../redux/action/dashboardAction";
import { AcceptRejectPostListAction } from "../../redux/action/postListAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const NewPostData = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState({
    isVerified: "VERIFIED",
    postType: "",
  });
  const [highlight, setHighlight] = useState("");

  const handleSubmit = (name) => {
    if (name === "Approved") {
      setActiveIndex(0);
      // dispatch(postListAction({ isVerified: "VERIFIED" }));
    } else {
      setActiveIndex(1);
      // dispatch(postListAction({ isVerified: "PENDING" }));
    }
  };

  useEffect(() => {
    dispatch(
      postListAction({ isVerified: activeIndex === 0 ? "VERIFIED" : "PENDING" })
    );
  }, [activeIndex]);

  const handleApprove = (id, data) => {
    dispatch(AcceptRejectPostListAction({ isVerified: "VERIFIED" }, id)).then(
      () => {
        dispatch(postListAction({ isVerified: "PENDING" }));
      }
    );
  };

  const handleCancel = (id) => {
    dispatch(AcceptRejectPostListAction({ isVerified: "REJECTED" }, id)).then(
      () => {
        dispatch(postListAction({ isVerified: "PENDING" }));
      }
    );
  };

  const ApprovedPostData = useSelector(
    (state) => state.postList.postListProfile
  );

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [newTableData, setNewTableData] = useState([]);
  const [count, setCount] = useState(0);
  const onPageChange = (event) => {
    let startPage = event.page + 1;
    let itemsPerPage = event.rows;
    let value = [];
    for (var i = 0; i < ApprovedPostData.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(ApprovedPostData[i]);
      }
    }
    setNewTableData(value);
    setCount(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    let startPage = 1;
    let itemsPerPage = rows;
    let value = [];
    for (var i = 0; i < ApprovedPostData?.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(ApprovedPostData[i]);
      }
    }
    setNewTableData(value);
    // setCount(0)
  }, [ApprovedPostData]);
  const handleDropdown = (name) => {
    if (activeIndex === 0 && name === "All Showings") {
      let payload = {
        isVerified: "VERIFIED",
        postType: "",
      };
      dispatch(postListAction(payload));
    } else if (activeIndex === 0) {
      let payload = {
        ...form,
        postType: name.toUpperCase(),
      };
      dispatch(postListAction(payload));
    } else if (activeIndex === 1 && name === "All Showings") {
      let payload = {
        isVerified: "PENDING",
        postType: "",
      };
      dispatch(postListAction(payload));
    } else if (activeIndex === 1) {
      let payload = {
        isVerified: "PENDING",
        postType: name.toUpperCase(),
      };
      dispatch(postListAction(payload));
    }
  };
  const buttons = ["All Showings", "Buyer", "Seller", "Renter"];
  return {
    ApprovedPostData,
    activeIndex,
    setActiveIndex,
    handleSubmit,
    handleApprove,
    handleCancel,
    first,
    rows,
    onPageChange,
    newTableData,
    setHighlight,
    highlight,
    isOpen,
    setIsOpen,
    buttons,
    handleDropdown,
  };
};
export default NewPostData;
