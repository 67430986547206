import api from "../../services/api";
import { formDataapi } from "../../services/formDataapi";
import Constants from "../../utils/constants";
import { types } from "../type";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

export const SellerUploadImageAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const formData = new FormData();
  data?.map((item) => {
    formData.append("files", item);
  });

  const res = await formDataapi(
    "post",
    Constants.END_POINT.UPLOADFILE,
    formData
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.UPLOAD_FILE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const propertyTypeAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.PROPERTYCONSTANT);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.PROPERTY_CONSTANT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const buyerGeneralInfo = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.GERENALPROPERTYINFO);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GENERAL_PROPERTY_INFO,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};
export const myProfileAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.MYPROFILE);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.MY_PROFILE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    // dispatch(showToast({ severity: "success", summary: res.message }))
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const createBuyerAction = (data, id, navigate) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api(
    "put",
    `${Constants.END_POINT.CREATEBUYERFORM}/${id}`,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CREATE_BUYER_FORM,
        payload: res.data,
      });
    }
    navigate("/new-post-list?postType=Pending");
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    if (res.message.includes("E11000")) {
      dispatch(hideLoaderAction());
      dispatch(
        showToast({ severity: "error", summary: "Email allready Exist" })
      );
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  }
};
