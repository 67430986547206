import { Login } from "../pages/login";
import { Signup } from "../pages/signup";
import { EmailVerify } from "../pages/signup/EmailVerify";
import { ForgetPassword } from "../pages/signup/ForgetPassword";
import { SetPassword } from "../pages/signup/SetPassword";
import { BuyerPostDetail } from "../views/NewPostList/BuyerPostDetail";
import { NewPostList } from "../views/NewPostList/Index";
import { RenterpostDetail } from "../views/NewPostList/RenterpostDetail";
import { SellerPostDetail } from "../views/NewPostList/SellerPostDetail";
import { Dashboard } from "../views/dashboard/Dashboard";
import { Message } from "../views/message/Message";
import { Subscribers } from "../views/subscriber/Subscribers";
import { UserList } from "../views/users/UserList";
import { UserProfile } from "../views/users/UserProfile";

export const allRoutes = [
  {
    path: "/",
    Component: Login,
  },
  {
    path: "/signup",
    Component: Signup,
  },
  {
    path: "/forget-password",
    Component: ForgetPassword,
  },
  {
    path: "/email-verify",
    Component: EmailVerify,
  },
  {
    path: "/set-password/:token",
    Component: SetPassword,
  },
  {
    path: "/dashboard",
    Component: Dashboard,
  },
  {
    path: "/new-post-list",
    Component: NewPostList,
  },
  {
    path: "/new-post-list/buyer-post-detail",
    Component: BuyerPostDetail,
  },
  {
    path: "/new-post-list/renter-post-detail",
    Component: RenterpostDetail,
  },
  {
    path: "/new-post-list/seller-post-detail",
    Component: SellerPostDetail,
  },
  {
    path: "/user-list",
    Component: UserList,
  },

  {
    path: "/user-list/user-profile",
    Component: UserProfile,
  },
  {
    path: "/message",
    Component: Message,
  },
  {
    path: "/subscriber",
    Component: Subscribers,
  },
];
