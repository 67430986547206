import React, { useState } from "react";
import _ from "lodash";
import InputLayout from "./InputLayout";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";

export const CustomInputButton = ({ col, position, ...props }) => {
  return (
    <InputLayout col={col || 6}>
      <div className={`flex justify-content-${position ? position : "start"}`}>
        <Button className="p-button-info" {...props} />
      </div>
    </InputLayout>
  );
};

export const CustomInputNumber = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  inputId,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputNumber
        value={value}
        name={name}
        allowEmpty={true}
        onValueChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        inputClassName="p-3"
        className={`w-full  bg-transparent mb-3 ${
          inputClass ? inputClass : ""
        } ${errorMessage ? "p-invalid border-hightlighted" : ""}`}
        useGrouping={props.useGrouping || false}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomCalenderInput = ({
  label,
  name,
  data,
  selectionMode,
  value,
  onChange,
  errorMessage,
  extraClassName,
  dateFormat,
  view,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Calendar
        id={name}
        name={name}
        value={value || data?.[name]}
        dateFormat={dateFormat}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        inputClassName="p-3"
        selectionMode={selectionMode}
        className={`calender-picker w-full text-lg text-black font-medium  ${
          inputClass ? inputClass : ""
        } ${errorMessage ? "p-invalid" : ""}`}
        showIcon
        {...props}
        // icon="pi pi-clock"
        // timeOnly
      />
    </InputLayout>
  );
};

export const CustomInputMask = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputMask
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        mask="(999) 999-9999"
        {...props}
      />
    </InputLayout>
  );
};

export const CustomTextArea = ({
  label,
  style,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  disabled,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 12}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      style={style}
    >
      <InputTextarea
        id={name}
        name={name}
        value={value}
        onChange={(e) =>
          onChange &&
          onChange({ name: e.target.name, value: e.target.value, ...e })
        }
        className={`w-full bg-transparent mb-2 ${
          inputClass ? inputClass : ""
        } ${errorMessage ? "p-invalid" : ""}`}
        rows={5}
        disabled={disabled}
        {...props}
      />
      {/* {errorMessage ? <small className="p-error">{errorMessage}</small> : null} */}
    </InputLayout>
  );
};
export const CustomChipInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  keyFilter = null,
  disabled = false,
  onRemove,
  onAdd,
  onKeyUp,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 12}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Chips
        id={name}
        onAdd={(e) =>
          onAdd && onAdd({ ...e, name: name, currentValue: e.value })
        }
        name={name}
        onKeyUp={(e) => {
          if (e.key === "Enter") return e.preventDefault();
          onKeyUp &&
            onKeyUp({ ...e, name: e.target.name, value: e.target.value });
        }}
        value={value || data?.[name]}
        onRemove={(e) =>
          onRemove && onRemove({ e, name: name, currentValue: e.value })
        }
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full p-fluid ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder="Press enter to add value."
        disabled={disabled}
        keyfilter={keyFilter}
        {...props}
      />
    </InputLayout>
  );
};
export const CustomAutoComplete = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  suggestions = [],
  forceSelection = false,
  ...props
}) => {
  const [filtered, setFiltered] = useState([]);
  const search = (event) => {
    let _filtered;
    if (!event.query.trim().length) {
      _filtered = [...suggestions];
    } else {
      _filtered = suggestions.filter((country) => {
        return country.toLowerCase().startsWith(event.query.toLowerCase());
      });
      // _filtered = [event.query,..._filtered];
      if (!forceSelection && !_filtered.length) {
        _filtered = [event.query];
      }
    }
    setFiltered(_filtered);
  };
  return (
    <InputLayout
      col={col || 12}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <AutoComplete
        id={name}
        name={name}
        value={value || data?.[name]}
        suggestions={filtered}
        completeMethod={search}
        forceSelection={forceSelection}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full p-fluid ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder={props.placeholder || `Select or Add ${label}`}
        {...props}
      />
    </InputLayout>
  );
};
export const CustomDropDown = ({
  label,
  name,
  onChange,
  data,
  value,
  labeClassName,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  options,
  disabled = false,
  optionLabel = "name",
  ...props
}) => {
  return (
    <InputLayout
      labelClassName="text-black"
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Dropdown
        id={name}
        name={name}
        options={options}
        value={value}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full text-lg text-black font-medium w-full p-1 ${
          inputClass ? inputClass : ""
        } ${errorMessage ? "p-invalid" : ""}`}
        optionLabel={optionLabel}
        placeholder={props.placeholder || `Select ${label}`}
        disabled={disabled}
        {...props}
      />
      {/* {errorMessage ? <small className="p-error">{errorMessage}</small> : null} */}
    </InputLayout>
  );
};

export const CustomMultiSelect = ({
  label,
  name,
  onChange,
  data,
  value,
  options,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 12}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <MultiSelect
        id={name}
        name={name}
        value={options.length ? value || data?.[name] : []}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        optionLabel="name"
        options={options}
        display="chip"
        placeholder={props.placeholder || `Select ${label}`}
        {...props}
      />
      {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
    </InputLayout>
  );
};

export function CustomRadioButtons({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col,
  labelClass,
  id,
  inputClass,
  options,
  optionLabel,
  labelclass,
  ...props
}) {
  return (
    <InputLayout
      col={col || 12}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      inputClass={inputClass}
    >
      <div
        className={`flex align-items-center text-lg text-black font-semibold ${
          inputClass ? inputClass : ""
        }`}
      >
        <RadioButton
          value={value}
          onChange={(e) =>
            onChange && onChange({ name, value: e.target.value, ...e })
          }
          checked={_.isEqual(value || data?.[name])}
          inputId={id}
          name={name}
          {...props}
          className={errorMessage ? "p-invalid hightightBorderRadio" : ""}
        />
        &nbsp;&nbsp;
        <label labelClass={labelClass} htmlFor={id}>
          {optionLabel}
        </label>
      </div>
    </InputLayout>
  );
}

export const CustomInputSwitch = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputSwitch
        id={name}
        name={name}
        checked={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomPhoneInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  setCode,
  code,
  form,
  setForm,
  setShowButton,
  disabled,
  ...props
}) => {
  return (
    <InputLayout
      country={"eg"}
      enableSearch={true}
      col={col || 6}
      label={label}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <div className="flex ">
        <div className="phone-country-code relative w-4  md:w-4 lg:w-3 px-3 p-2 py-3 mb-3 border-1 border-round-lg border-400">
          <label htmlFor={name} className="phone-label text-light">
            {code || "+61"}{" "}
            <i className="pi pi-angle-down absolute dropdown-icon"></i>
          </label>

          {/* <PhoneInput
            country={"us"}
            // disableDropdown="false"
            name={name}
            className="absolute w-full h-full top-0 left-0 mb-3"
            inputStyle={{ display: "none" }}
            value={code || "+61"}
            isValid={(value, country) => /^\d+$/.test(value)}
            disabled={disabled}
            errorMessage={errorMessage}
            onChange={(e) => {
              setCode(`+${e}`) &&
                onChange({ ...e, name: e.target.name, value: e.target.value });
            }}
            {...props}
          /> */}
        </div>
        <InputText
          keyfilter="num"
          id={name}
          maxLength={16}
          name={name}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            onChange && onChange({ ...e, name: e.target.name, value: e.value });
          }}
          inputClassName="phnoe-number mb-3 "
          className={`w-8 phnoe-number lg:w-10 phone-input-feild ml-3  bg-transparent ${
            inputClass ? inputClass : ""
          } ${errorMessage ? "p-invalid border-hightlighted" : ""}`}
          useGrouping={props.useGrouping || false}
          style={{ height: "3.2rem" }}
          {...props}
        />
      </div>
    </InputLayout>
  );
};
